// import dayjs from "@/common/dayjs.min.js";
// import isBetween  from "@/common/isBetween/index.js";
// dayjs.extend(isBetween);
export default {
	state: {
		messageCount:0,			//要移除的消息数量
		conferenceId:0,//会议ID
		fileList:[],			//下载中的文件列表
	},
	mutations: {
		// 设置时间 store.commit("setMessageCount",count);
		setMessageCount(state, value) {
			console.log('设置待移除消息数量',state,value)
			state.messageCount = value
		},
		setConferenceId(state, value) {
			state.conferenceId = value
		},
		// 添加下载文件列表
		setFileList(state, value) {
			// state.conferenceId = value
			let add = true
			state.fileList.forEach(element => {
				if(element.MsgId == value.MsgId){
					add = false
				}
			});
			if(add){
				state.fileList.push(value)
			}
			
		},
		// 删除下载文件列表
		deleteFileList(state, value){
			// state.fileList.push({value})
			const list = state.fileList
			console.log('我进来了')
			list.forEach((element,index) => {
				
				if(element.MsgId == value.MsgId){
					console.log('要移除的下标',index)
					list.splice(index, 1)
				}
			});
			state.fileList = list 
		}
	},
	actions: {
		
	}
}