<template>
	<div>
		<el-dialog :title="title" :visible.sync="dialogVisible" top="10vh" width="648px" class="myDialogClass"
			@close="closeDialog">

			<div style="height: 415px;overflow: auto;" class="flexcumal">


				<div class="frame-item flexal" v-for="(item,index) in list" :key="index">
					<img :src="fileIcon[item.FileSuffix]" style="width: 30px;height: 31px;" />
					<div style="font-weight: 400;font-size: 12px;color: #000000;margin-left: 12px;">{{item.FileName}}</div>
				</div>
			</div>

			<!--弹窗底部-->
			<template #footer>
				<span class="myDialogFooter">
					<!-- <el-button type="primary" @click="dialogVisible = false">取消</el-button>
					<el-button type="primary" @click="handleeditusers()"
						style="background: #0052D9;color: #FFFFFF;">确定</el-button> -->
				</span>
			</template>
		</el-dialog>
	</div>

</template>

<script>
	export default {
		name: 'bookEditPop',
		props: {
			// 是否显示弹窗
			dialogVisibleShow: {
				type: Boolean,
				default: false
			},
			// 弹窗标题
			title: {
				type: String,
				default: '文档'
			},
		},
		data() {
			return {
				dialogVisible: false,
				input: '',
				num: 0,
				content: '',
				useravatar: '',
				username: '',
				list: [{
						checked: false,
						FileSuffix: '.pdf'
					}, {
						checked: false,
						FileSuffix: '.pdf'
					}, {
						checked: false,
						FileSuffix: '.pdf'
					}, {
						checked: false,
						FileSuffix: '.pdf'
					}

				],
				roomId: 0,
			};
		},
		watch: {
			// New: 新值 | Old: 老值
			dialogVisibleShow(New, Old) {
				this.dialogVisible = New
				this.useravatar = this.avatar
				this.content = this.name
				console.log(`新值:${New} ——— 老值:${Old}`)
				if (New) {
					var value = this.$store.state.messageStore.conferenceId;
					console.log(`conferenceId:${this.roomId}`)
					this.roomId = value
					this.loaddoclist()
				}
			},
		},
		computed: {
			// 文件消息图标
			fileIcon() {
				let fileIcon = []
				fileIcon['.ppt'] = require('@/assets/img/messageRight/ppt.png')
				fileIcon['.pdf'] = require('@/assets/img/messageRight/pdf.png')
				fileIcon['.doc'] = require('@/assets/img/messageRight/word.png')
				fileIcon['.docx'] = require('@/assets/img/messageRight/word.png')
				fileIcon['.xls'] = require('@/assets/img/messageRight/xlx.png')
				fileIcon['.xlsx'] = require('@/assets/img/messageRight/xlx.png')
				fileIcon['.zip'] = require('@/assets/img/messageRight/package.png')
				fileIcon['.rar'] = require('@/assets/img/messageRight/package.png')
				fileIcon['.txt'] = require('@/assets/img/messageRight/package.png')
				fileIcon['.package'] = require('@/assets/img/messageRight/package.png')
				// console.log('fileIcon',fileIcon['ppt'])
				return fileIcon
			}
		},
		mounted() {
			console.log('组件初始化', this.avatar)
		},
		methods: {
			closeDialog() {
				this.$emit('updateDialog')
			},
			cancelClick(index) {
				this.list[index].checked = false
			},
			scrollLoad() {
				console.log('下拉到底部了')
			},
			//加载文档记录
			async loaddoclist() {
				this.list = []
				var data = {
					type: 1, //0-云文档 1-会议文档 2-会议录制
					page: 1,
					limit: 999,
					parentId: this.roomId
				}
				var rs = await this.$request.post('/document/docList', data)
				if (rs.code == 200) {
					this.list = rs.data.items
				}

			},
		},
	};
</script>

<style scoped lang="less">
	.frame-item {
		margin-top: 14px;
		width: 587px;
		padding: 15px 19px;
		// height: 61px;
		background: #F9F9F9;
		border-radius: 8px 8px 8px 8px;


		.item-icon {
			width: 8px;
			height: 9px;
			background: #366EF4;
			border-radius: 50%;
		}

		.item-text {
			margin-left: 5px;
			font-weight: 400;
			font-size: 10px;
			color: #366EF4;
		}

		.item-time {
			margin-left: 12px;
			font-weight: 400;
			font-size: 10px;
			color: #333333;
		}

		.item-line {
			margin-top: 11px;
			margin-left: 13px;
			width: 564px;
			height: 0px;
			border: 1px solid #707070;
			opacity: 0.17;
		}
	}

	.myDialogClass {
		border-radius: 16px;
	}

	.el-dialog .el-dialog__footer {
		padding: 10px;
	}

	/deep/.el-dialog__body {
		padding: 0 1vw;
	}

	/deep/.el-checkbox__inner {
		width: 21px;
		height: 21px;
		border: 2px solid #9B9B9B;
		border-radius: 50%;
	}

	/* 对勾样式 */
	/deep/.el-checkbox__inner::after {
		border: 2px solid #fff;
		border-left: 0;
		border-top: 0;
		left: 6px;
		top: 3px;
	}

	/deep/.memberInput .el-input__inner {
		width: 836px;
		height: 56px;
		background: #FFFFFF;
		border-radius: 16px 16px 16px 16px;
		border: 1px solid #B0B1B4;
	}

	.myDialogFooter .el-button {
		// border-color: rgb(42,155,152);
		// background-color: rgb(242,249,249);
		// color: rgb(42,155,152);
		width: 113px;
		height: 45px;
		background: #FFFFFF;
		border-radius: 16px 16px 16px 16px;
		border: 2px solid #0052D9;
		font-size: 16px;
		color: #0052D9;
	}
</style>