<template>
	<div class="overlayPhone flexcen" @click="handleOverlayClick" id="overlay" ref="tag"
		:style="{'z-index':visible?'100':'-100' }">
		<div class="frame flexcumal" v-if="visible">
			<div class="flexcumal" style="flex-direction: row;">
				<template v-for="(item,index) in ulist">
					<div style="margin-right: 10px;" :key="index" v-if="item.state<2">
						<video :id="`remoteVideo_${item.uid}`" width="78px" height="100px" autoplay
							:muted="item.uid==myuser.ID?true:false" :poster="item.uavatar"
							:style="`border: 1px solid black;margin-top:50px;`"></video>
						<!-- <img :src="item.uavatar" style="width: 78px;height: 78px;border-radius: 50%;margin-top: 74px;"> -->
						<div class="flexcen">
							<div style="font-weight: 500;font-size: 16px;color: #FFFFFF;margin-top: 6px;">{{item.uname}}
							</div>
						</div>
						<div class="cancel-frame flexcen" v-if="item.state==2">呼叫 {{item.state==2?'未接通':'已挂断'}}</div>
						<div style="font-weight: 400;font-size: 16px;color: #FFFFFF;margin-top: 33px;margin-bottom:60px;text-align: center"
							v-else>
							{{item.state == 0?'拨打中':'通话中'}}......
						</div>
					</div>
				</template>
			</div>

			<div class="flexal">
				<div class="flexcumal">
					<img :src="require('../../assets/img/popUp/tele-stop.png')"
						style="width: 61px;height: 61px;border-radius: 50%;" @click="cancelClick()">
					<div style="font-weight: 400;font-size: 13px;color: #FFFFFF;margin-top: 8px;">取消</div>
				</div>
				<div v-if="isfaqi<1&&!isoktel" style="margin-left: 66px;" class="flexcumal">
					<img :src="require('../../assets/img/popUp/tele-pay.png')"
						style="width: 61px;height: 61px;border-radius: 50%;" @click="okTelClick()">
					<div style="font-weight: 400;font-size: 13px;color: #FFFFFF;margin-top: 8px;">接通</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import store from '@/store'

	export default {
		name: 'telephonePop',
		// 是否显示弹窗
		//  dialogVisibleShow: {
		//        type: Boolean,
		//        default: false
		//    },
		data() {
			return {
				visible: false,
				avatar: 'https://cdn.uviewui.com/uview/album/8.jpg',
				cancelTele: false, //挂断
				zoomValues: 0,
				ulist: [], //通话用户
				teltype: 0, //0:语音通话 1:视频通话

				myuser: {}, //当前用户
				isfaqi: 1, //发起标识 0:被呼叫 1:呼起
				isoktel: false, //是否接通
				localMediaStream: null, //本地音视频流
				BlowShow: false, //放大还是缩小
				msgid: 0,
				telmsg: null,
			};
		},
		watch: {
			visible: {
				handler(New, Old) {
					if (New) {
						console.log('进入通话',New)
						if (this.$eventBus) {
							this.$eventBus.$on("rtcmessage", (wdata) => {
								if (wdata.msgid == this.msgid) {
									console.log('rtcmessage', wdata)
									if (wdata.code == 'Answer' && this.isoktel) {
										this.receiveAnswer(wdata.sendid, wdata.data)
									} else if (wdata.code == 'Offer' && this.isoktel) {
										this.receiveOffer(wdata.sendid, wdata.data)
									} else if (wdata.code == 'Candidate' && this.isoktel) {
										this.saveIceCandidate(wdata.sendid, wdata.data)
									} else if (wdata.code == 'PutThrough' && this.isoktel) {
										var uid = wdata.sendid
										var uindex = this.ulist.findIndex(a => a.uid == uid)
										if (uindex >= 0) {
											this.ulist[uindex].state = 1
											this.updMsgContentFun(1, this.ulist, this.telmsg)
										}
									} else if (wdata.code == 'JoinUser') {
										var uid = wdata.sendid
										var uindex = this.ulist.findIndex(a => a.uid == uid)
										if (uindex < 0) {
											var uinfo = JSON.parse(telmsg.data)
											this.ulist.push({
												uid: uinfo.ID,
												uavatar: uinfo.Avatar,
												uname: uinfo.UserName,
												state: 0,
												rtcPeerConnection: null,
											})
											this.updMsgContentFun(1, this.ulist, this.telmsg)
										}
									} else if (wdata.code == 'Over') {
										this.handOverTel(wdata.sendid)
									}

								}
							});
						}
					} else {
						console.log('通话关闭')

						//销毁事件
						if (this.$eventBus) {
							this.$eventBus.$off("rtcmessage")
						}

						if (this.localMediaStream) {
							// 关闭摄像头和麦克风
							this.localMediaStream.getTracks().forEach(track => {
								track.stop();
							});
							this.localMediaStream = null
						}

						if (this.ulist) {
							this.ulist.map((it, index) => {
								if (it.rtcPeerConnection) {
									let pc = it.rtcPeerConnection
									// 关闭所有已经打开的数据流追踪
									if (pc.getSenders) {
										pc.getSenders().forEach(function(sender) {
											sender.track ? sender.track.stop() : null;
										});
									}

									// 关闭所有已经创建的媒体通道
									pc.getTransceivers().forEach(function(transceiver) {
										if (transceiver.stop) {
											transceiver.stop();
										}
									});

									// 关闭信令机制，并且断开所有的网络连接
									pc.close();
								}
							})
							this.ulist = []
						}
						this.isoktel = false
						this.telmsg = null
					}
				},
				deep: true, // 可以深度检测到 obj 对象的属性值的变化
				immediate: true //刷新加载  立马触发一次handler
			},

		},
		methods: {
			show(type, userlist, msgId, isfq) {

				//设置通话状态

				this.$localdic.set('overlayPhoneShow', 1)

				this.$localdic.get('udata').then(udata => {
					if (udata) {
						var user = JSON.parse(udata);
						this.myuser = user

						this.teltype = type
						this.isfaqi = isfq
						this.ulist = []
						this.msgid = msgId
						this.getmsginit()
						console.log('msgid', msgId)
						userlist.map(it => {
							this.ulist.push({
								uid: it.ID,
								uavatar: it.Avatar,
								uname: it.UserName,
								state: 0, //状态 0-拨打中 1-已接通 2-未接通 3-已挂断 
								telmin: 0, //通话时长(秒)
								rtcPeerConnection: null,
							})
						})

						this.visible = true;
						this.cancelTele = false

						if (isfq > 0) {
							this.okTelClick()
							//发起通话用户检查2分钟后是否有用户接通
							setTimeout(() => {
								if (this.ulist.filter(a => a.state == 1).length <= 1) {
									// console.log('我进来了，当前只剩下一个人')
									//挂断
									this.cancelClick()
								}
							}, 1000 * 60 * 2)

						}
						console.log('触发了', this.ulist)
					}
				})

			},
			hide() {
				this.visible = false;
				console.log('关闭了', this.zoomValue)
			},
			handleOverlayClick() {
				this.$emit('click');
			},
			//获取msg信息
			async getmsginit() {
				this.telmsg = await this.getonebymsgid(this.msgid)
			},
			//更新msg信息
			updMsgContentFun(mstate, udata, tmsg) {
				//相差毫秒
				if (tmsg && tmsg.CreateTime) {
					var ctime = new Date(tmsg.CreateTime)
					var diff = Math.abs(new Date().getTime() - ctime.getTime());
					var diffInSeconds = Math.floor(diff / 1000); //通话时长(秒)
					var msgcontent = {
						ulist: udata,
						state: mstate, //呼叫中 1-通话中 2-已挂断
						times: diffInSeconds, //通话时长
					}
					let upd = `${JSON.stringify(msgcontent)}`
					this.msgUpdContent(this.msgid, upd).then(rs => {
						console.log("通话更新消息：", rs);
					})
				}
			},
			//挂断
			cancelClick() {
				this.cancelTele = true
				this.updMsgContentFun(2, this.ulist, this.telmsg)
				this.sendOver()
				setTimeout(() => {
					this.visible = false
					//显示通话状态
					this.$localdic.set('overlayPhoneShow', 0)
				}, 1000)
			},
			//收到挂断请求
			handOverTel(uid) {
				var index = this.ulist.findIndex(a => a.uid == uid)
				if (index >= 0) {
					console.log('handOverTel', index)
					this.ulist[index].state = 2
					this.updMsgContentFun(1, this.ulist, this.telmsg)
				}
				// console.log('我被触发了',this.ulist)
				//参与用户全部挂断，结束通话
				if (this.ulist.filter(a => a.state == 1).length <= 1) {
					// console.log('我进来了，当前只剩下一个人')
					//挂断
					this.cancelClick()
				}
			},

			//发送Over请求
			sendOver() {
				//通知其它用户我已挂断
				this.ulist.map(async it => {
					if (this.myuser.ID != it.uid) {
						var rtcdata = JSON.stringify({
							type: 999,
							code: 'Over',
							userid: it.uid,
							msgid: this.msgid,
							sendid: this.myuser.ID, //发送用户(我)Id
							data: '结束通话'
						});
						var data = {
							UserId: it.uid,
							MsgContent: rtcdata
						}
						var rs = await this.$request.post('/chat/sendrtcMsg', data)
					}
				})
			},

			//发送rtc请求
			async sendRtcMsgFun(uid, tdata, code = 'Offer') {
				var rtcdata = JSON.stringify({
					type: 999,
					code: code,
					userid: uid,
					msgid: this.msgid,
					sendid: this.myuser.ID, //发送用户(我)Id
					data: tdata
				});
				var data = {
					UserId: uid,
					MsgContent: rtcdata
				}
				var rs = await this.$request.post('/chat/sendrtcMsg', data)
				if (rs.code == 200) {

				}

			},
			//接通
			okTelClick() {
				console.log('接通了')
				this.isoktel = true
				var myuserid = this.myuser.ID
				//打开本地音视频后
				this.openLocalMedia(() => {
					console.log('打开本地音视频后执行')
					if (this.localMediaStream) {
						var id = `remoteVideo_${myuserid}`
						//显示 我的本地视频流
						let myVideo = document.getElementById(id);
						myVideo.srcObject = this.localMediaStream;
					}

					this.ulist.map((it, index) => {
						if (it.uid != myuserid) {
							this.sendRtcMsgFun(it.uid, '', 'PutThrough') //向其它用户发送接通状态
							//创建rtc
							this.createPeerConnection(it.uid)
							if (this.isfaqi < 1) {
								//创建offter后给用户发送offter请求
								this.createOffer(it.uid, (userid, sdp) => {
									this.sendRtcMsgFun(userid, sdp, 'Offer')
								})
							}
						} else {
							it.state = 1
						}
					})

				})

			},

			// 创建 PeerConnection 对象
			createPeerConnection(uid) {
				var index = this.ulist.findIndex(a => a.uid == uid)
				if (index >= 0) {
					//ice服务器信息, 用于创建 SDP 对象
					let iceServers = {
						"iceServers": [{
								"url": "stun:stun.l.google.com:19302"
							},
							//{ "urls": ["stun:159.75.239.36:3478"] },
							//{ "urls": ["turn:159.75.239.36:3478"], "username": "chr", "credential": "123456" },
						]
					};
					var rtcPeerConnection = new RTCPeerConnection(iceServers)
					 console.log('rtcPeerConnection创建', rtcPeerConnection)


					//将 音视频流 添加到 端点 中

					for (const track of this.localMediaStream.getTracks()) {
						rtcPeerConnection.addTrack(track, this.localMediaStream);
					}

					rtcPeerConnection.ontrack = (event) => {
						if (event) {
							console.log('流 的回调-' + index)
							var stream = event.streams[0]
							//绑定远程视频流
							var id = `remoteVideo_${uid}`
							//显示 远程视频流
							let remoteVideo = document.getElementById(id);
							remoteVideo.srcObject = stream;
							// remoteVideo.play();

						}
					};
					rtcPeerConnection.onconnectionstatechange = () => {
						console.log(`rtc 连接状态-${index}: `, rtcPeerConnection.connectionState);
					};

					rtcPeerConnection.oniceconnectionstatechange = () => {
						console.log(`ICE 连接状态-${index}: `, rtcPeerConnection.iceConnectionState);
					};
					rtcPeerConnection.onicegatheringstatechange = () => {
						console.log(`ICE 收集状态-${index}: `, rtcPeerConnection.iceGatheringState);
					};

					this.ulist[index].rtcPeerConnection = rtcPeerConnection

					// console.log('创建rtc成功')

					//绑定 收集 candidate 的回调
					this.bindOnIceCandidate(uid, (uid, candidate) => {
						//发送candidate信息,用于交换
						this.sendRtcMsgFun(uid, candidate, 'Candidate')

					})
					// console.log('创建视频回调成功')
				}
			},
			//打开本地音视频流
			openLocalMedia(callback) {
				if (!this.localMediaStream) {

					console.log('打开本地视频流');
					// 本地音视频信息, 用于 打开本地音视频流

					this.$localdic.get('isCamera').then(iscamera => {
						const mediaConstraints = {
							video: iscamera > 0, //  this.teltype > 0,
							audio: true, //由于没有麦克风，所有如果请求音频，会报错，不过不会影响视频流播放
						};
						//getUserMedia
						if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
							navigator.mediaDevices.getUserMedia(mediaConstraints).then(stream => {
								this.localMediaStream = stream;
								callback();
							}).catch((e) => {
								console.log('打开本地音视频流错误', e)
							})

						}
					})
				}
			},

			//收到Answer请求
			async receiveAnswer(uid, sdp) {
				this.saveSdp(uid, sdp, () => {
					console.log('收到Answer请求保存后执行')

				})
			},

			//收到offter请求
			async receiveOffer(uid, sdp) {
				this.createPeerConnection(uid)

				this.saveSdp(uid, sdp, () => {
					this.createAnswer(uid, (userid, answer) => {
						this.sendRtcMsgFun(userid, answer, 'Answer')
					})
				})
			},

			//创建用于 offer 的 SDP 对象
			createOffer(uid, callback) {
				var index = this.ulist.findIndex(a => a.uid == uid)
				if (index >= 0) {

					// 创建 offer 的信息
					const offerOptions = {
						iceRestart: true,
						offerToReceiveAudio: true, //由于没有麦克风，所有如果请求音频，会报错，不过不会影响视频流播放
					};
					// 调用PeerConnection的 CreateOffer 方法创建一个用于 offer的SDP对象，SDP对象中保存当前音视频的相关参数。
					this.ulist[index].rtcPeerConnection.createOffer(offerOptions)
						.then(sdp => {
							// 保存自己的 SDP 对象
							this.ulist[index].rtcPeerConnection.setLocalDescription(sdp)
								.then(() => callback(uid, sdp));
						})
						.catch(() => console.log('createOffer 失败'));
				}
			},
			//创建用于 answer 的 SDP 对象
			createAnswer(uid, callback) {
				var index = this.ulist.findIndex(a => a.uid == uid)
				if (index >= 0) {
					// 创建 offer 的信息
					const offerOptions = {
						iceRestart: true,
						offerToReceiveAudio: true, //由于没有麦克风，所有如果请求音频，会报错，不过不会影响视频流播放
					};
					// 调用PeerConnection的 CreateAnswer 方法创建一个 answer的SDP对象
					this.ulist[index].rtcPeerConnection.createAnswer(offerOptions)
						.then(sdp => {
							// 保存自己的 SDP 对象
							this.ulist[index].rtcPeerConnection.setLocalDescription(sdp)
								.then(() => callback(uid, sdp));
						}).catch(() => console.log('createAnswer 失败'))
				}
			},


			//保存远程的 SDP 对象
			saveSdp(uid, answerSdp, callback) {
				var index = this.ulist.findIndex(a => a.uid == uid)
				if (index >= 0) {
					this.ulist[index].rtcPeerConnection.setRemoteDescription(
							new RTCSessionDescription(answerSdp))
						.then(callback);
				}
			},
			// 、保存 candidate 信息
			saveIceCandidate(uid, candidate) {
				var index = this.ulist.findIndex(a => a.uid == uid)
				if (index >= 0) {

					var rtc = this.ulist[index].rtcPeerConnection
					let iceCandidate = new RTCIceCandidate(candidate);
					this.ulist[index].rtcPeerConnection.addIceCandidate(iceCandidate)
						.then(() => {
							// console.log(`addIceCandidate成功 ${index}`, this.ulist[index].rtcPeerConnection.iceConnectionState)
						}).catch((e) => {
							console.log('saveIceCandidate 失败', e)
						});

				}
			},
			// 收集 candidate 的回调
			bindOnIceCandidate(uid, callback) {
				var index = this.ulist.findIndex(a => a.uid == uid)
				if (index >= 0) {
					// 绑定 收集 candidate 的回调
					this.ulist[index].rtcPeerConnection.onicecandidate = (event) => {
						if (event.candidate) {
							callback(uid, event.candidate);
						}
					}
				}
			},
		}
	}
</script>

<style scoped lang="less">
	.overlayPhone {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background-color: rgba(0, 0, 0, 0);
		/* Adjust transparency as needed */
		z-index: 100;
		/* Ensure it sits on top of other content */

		.frame {
			width: 736px;
			height: 411px;
			background: #272B34;
			border-radius: 24px 24px 24px 24px;
		}

		.cancel-frame {
			margin-top: 29px;
			margin-bottom: 31px;
			width: 214px;
			height: 52px;
			background: #FFFFFF;
			border-radius: 14px 14px 14px 14px;
			border: 2px solid #366EF4;
			font-size: 19px;
			font-weight: 400;
			color: #366EF4;
		}
	}
</style>