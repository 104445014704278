<template>
	<div>
		<el-dialog :title="title" :visible.sync="dialogVisible" width="648px" top="10vh" class="myDialogClass"
			@close="closeDialog">

			<div style="height: 587px;overflow: auto;" class="flexcumal">


				<div class="frame-item" v-for="(item,index) in list" :key="index">

					<div class="flexal" @click="item.checked=!item.checked;getdetail(index)">
						<div style="margin-left: 13px;flex: 1;">
							<div class="" style="font-weight: 400;font-size: 12px;color: #000000;">{{item.Name}}</div>
							<div class="flexal" style="margin-top: 5px;">
								<!-- <div class="item-icon" :style="{ 'background': item.checked? '#366EF4':'#333333' }"></div> -->
								<!-- <span class="item-text" :style="{ 'color': item.checked? '#366EF4':'#333333' }">{{ item.checked? '进行中':'已结束'}}</span> -->
								<span class="item-time">{{dateFtt(item.CreateTime,'yyyy/MM/dd hh:mm')}}</span>
							</div>
						</div>
						<img :src="require('@/assets/img/right.png')"
							style="width: 10px;height: 16px;margin-right: 20px; "
							:style="{'transform': item.checked?  'rotate(-90deg)':'rotate(90deg)'}" />
					</div>

					<div style="font-weight: 400;font-size: 13px;color: #000000;margin-left: 13px;margin-right: 13px;margin-top: 7px;"
						v-if="item.checked">
						{{item.Content }}
					</div>
					<div class="flexal" style="margin-left: 13px;margin-top: 6px;">
						<div style="font-weight: 400;font-size: 10px;color: #858A99;">{{item.total}}个人已投</div>
						<div style="font-weight: 400;font-size: 10px;color: #858A99;margin-left: 13px;">
							<span>{{item.IsAnonymous>0?'匿名':''}}</span>
							{{item.State>0? '进行中':'已结束'}}
						</div>
					</div>
					<!-- <div class="flexal" style="margin-top: 18px;" v-if="item.checked">
                    <div style="font-weight: 400;font-size: 11px;color: #424A57;flex: 1;margin-left: 13px;">签到成员</div>
                    <div style="font-weight: 400;font-size: 11px;color: #424A57;margin-right: 26px;">签到时间</div>
                    </div> -->

					<!-- 分割线 -->
					<div class="item-line" v-if="item.checked"></div>
					<template  v-for="(itProblem,index2) in item.problems">
					<div style="margin-left: 14px;" v-if="item.checked"
						:key="index2">
						<div class="flexal" style="margin-top: 10px;">
							<div style="font-weight: 400;font-size: 12px;color: #000000;">{{itProblem.Title}}</div>
						</div>
						<div v-for="(items,indexs) in itProblem.child" :key="indexs">
							<div class="flexal" style="width: 100%; margin-top: 14px;">
								<div style="flex: 1;font-weight: 400;font-size: 11px;color: #000000;">{{items.Title}}
								</div>
								<div style="font-weight: 400;font-size: 10px;color: #333333;margin-right: 20px;">
									{{items.total}}票
									{{ item.total>0? (items.total/item.total*100).toFixed(0):0 }}
								</div>
							</div>
							<el-progress style="margin-top: 10px;margin-right: 15px;"
								:percentage="(item.total>0?items.total/item.total*100:0)"
								:show-text="false"></el-progress>
						</div>

					</div>
				</template>
				</div>



			</div>

			<!--弹窗底部-->
			<template #footer>
				<span class="myDialogFooter">
					<!-- <el-button type="primary" @click="dialogVisible = false">取消</el-button>
					<el-button type="primary" @click="handleeditusers()"
						style="background: #0052D9;color: #FFFFFF;">确定</el-button> -->
				</span>
			</template>
		</el-dialog>
	</div>

</template>

<script>
	export default {
		name: 'bookEditPop',
		props: {
			// 是否显示弹窗
			dialogVisibleShow: {
				type: Boolean,
				default: false
			},
			// 弹窗标题
			title: {
				type: String,
				default: '投票'
			},
		},
		data() {
			return {
				dialogVisible: false,
				input: '',
				num: 0,
				content: '',
				useravatar: '',
				username: '',
				list: []
			};
		},
		watch: {
			// New: 新值 | Old: 老值
			dialogVisibleShow(New, Old) {
				this.dialogVisible = New
				this.useravatar = this.avatar
				this.content = this.name
				console.log(`新值:${New} ——— 老值:${Old}`)
				if (New) {
					var value = this.$store.state.messageStore.conferenceId;
					console.log(`conferenceId:${this.roomId}`)
					this.roomId = value
					this.loadlist()
				}
			},
		},
		mounted() {
			console.log('组件初始化', this.avatar)
		},
		methods: {
			closeDialog() {
				this.$emit('updateDialog')
			},
			cancelClick(index) {
				this.list[index].checked = false
			},
			scrollLoad() {
				console.log('下拉到底部了')
			},
			//加载记录
			async loadlist() {
				this.list = []
				var data = {
					RoomId: this.roomId
				}

				var rs = await this.$request.post('/meeting/voteList', data)
				if (rs.code == 200) {
					rs.data.map(it => {
						var info = {
							checked: false,
							ProblemId: it.ProblemId,
							CreateTime: it.CreateTime,
							Name: it.Name,
							State: it.State,
							total: it.total,
							Content: '',
							IsAnonymous: 0,

							problems: [],

						}
						this.list.push(info)
					})
				}
				console.log('list', this.list)

			},
			//加载投票信息
			async getdetail(index) {
				if (this.list[index].Content == '') {
					var vid = this.list[index].ProblemId
					var data = {
						ProblemId: vid
					}
					var res = await this.$request.post('/meeting/voteDetail', data)
					if (res.code == 200) {
						this.list[index].total = res.data.votecount
						this.list[index].IsAnonymous = res.data.vote.IsAnonymous
						this.list[index].Content = res.data.vote.Content
						this.list[index].problems = res.data.vote.problems
					}
				}
			},
		},
	};
</script>

<style scoped lang="less">
	.frame-item {
		margin-top: 14px;
		width: 587px;
		padding: 13px 0px;
		// height: 61px;
		background: #F9F9F9;
		border-radius: 8px 8px 8px 8px;

		.item-icon {
			width: 8px;
			height: 9px;
			background: #366EF4;
			border-radius: 50%;
		}

		.item-text {
			margin-left: 5px;
			font-weight: 400;
			font-size: 10px;
			color: #366EF4;
		}

		.item-time {
			// margin-left: 12px;
			font-weight: 400;
			font-size: 10px;
			color: #858A99;
		}

		.item-line {
			margin-top: 11px;
			margin-left: 13px;
			width: 564px;
			height: 0px;
			border: 1px solid #707070;
			opacity: 0.17;
		}
	}

	.myDialogClass {
		border-radius: 16px;
	}

	.el-dialog .el-dialog__footer {
		padding: 10px;
	}

	/deep/.el-dialog__body {
		padding: 0 1vw;
	}

	/deep/.el-checkbox__inner {
		width: 21px;
		height: 21px;
		border: 2px solid #9B9B9B;
		border-radius: 50%;
	}

	/* 对勾样式 */
	/deep/.el-checkbox__inner::after {
		border: 2px solid #fff;
		border-left: 0;
		border-top: 0;
		left: 6px;
		top: 3px;
	}

	/deep/.memberInput .el-input__inner {
		width: 836px;
		height: 56px;
		background: #FFFFFF;
		border-radius: 16px 16px 16px 16px;
		border: 1px solid #B0B1B4;
	}

	.myDialogFooter .el-button {
		// border-color: rgb(42,155,152);
		// background-color: rgb(242,249,249);
		// color: rgb(42,155,152);
		width: 113px;
		height: 45px;
		background: #FFFFFF;
		border-radius: 16px 16px 16px 16px;
		border: 2px solid #0052D9;
		font-size: 16px;
		color: #0052D9;
	}
</style>