<template>
	<div id="app">
		<!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
		<router-view />
	</div>
</template>

<script>
	import { mixins } from "./mixin/index";
	export default {
		name: "App",
		mixins: [mixins],
		components: {},
 
		created() {
			// console.log("组件调用minxi数据",this.msg);
		},
		mounted() {
			// console.log('')
			// var browerWidth = window.innerWidth; //浏览器可视宽度
			// var baseWidth = 1440; //设计稿宽度
			// var zoomValue = browerWidth / baseWidth; //缩放比例计算

			// console.log('browerWidth',browerWidth)
			// document.getElementById("app").style.transform = "scale(" + (zoomValue) + "," + (zoomValue) +
			// ")"; //mainContainer为主容器id
			// var that = this
			// window.onresize = function() { //窗口尺寸变化时，重新计算和缩放
			// 	browerWidth = window.innerWidth;
			// 	zoomValue = browerWidth / baseWidth;
			// 	that.zoomValue = zoomValue
			// 	window.localStorage.setItem('zoomValue', zoomValue)
			// 	// console.log('实时监听窗口变化',this.zoomValue)
			// 	document.getElementById("app").style.transform = "scale(" + zoomValue + "," + zoomValue + ")";
			// }
		}
	};
</script>

<style lang="less">
	html,
	body {
		font-family: Source Han Sans CN, Source Han Sans CN;
	}

	#app {
		/* font-family: Source Han Sans CN, Source Han Sans CN; */
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		// text-align: center;
		color: #2c3e50;
		
		// position: relative;
		// z-index: 2003;
		// width: 1920px;
		// height: 900px;
	}

	nav {
		padding: 30px;
	}

	nav a {
		font-weight: bold;
		color: #2c3e50;
	}

	nav a.router-link-exact-active {
		color: #42b983;
	}

	.flexal {
		display: flex;
		align-items: center;
	}

	.flexcen {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.flexspb {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.flexcum {
		display: flex;
		flex-direction: column;
	}

	.flexcumal {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.ellipsis {
		white-space: nowrap;
		/* 防止文本换行 */
		overflow: hidden;
		/* 隐藏超出部分 */
		text-overflow: ellipsis;
		/* 使用省略号表示溢出部分 */
	}

	::-webkit-scrollbar {
		width: 6px;
		/*对垂直流动条有效*/
		height: 6px;
		/*对水平流动条有效*/
	}

	
	
</style>

<style scoped lang="less">
  /* @import '../src/assets/text/text.css'; */
  /deep/ .v-modal{
		position: relative;
	}
</style>