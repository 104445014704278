let localdic = {}
//判断字典表是否存在，不存在添加
function isDicTable() {
	return new Promise((resolve, reject) => {
		if (window.electron) {
			var sql =
				"CREATE TABLE IF NOT EXISTS diclist (key TEXT, value TEXT)";
			window.electron.createTableIPC(sql).then(rs => {
				console.log('字典表创建结果true/false', rs);
				resolve(rs)
			})
		}
	});
}

//获取数据
function getValue(key) {
	return new Promise((resolve, reject) => {
		if (window.electron) {
			var sql = `select * from diclist where key=@key`;
			var parms0 = {
				key: key
			}
			window.electron.oneDataIPC(sql, parms0).then(rs => {
				// console.log(`getValue${key}结果`, rs);
				var value = ''
				if (rs && 'value' in rs) value = rs.value
				resolve(value)
			}).catch((error) => {
				console.log("getValue-promise", error);
				reject('')
			})
		} else {
			var value = localStorage.getItem(key)
			resolve(value)
		}
	});
}
//设置数据
function setValue(key, value) {
	return new Promise((resolve, reject) => {
		if (window.electron) {
			var sql = `select  count(1) as cnum from diclist where key=@key`;
			var parms0 = {
				key: key
			}
			window.electron.oneDataIPC(sql, parms0).then(res => {
				// console.log(`setValue-count`, res);
				if (res && res.cnum > 0) {
					//存在key值,更新
					var upd_sql = "update  diclist set value=@value where key=@key";
					var parms = {
						key: key,
						value: value
					}
					window.electron.modifyDataIPC(upd_sql, parms).then(updrs => {
						// console.log(`setValue-更新${key}结果`, updrs);
						resolve(updrs)
					})
				} else {

					var add_sql = "INSERT INTO diclist (key, value) VALUES (@key, @value)";
					var parms = [{
						key: key,
						value: value
					}]
					window.electron.insertDataIPC(add_sql, parms).then(res => {
						// console.log(`setValue-添加${key}结果`, res);
						resolve(res)
					})
				}
			})
		} else {
			localStorage.setItem(key, value)
			resolve(true)
		}
	});
}


localdic.set = (key, vlaue) => {
	return setValue(key, vlaue)
};

localdic.get = (key) => {
	return getValue(key)
};

localdic.istab = () => {
	isDicTable()
};

export default localdic;