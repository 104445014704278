import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "@/assets/text/text.css";
import OverlayPlugin from './util/overlay-plugin';
import TelePhone from './util/telephone-plugin';
import Computer from './util/computer-plugin';
import VideoPlayer from 'vue-video-player'
import drag from './mixin/drag'
import '@/assets/iconfont/iconfont.css' // permission control
// import tools from './util/tools'
// Vue.use(tools)
Vue.use(VideoPlayer)

// 引入富文本组件
import QuillEditor from 'vue-quill-editor'
// 引入富文本组件样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(QuillEditor)
// 引入富文本组件



import RemoteDesktop from './util/remoteDesktop-plugin';
Vue.use(RemoteDesktop);


require('vue-video-player/src/custom-theme.css')

// 引入水印文件地址
import watermark from './util/watermark.js'
Vue.prototype.$watermark = watermark



import request from '@/util/request'
Vue.prototype.$request = request;


Vue.prototype.$eventBus = new Vue();



import msgHelper from '@/util/msgHelper.js';
Vue.mixin(msgHelper)


// 引入数据存储
import localdic from '@/util/dicHelper.js'
Vue.prototype.$localdic = localdic



import {
	mixins
} from "./mixin/index";
Vue.mixin(mixins);


import {
	addressbook
} from "./mixin/address-book";
Vue.mixin(addressbook);

Vue.use(ElementUI);
Vue.use(OverlayPlugin);
Vue.use(TelePhone);
Vue.use(Computer);

Vue.config.productionTip = false


router.beforeEach((to, from, next) => {
	console.log('store', store)
	if (to.matched.some(res => res.meta.requireAuth)) { // 验证是否需要登陆
		localdic.get('userId').then(id => {
			console.log('id', id)
			if (id) { // 查询本地存储信息是否已经登陆
				console.log('我已经登录')
				next();
			} else {
				// console.log('内容输出')
				next({
					path: '/login', // 未登录则跳转至login页面
					redirect: '/' // 登陆成功后回到当前页面，这里传值给login页面，to.fullPath为当前点击的页面
				});
			}
		});

	} else {
		next();
	}
})

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')