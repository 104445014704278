<template>
	<div>
		<el-dialog :title="title" :visible.sync="dialogVisible" width="884px" class="myDialogClass" @close="closeDialog" :close-on-click-modal="false" 
			append-to-body>

			<!-- <span>这是一段信息</span> -->
			<div v-if="type == 1">
				<div style="font-weight: 400;font-size: 16px;color: #333333;margin-bottom: 15px;">群聊名称</div>
				<el-input v-model="content" class="memberInput" placeholder="请输入群聊名称"></el-input>
				<div style="font-weight: 400;font-size: 16px;color: #333333;margin-bottom: 15px;margin-top: 19px;">群聊头像
				</div>
				<div class="flexal" style="margin-bottom: 27px;margin-left: 20px;">
					<el-upload action="#" accept="image/png,image/jpg" :show-file-list="false"
						:http-request="imgUpload">
						<img :src="groupImg" style="width: 57px;height: 57px;border-radius: 50%;" />
						<div style="font-weight: 400;font-size: 16px;color: #366EF4;">点击修改</div>
					</el-upload>
				</div>
			</div>
			<div class="frame" :style="{'height': type? '300px':'531px'}">
				<div class="frame-block">
					<div class="left-item" style="margin-top: 19px;margin-left: 14px;">
						<el-input v-model="input" placeholder="搜索" @change="seachUsers(input)">
							<div slot="prefix"
								style="height: 100%;display: flex;align-items: center;justify-content: center;">
								<div class="prefix-icon3"></div>
							</div>
						</el-input>
					</div>
					<!-- <div class="flexal" style="margin-top: 20px;margin-left: 14px;">
          <img :src="require('@/assets/img/popUp/people.png')"  style="width: 30px;height: 30px;margin-right: 10px;" />
          <div class="frame-text">组织内联系人</div>
        </div> -->
					<div class="frame-people infinite-list" v-infinite-scroll="scrollLoad" style="overflow:auto"
						:style="{'height': type? '240px':'470px'}">
						<template v-for="(item,index) in list">
							<div class="flexal" style="margin-top: 22px;padding: 0px 14px;" :key="index"
								v-if="list.length!=0">
								<img :src="item.Avatar"
									style="width: 38px;height: 38px;border-radius: 50%;margin-right: 5px;" />
								<div class="frame-text" style="flex: 1;">{{item.UserName}}</div>
								<!-- <div>{{item.checked}}</div> -->
								<el-checkbox v-model="item.checked" @click="checkPeople(index)"></el-checkbox>
							</div>
						</template>
						<div style="width: 100%;height: 100%;" class="flexcen" v-if="list.length == 0">
							列表为空
						</div>
					</div>
				</div>
				<div class="frame-block" style="border-left: 1px solid #B0B1B4;">
					<div class="frame-text" style="color: #272B34;font-size: 14px;margin-top: 19px;margin-left: 15px;">
						已选择：{{num}}人</div>
					<div class="frame-people" :style="{'height': type? '254px':'485px'}">
						<template v-for="(item, index) in list">
							<div class="flexal" style="margin-top: 22px;padding: 0px 14px;" :key="index"
								v-if="item.checked == true">
								<img :src="item.Avatar"
									style="width: 38px;height: 38px;border-radius: 50%;margin-right: 5px;" />
								<div class="frame-text" style="flex: 1;">{{item.UserName}}</div>
								<img :src="require('@/assets/img/cancel.png')" @click="cancelClick(index)"
									style="width: 14px;height: 14px" />
							</div>
						</template>
					</div>
				</div>
			</div>
			<!--弹窗底部-->
			<template #footer>
				<span class="myDialogFooter">
					<el-button type="primary" @click="closeDialog()">取消</el-button>
					<el-button type="primary" @click="handlegrouopaddusers()"
						style="background: #0052D9;color: #FFFFFF;" v-if="type == 0">确定</el-button>
					<el-button type="primary" @click="handleaddgrouop()" style="background: #0052D9;color: #FFFFFF;"
						v-if="type == 1">创建</el-button>
					<el-button type="primary" @click="handledelusers()" style="background: #0052D9;color: #FFFFFF;"
						v-if="type == 2">确定</el-button>
					<el-button type="primary" @click="handlechecks()" style="background: #0052D9;color: #FFFFFF;"
						v-if="type == 3">确定</el-button>
					<el-button type="primary" @click="handleinviteusers()" style="background: #0052D9;color: #FFFFFF;"
						v-if="type == 4">邀请</el-button>
					<el-button type="primary" @click="handlecheckuserss()" style="background: #0052D9;color: #FFFFFF;"
						v-if="type == 5">确定</el-button>

				</span>
			</template>
		</el-dialog>
	</div>

</template>

<script>
	export default {
		name: 'memberPop',
		props: {
			// 是否显示弹窗
			dialogVisibleShow: {
				type: Boolean,
				default: false
			},
			// 联系人列表
			list: {
				type: Array,
				default: () => ([])
			},
			// 组件类型，0:添加群成员 1:创建群组  2:删除群成员 3:选择视频通话用户 4:邀请用户参与会议  5:日志发送人
			type: {
				type: Number,
				default: 0
			},
			//群组ID
			roomid: {
				type: Number,
				default: 0
			},
			dialogTitle: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				dialogVisible: false,
				input: '',
				num: 0,
				content: '', //群组名称
				groupImg: 'https://dzapi.youyilianjk.com/Upload/2024/0924/202409241540147077.png', //群组头像
				ulist: [],
				title: '添加群成员',
			};
		},

		watch: {
			// New: 新值 | Old: 老值
			dialogVisibleShow(New, Old) {
				this.dialogVisible = New
				// console.log(`新值:${New} ——— 老值:${Old}`)
				console.log('传入的标题', this.list)
				if (this.dialogTitle) {
					this.title = this.dialogTitle
				}
			},
			type(New, Old) {
				// console.log('类型',New,Old)
				this.title = (New == 0 ? '添加群成员' : New == 1 ? '创建群组' :
					New == 2 ? '删除群成员' : New == 3 ? '选择通话用户' :
					New == 4 ? '会议邀请用户' : New == 5 ? '发送人' : '')
				// console.log(`新值:${New} ——— 老值:${Old}`)
			},
			// dialogTitle(New, Old){
			// 	console.log('传入的标题',New)
			// },
			list: {
				handler(value) { //这是vue的规定写法，当你watch的值发生变化的时候，就会触发这个handler，这是vue内部帮你做的事情
					console.log('数组变化',value)
					let num = 0
					value.forEach(element => {
						if (element.checked == true) {
							num++
						}
					});
					this.num = num
				},
				deep: true, // 可以深度检测到 obj 对象的属性值的变化
				immediate: true //刷新加载  立马触发一次handler
			},
		},
		mounted() {
			// console.log('组件初始化')
		},
		methods: {
			seachUsers(name) {
				if (this.ulist.length <= 0) this.ulist = this.list
				this.list = this.ulist.filter(a => a.UserName.indexOf(name) > -1);
			},
			// 弹窗关闭前回调
			// closeDialogBefore(){
			// 	console.log('关闭前')
			// },
			closeDialog() {
				console.log('我被取消了', this.dialogVisibleMember)
				this.dialogVisible = false
				// this.dialogVisibleMember = 'false'
				// if (this.type == 1) {
				// 	this.$emit('updateDialog', this.list)
				// } else if (this.type == 3) {
				// 	this.$emit('updateDialog_message')
				// } else {
				// 	this.$emit('updateDialog')
				// }
				if(this.type == 5){
					this.$emit('CloseUpdateDialog')
				}else{
					this.$emit('updateDialog')
				}
				
				// this.$emit('update:dialogVisibleMember', false)
			},
			cancelClick(index) {
				// console.log('关闭前',e)
				// this.checkList.push(list[index])
				this.list[index].checked = false
			},
			scrollLoad() {
				console.log('下拉到底部了')
			},
			//提交图片
			async imgUpload(item) {
				const formData = new FormData()
				var fileno = ''
				if (fileno && fileno != '') formData.append('uploadfileNo', fileno)
				formData.append('uploadfile', item.file)
				var res = await this.$request.post('/upload/UploadFile', formData)
				console.log(res)
				if (res.code == 200) {
					//https://dzapi.youyilianjk.com/Upload/2024/0924/202409241540147077.png
					this.groupImg = res.data
				}
			},
			//创建群组
			async handleaddgrouop() {
				if (!this.content || this.content == '') {
					this.$message.error('名称不能为空')
					return false
				}
				var uchecks = []
				this.list.map(it => {
					if (it.checked) uchecks.push({
						UserId: it.ID
					})
				})
				if (uchecks.length < 1) {
					this.$message.error('请选择人')
					return false
				}
				var data = {
					RoomType: 1,
					Name: this.content,
					Avatar: this.groupImg,
					userlist: uchecks
				}
				var res = await this.$request.post('/chat/CrateRoom', data)
				console.log(res)
				if (res.code == 200) {
					this.dialogVisible = false
					this.groupImg = 'https://dzapi.youyilianjk.com/Upload/2024/0924/202409241540147077.png' //群组头像
					this.content = ''
					this.list.forEach(element => {
						element.checked = false
					});
				}
			},
			//添加群成员
			async handlegrouopaddusers() {
				console.log('添加群成员')
				var uchecks = []
				var udata = []
				this.list.map(it => {
					if (it.checked) {
						udata.push(it)
						uchecks.push({
							UserId: it.ID
						})
					}
				})
				if (uchecks.length < 1) {
					this.$message.error('请选择人')
					return false
				}
				var data = {
					RoomId: this.roomid,
					userlist: uchecks
				}
				var res = await this.$request.post('/chat/addUsers', data)
				console.log(res)
				if (res.code == 200) {
					this.dialogVisible = false
					this.$emit('updateDialog', udata)
				}

			},

			//删除群成员
			async handledelusers() {

				console.log('删除群成员')
				var uchecks = []
				var udata = []
				this.list.map(it => {
					if (it.checked) {
						udata.push(it)
						uchecks.push({
							UserId: it.ID
						})
					}
				})
				if (uchecks.length < 1) {
					this.$message.error('请选择人')
					return false
				}
				var data = {
					RoomId: this.roomid,
					userlist: uchecks
				}
				var res = await this.$request.post('/chat/delUsers', data)
				console.log(res)
				if (res.code == 200) {
					this.dialogVisible = false
					this.$emit('updateDialog', udata)
				}

			}, //选择视频通话用户
			//选择通话人员
			async handlechecks() {
				var udata = []
				this.list.map(it => {
					if (it.checked) {
						udata.push(it)
					}
				})
				if (udata.length < 1) {
					this.$message.error('请选择用户')
					return false
				}
				this.dialogVisible = false
				this.$emit('updateDialog_message', udata)
			},
			//选择人员
			async handlecheckuserss() {
				var udata = []
				this.list.map(it => {
					if (it.checked) {
						udata.push(it)
					}
				})
				if (udata.length < 1) {
					this.$message.error('请选择用户')
					return false
				}
				this.dialogVisible = false
				console.log('udata',udata)
				this.$emit('updateDialog', udata)
			},
			//选择邀请加入会议用户
			async handleinviteusers() {
				var uchecks = []
				var udata = []
				this.list.map(it => {
					if (it.checked) {
						udata.push(it)
						uchecks.push({
							UserId: it.ID
						})
					}
				})
				if (uchecks.length < 1) {
					this.$message.error('请选择人')
					return false
				}
				var data = {
					RoomId: this.roomid,
					userlist: uchecks
				}
				var res = await this.$request.post('/chat/sendMettingInviteMsg', data)
				console.log(res)
				if (res.code == 200) {
					this.$message.success('成功')
					this.dialogVisible = false
					this.$emit('updateDialog', udata)
				}

			},


		},
	};
</script>

<style scoped lang="less">
	/* .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0);
    z-index: 9999;
  } */

	.myDialogClass{
		// height: 90vh;
	}

	.frame {
		width: 836px;
		height: 531px;
		background: #FFFFFF;
		border-radius: 16px 16px 16px 16px;
		border: 1px solid #B0B1B4;
		display: flex;

		.frame-block {
			width: 50%;
		}

		.left-item {
			display: flex;
			width: 390px;
			align-items: center;
			border-radius: 8px;

			.prefix-icon3 {
				margin-left: 5px;
				width: 15px;
				height: 15px;
				background-image: url('../../assets/img/navigation/search.png');
				background-size: cover;
				/* 确保图像覆盖整个背景区域 */
				background-position: center;
				/* 图像居中对齐 */
				background-repeat: no-repeat;
				/* 防止图像重复 */
			}

			/deep/.el-input__inner {
				height: 36px;
				font-size: 15px;
				border-radius: 8px;
			}
		}

		.frame-text {
			font-weight: 400;
			font-size: 16px;
			color: #000000;
		}

		.frame-people {
			height: 420px;
			// background: #F8F9FA;
			// background-color: #000000;
			z-index: 101;
			overflow: auto;
			/* Ensure it sits on top of other content */
			// -webkit-overflow-scrolling: touch;
			// overflow-scrolling: touch;
			// overflow-y: auto;
			// overflow-x: hidden;
		}
	}

	.el-dialog .el-dialog__footer {
		padding: 10px;
	}

	/deep/.el-dialog__body {
		padding: 0 1vw;
	}

	/deep/.el-checkbox__inner {
		width: 21px;
		height: 21px;
		border: 2px solid #9B9B9B;
		border-radius: 50%;
	}

	/* 对勾样式 */
	/deep/.el-checkbox__inner::after {
		border: 2px solid #fff;
		border-left: 0;
		border-top: 0;
		left: 6px;
		top: 3px;
	}

	/deep/.memberInput .el-input__inner {
		width: 836px;
		height: 56px;
		background: #FFFFFF;
		border-radius: 16px 16px 16px 16px;
		border: 1px solid #B0B1B4;
	}

	.myDialogFooter .el-button {
		// border-color: rgb(42,155,152);
		// background-color: rgb(242,249,249);
		// color: rgb(42,155,152);
		width: 113px;
		height: 45px;
		background: #FFFFFF;
		border-radius: 16px 16px 16px 16px;
		border: 2px solid #0052D9;
		font-size: 16px;
		color: #0052D9;
	}
</style>