<template>

	<div style="display: flex;">
		<!-- 分组 -->
		<div class="frame-group" v-if="groupShow">
			<div class="flexal" style="margin-top: 15px;margin-left: 12px;margin-bottom: 10px;">
				<img :src="require('@/assets/img/message/message.png')" class="imgIcon"
					@click="groupShow = !groupShow" />
			</div>
			<div class="frame-group-item flexal" v-for="(item,index) in tabList" :key="index"
				:class="tabListIndex ==  index? 'frame-group-item-active':''" @click="tabListIndex = index">
				<img :src="tabListIndex ==  index? item.chooseIcon:item.icon" class="imgIcon"
					style="margin-left: 12px;" />
				<div class="text">{{item.text}}</div>
			</div>
		</div>
		<!-- 消息 -->
		<div class="farme-people">
			<!-- 标题 -->
			<div class="flexal" style="margin-top: 15px;margin-left: 14px;margin-bottom: 10px;">
				<img :src="require('@/assets/img/message/message.png')" class="imgIcon" @click="groupShow = !groupShow"
					v-if="!groupShow" />
				<div class="people-title" @click="flicker">消息</div>
				<!-- <i class="iconfont icon-close" style="font-size: 24px"></i> -->
				<!-- <i title="缩小" class="iconfont icon-suoxiao" style="font-size: 24px" @click="minimize"></i> -->
				<!-- <i title="缩小" class="iconfont icon-fangda" style="font-size: 24px" @click="minimize"></i> -->
			</div>
			<div class="frame-people-item" v-infinite-scroll="scrollLoad">
				<template v-for="(item,index) in chatlist">
					<div :key="index" v-if="!item.State">
					<div class="people-item flexal" :class="listIndex ==  index? 'people-item-active':''" 
						@click="submit(index)" @contextmenu.prevent="openMenu($event,item,index)"
						v-if="item.Id>0&&(tabList[tabListIndex].type<0|| tabList[tabListIndex].type==item.type)">
						<!-- 头像 -->
						<div style="display: flex;">
							<!-- 群九宫格头像 -->
							<!-- <div class="avatar" v-if="item.avatarList.length !=0">
           <img v-for="(items,indexs) in item.avatarList" :src="items" :class="computedAvatar(item.avatarList)" :key="indexs"  />
          </div> -->
							<img :src="item.avatar" class="imgIcon-avatar" />

							<div class="people-bubble flexcen" v-if="item.NotDisturb<1&&item.count>0"
								:class=" item.count >=10?'people-bubble-both':''">{{item.count}}</div>
						</div>
						<!-- 名称+内容 -->
						<div style="margin-left: 10px;flex: 1;">
							<!-- <div class="flexal"> -->
							<div class="people-text flexal">
								{{item.RoomName}}
								<img :src="require('@/assets/img/message/start.png')" v-if="item.IsStar>0"
									style="width: 16px;height: 16px;margin-left: 7px;" />
							</div>

							<!-- </div> -->
							<div class="people-content" v-if="item.msg">
								<span class="people-content" v-if="item.IsCall>0" style="color: #D54941;">@我</span>
								{{item.msg.MsgType==0||item.msg.MsgType==3?item.msg.MsgContent:item.msg.MsgType==1?'[语音]':
							item.msg.MsgType==2?'[视频]':item.msg.MsgType==4?'[文件]':item.msg.MsgType==5?'[图片]':
							item.msg.MsgType==6?'[云文档]':item.msg.MsgType==7?'[语音通话]':item.msg.MsgType==8?'[视频通话]':item.msg.MsgType==10? '签收消息':'' }}
							</div>
						</div>
						<!-- 时间+图标 -->
						<div style="text-align: right;margin-right: 14px;">
							<div style="display: flex;">
								<!-- <div class="people-time">{{dateFtt(item.UpdateTime,'MM-dd hh:mm')}}</div> -->
								<div class="people-time">{{formDataTime(item.updtime)}}</div>
								<!-- <div class="people-time">{{item.UpdateTime}}</div> -->
								<div class="triangle" v-if="item.IsTop>0"></div>
							</div>
							<img v-if="item.NotDisturb>0" :src="require('@/assets/img/message/shield.png')"
								style="width: 13px;height: 16px;margin-top: 4px;" />
							<div v-else style="width: 13px;height: 16px;margin-top: 4px;"></div>
						</div>
					</div>
				</div>
				</template>
				 <!-- 右键菜单部分 -->
				 <ul v-show="visible" :style="{left:left+'px',top:top+'px'}" class="contextmenu">
					<li @click="handleDelete">删除</li>
					<li @click="handlePinned(1)" v-if="rightClickItem.IsTop == 0">置顶</li>
					<li @click="handlePinned(0)" v-else>取消置顶</li>
					<!-- <li @click="handlePreviewFile">预览</li> -->
				</ul>
			</div>
		</div>
		<z-dialog :title="title" :show.sync="dialogVisible" :zDialogRef="'z-dialog1'" :isFooter="false" >
			<div slot="body">
				对话框body
			</div>
			<div slot="footer">
				对话框footer
			</div>
  		</z-dialog>
	</div>
</template>

<script>
	import message from '@/common/message.js'
	import store from '@/store/index.js';
	import ZDialog from '@/components/ZDialog/ZDialog.vue'
	export default {
		name: 'notice',
		props: {
			msg: String,

			ischange: {
				type: Number,
				default: 0
			},
			detailchange: {
				type: Number,
				default: 0
			},
			isinit: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				tabList: [{
						icon: require('@/assets/img/message/message1.png'),
						chooseIcon: require('@/assets/img/message/messageIcon.png'),
						text: '全部消息',
						type: -1,
					},
					{
						icon: require('@/assets/img/message/single.png'),
						chooseIcon: require('@/assets/img/message/singleIcon.png'),
						text: '单聊',
						type: 0,
					}, {
						icon: require('@/assets/img/message/group.png'),
						chooseIcon: require('@/assets/img/message/groupIcon.png'),
						text: '群组',
						type: 1,
					}, {
						icon: require('@/assets/img/message/review.png'),
						chooseIcon: require('@/assets/img/message/reviewIcon.png'),
						text: '审批',
						type: 2,
					}, {
						icon: require('@/assets/img/message/notice1.png'),
						chooseIcon: require('@/assets/img/message/noticeIcon.png'),
						text: '公告',
						type: 3,
					}, {
						icon: require('@/assets/img/message/robot1.png'),
						chooseIcon: require('@/assets/img/message/robotIcon.png'),
						text: '机器人',
						type: 5,
					}, {
						icon: require('@/assets/img/message/bell.png'),
						chooseIcon: require('@/assets/img/message/bellIcon.png'),
						text: '通知集成',
						type: 6,
					},
				],
				// type:0:单聊,1:群聊,2:审批,3:公告,4:文件传输助手,5:机器人，6:通知集成
				chatlist: [],
				listIndex: null,
				tabListIndex: 0,
				groupShow: false,
				//系统图标
				avatarlist: [
					require('@/assets/img/message/aide.png'), //文件助手
					require('@/assets/img/message/notice.png'), //公告
					require('@/assets/img/message/robot.png'), //机器人
					require('@/assets/img/message/bell1.png'), //通知
					require('@/assets/img/message/examine.png'), //审核
				],
				messageInfo: {},
				title:'提示',
       			dialogVisible: false,
				
				rightClickItem:'',
				rightClickIndex:null,
				visible: false, // 是否展示右键菜单
				top:0,
				left:0,
			}
		},
		components:{
			ZDialog
		},
		watch: {
			// 监听 visible，来触发关闭右键菜单，调用关闭菜单的方法
			visible(value) {
			if (value) {
				document.body.addEventListener('click', this.closeMenu)
			} else {
				document.body.removeEventListener('click', this.closeMenu)
			}
			},
			ischange: {
				handler(newValue, oldValue) {
				
				},
				deep: true, // 可以深度检测到 obj 对象的属性值的变化
				immediate: true //刷新加载  立马触发一次handler
			},
			isinit: {
				handler(newValue, oldValue) {
					//重新加载聊天列表
					this.loadinit()
				},
				deep: true,
				immediate: true
			},
			detailchange: {
				handler(newValue, oldValue) {
					// console.log('消息来了',this.chatlist)
					this.$localdic.get('chatData').then(data => {
						if (data && data != '') {
							// console.log('我进来了',this.listIndex)
							let chatList = JSON.parse(data)
							// this.chatlist = JSON.parse(data)
							chatList.map(async (it, index) => {
								if (it.chatType > 0) {
									it.avatar = this.avatarlist[it.chatType - 1]
								}
								if(this.listIndex == index){
									// setMessageCount
									const count = await this.msggetcount(it.RoomId)
									store.commit("setMessageCount",count);
								}
								if(this.listIndex != index){
									it.count = await this.msggetcount(it.RoomId)
								}
								// it.msg = await this.msgoneget(it.RoomId, {})
							})
							this.chatlist = chatList
						}
						// this.sortFun()
						this.loadinit()
						console.log('消息列表Notice',this.chatlist)
					})

				},
				deep: true,
				immediate: true
			}
		},
		mounted() {
			this.loadinit()
			// 刷新聊天列表
			message.$on('refresh', data => {
				// console.log('强制刷新',data)
				if (data.type == 0) {
					this.loadinit()
				} else if (data.type == 1) {
					this.loadinit()
					setTimeout(() => {
						this.submit(0, data.RoomId)
					}, 100);

				}

			})
			// this.formDataTime()
			// console.log('测试测试over',formDataTime())
		},
		methods: {
			 // 打开右键菜单
			 openMenu(e,item,index){
				this.visible = true;
				this.left = e.pageX > 470 ? 470 : e.pageX;
				this.top = e.pageY;
				// this.left = e.pageX;
				this.rightClickItem = item;
				this.rightClickIndex = index
				console.log('触发了', this.visible, this.left, this.top)
			},
			// 关闭右键菜单
			closeMenu(){
			this.visible = false;
			},
			sortFun() {
				console.log('sortFun数组我来了')
				this.chatlist.sort((a, b) => {
					if (b.IsTop == a.IsTop) {
						return b.updtime - a.updtime
					}
					return b.IsTop - a.IsTop
				})
				this.chatlist.map(async (it) => {
					it.count = await this.msggetcount(it.RoomId)
					// console.log('it',it,this.chatlist[this.listIndex])
					if (this.chatlist[this.listIndex]) {
						if (it.Id == this.chatlist[this.listIndex].Id) {
							it.count = 0
							this.$emit('noticeSubmit', this.chatlist[this.listIndex], await this.msggetcount(it.RoomId))
						}
					}
				})
				if (this.messageInfo && this.messageInfo.RoomId > 0) {
					var index = this.chatlist.findIndex(a => a.RoomId == this.messageInfo.RoomId)
					if (index >= 0) this.listIndex = index
				}
				// console.log('sortFun数组后',this.chatlist)
			},
			//加载所有的聊天
			async loadinit() {
				var res = await this.$request.post('/chat/initChatList', {})
				if (res.code == 200) {
					res.data.map(async (it, index) => {
						if (it.chatType > 0) {
							it.avatar = this.avatarlist[it.chatType - 1]
						}
						it.count = await this.msggetcount(it.RoomId)
						it.msg = await this.msgoneget(it.RoomId, {})
						it.messageDetele = false

					})
					this.chatlist = res.data
					setTimeout(() => {
						this.$localdic.set('chatData', JSON.stringify(res.data)) //localStorage.setItem('chatlist',JSON.stringify(res.data))
					}, 500)

					this.sortFun()
					// this.$forceUpdate()
					// console.log('所有的聊天.list', this.chatlist)
				}
			},

			// 滑底触发
			scrollLoad() {
				// console.log('滑底触发')
			},
			// 消息通知回调
			async submit(index, RoomId) {
				this.listIndex = index
				if (RoomId) {
					this.chatlist.forEach((element, index) => {
						if (element.RoomId == RoomId) {
							this.listIndex = index
						}
					});
				}
				const count = await this.msggetcount(this.chatlist[this.listIndex].RoomId)
				this.chatlist[this.listIndex].count = 0
				this.chatlist[this.listIndex].UpdateTime = this.dateFtt(new Date(), 'yyyy/MM/dd hh:mm:ss')
				this.messageInfo = this.chatlist[this.listIndex]
				this.cancelFlicker()
				this.$emit('noticeSubmit', this.messageInfo, count)
			},
			// 置顶
			async handlePinned(type){
				console.log('置顶消息',this.rightClickItem)
				const params = {
					Id:this.rightClickItem.Id,
					IsTop:type,
					editType:0
				}
				var rs = await this.$request.post('/chat/editRoomRole', params)
					if (rs.code == 200) {
						// this.messageDetail.IsTop = stt
						// this.$parent.refreshNotice({type:0})
						this.loadinit()
					}
			},
			// 删除聊天框
			async handleDelete(){
				// this.chatlist[this.rightClickIndex].messageDetele = true
				// if(this.rightClickIndex == this.listIndex){
				// 	this.listIndex = null
				// }
				// console.log('删除聊天',this.chatlist[this.rightClickIndex].RoomId)
				// return
				var data = {
						RoomId: this.chatlist[this.rightClickIndex].RoomId,
						// msgids: [item.MsgId],
					}
				var rs = await this.$request.post('/chat/delRoomState', data)
				if (rs.code == 200) {
					this.chatlist[this.rightClickIndex].State = 1
					// this.messageDetail.RoomName = this.groupName
				}
			},
			

		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	.farme-people {
		// margin-top: 31px;
		width: 488px;
		// height: 751px;
		height: 766px;
		background: #FFFFFF;
		border-radius: 16px 16px 16px 16px;

		.people-title {
			margin-left: 12px;
			font-size: 19px;
			font-weight: 500;
			color: #333333;
		}

		.frame-people-item {
			height: 705px;
			-webkit-overflow-scrolling: touch;
			overflow-scrolling: touch;
			overflow-y: auto;
			overflow-x: hidden;
			// background: #000;
		}

		.people-item-active {
			background: #F2F3FF;
		}

		.people-item {
			margin-top: 2px;
			margin-left: 12px;
			width: 464px;
			height: 76px;
			// background: #F2F3FF;
			border-radius: 16px 16px 16px 16px;

			.imgIcon-avatar {
				width: 52px;
				height: 52px;
				border-radius: 50%;
				margin-left: 12px;
			}

			.people-text {
				width: 300px;
				font-weight: 500;
				font-size: 16px;
				color: #333333;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				margin-top: 5px;
			}

			.people-content {
				width: 300px;
				font-weight: 400;
				font-size: 14px;
				color: #666666;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				margin-top: 5px;
			}

			.people-time {
				font-weight: 400;
				font-size: 14px;
				color: #9D9D9D;
			}

			.people-bubble {
				margin-left: -12px;
				// position: absolute;
				width: 16px;
				height: 16px;
				background: #F65160;
				font-weight: 400;
				font-size: 9px;
				color: #FFFFFF;
				border-radius: 50%;
			}

			.people-bubble-both {
				width: 18px;
				height: 18px;
			}

			.triangle {
				margin-right: -5px;
				// right: 100px;
				// margin-left: 452px;
				// margin-top: -40px;
				// position: absolute;
				width: 0;
				height: 0;
				border-top: 6px solid #366EF4;
				border-left: 6px solid transparent;
			}

		}
	}

	::-webkit-scrollbar {
		width: 6px;
		/*对垂直流动条有效*/
		height: 6px;
		/*对水平流动条有效*/
	}

	.avatar {
		width: 52px;
		height: 52px;
		overflow: hidden;
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		flex-wrap: wrap-reverse;
		border: 2px solid #36A8F4;
		border-radius: 50%;
		margin-left: 12px;
		// border: #000 1px solid;
		// margin: 30px;


		.avatarImg {
			width: 52px;
			height: 52px;
		}

		.avatarItem--1 {
			width: 98%;
			height: 98%;
		}

		.avatarItem--2 {
			width: 47%;
			height: 47%;
			margin: 1%;
		}

		.avatarItem--3 {
			width: 32%;
			height: 30%;
			margin: 1%;
		}
	}

	.frame-group {
		width: 132px;
		height: 766px;
		background: #FFFFFF;
		border-radius: 16px 16px 16px 16px;
		// margin-top: 31px;
		margin-right: 12px;

		.frame-group-item {
			margin-top: 6px;
			margin-left: 12px;
			width: 108px;
			height: 35px;
			// background: #F2F3FF;
			border-radius: 8px;
			color: #858A99;
		}

		.text {
			margin-left: 6px;
			font-weight: 400;
			font-size: 14px;

		}

		.frame-group-item-active {
			background: #EFF2FF;
			color: #0052D9;
		}
	}

	.imgIcon {
		width: 19px;
		height: 19px;
	}

	.imgIcons {
		width: 16px;
		height: 16px;
	}

	.contextmenu {
    margin: 0;
    background: #fff;
    z-index: 3000;
    position: absolute;
    list-style-type: none;
    padding: 5px 0;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
  }
  .contextmenu li {
    margin: 0;
    padding: 7px 16px;
    cursor: pointer;
  }
  .contextmenu li:hover {
    background: #eee;
  }
	
</style>