<template>
	<div class="hello">
		<div class="frame">
			<!-- 标题 -->
			<div style="width: 100%;display: flex;justify-content: flex-end;">
				<div class="frame-right" v-if="show">
					<img :src="require('@/assets/img/cancel.png')"
						style="width: 13px;height: 13px;margin-left: 236px;margin-top: 13px;" @click="show = !show" />
					<div style="padding: 0px 15px;">
						<div class="frame-right-title" style="font-weight: 500;font-size: 15px;">{{detail.cdata.Name}}
						</div>
						<div style="display: flex;">
							<div class="frame-right-text">会议ID：{{detail.cdata.RoomCode}}</div>
							<div class="copy-frame flexcen" v-clipboard:copy="detail.cdata.RoomCode"
								@click="onCopy(detail.cdata.RoomCode)">复制
							</div>
						</div>
						<div class="frame-right-text">时间：{{detail.meetingTime}}</div>
						<div class="frame-right-text">参会人：<template
								v-for="(it,index) in detail.userlist">{{index>0?'，':''}} {{it.UserName}}</template>
						</div>
					</div>
					<div class="frame-right-line"></div>
					<div style="padding: 0px 15px; margin-top: 20px;">
						<div class="frame-right-title" style="font-weight: 500;font-size: 15px;">
							{{dateFtt(detail.myuser.CreateTime,'MM/dd')}}
						</div>
						<div class="frame-right-text" v-if="detail.myuser.State==9">
							{{dateFtt(detail.myuser.UpdateTime,'hh:mm') }}离开会议
						</div>
						<div class="frame-right-text">{{dateFtt(detail.myuser.CreateTime,'hh:mm')}}加入会议</div>
					</div>
					<div class="frame-right-line"></div>
					<div style="padding: 0px 15px; margin-top: 20px;">
						<div class="frame-right-title" style="font-weight: 500;font-size: 15px;">应用</div>
						<div class="frame-right-text flexal" @click="openDialog(detail.cdata,0)">
							<img :src="require('@/assets/img/conference/signIn.png')"
								style="width: 16px;height: 14px;margin-left: 5px;" />
							<div style="font-weight: 400;font-size: 14px;color: #15181D;flex: 1;margin-left: 16px;">签到
							</div>
							<img :src="require('@/assets/img/right.png')" style="width: 5px;height: 8px;" />
						</div>
						<div class="frame-right-text flexal" @click="openDialog(detail.cdata,1)">
							<img :src="require('@/assets/img/conference/vote.png')"
								style="width: 16px;height: 14px;margin-left: 5px;" />
							<div style="font-weight: 400;font-size: 14px;color: #15181D;flex: 1;margin-left: 16px;">投票
							</div>
							<img :src="require('@/assets/img/right.png')" style="width: 5px;height: 8px;" />
						</div>
						<div class="frame-right-text flexal" @click="openDialog(detail.cdata,2)">
							<img :src="require('@/assets/img/conference/document.png')"
								style="width: 16px;height: 14px;margin-left: 5px;" />
							<div style="font-weight: 400;font-size: 14px;color: #15181D;flex: 1;margin-left: 16px;">文档
							</div>
							<img :src="require('@/assets/img/right.png')" style="width: 5px;height: 8px;" />
						</div>
						<div class="frame-right-text flexal" @click="openDialog(detail.cdata,3)">
							<img :src="require('@/assets/img/conference/98065f9cf961dde3723aaa33dae79d0.png')"
								style="width: 25px;height: 25px;" />
							<div style="font-weight: 400;font-size: 14px;color: #15181D;flex: 1;margin-left: 11px;">录制
							</div>
							<img :src="require('@/assets/img/right.png')" style="width: 5px;height: 8px;" />
						</div>
					</div>
				</div>
			</div>
			<div class="flexal" style="padding-top: 15px;margin-left: 14px;margin-bottom: 10px;">
				<div class="flexcumal" style="margin-left: 4px;" @click="handload(0)">
					<div class="people-title" :class="'people-title-active'">会议记录</div>
					<!-- <div class="title-block" v-if="index==0"></div>
					<div  style="height: 3px;" v-if="index==1"></div> -->
				</div>
				<!-- <div class="flexcumal" style="margin-left: 64px;" @click="handload(1)">
				<div class="people-title" :class="'people-title-active'">会议录制</div>
					<div class="title-block" v-if="index==1"></div>
					<div style="height: 3px;" v-if="index==0"></div>
				</div> -->
			</div>
			<div style="height: 700px;overflow: auto;" v-infinite-scroll="scrollLoad" v-if="index == 0">
				<div style="margin-bottom: 17px;" v-for="(item,index) in list" :key="index">
					<!-- <div class="frame-time" style="padding-left: 18px;">{{item.time}}</div>  -->
					<div class="frame-item flexal" @click="handelDetail(item)">
						<!-- <img :src="items.img"  class="imgIcon"  /> -->
						<div style="margin-left: 15px;">
							<div style="display: flex;width: 844px;">
								<div style="font-weight: 400;font-size: 12px;color: #858A99;flex: 1;">
									会议ID：{{item.RoomCode}}
								</div>
								<div style="font-size: 14px;color: #333333;">{{item.MeetDate}} </div>
							</div>
							<div class="flexal">
								<div style="font-weight: 500;font-size: 15px;color: #000000;margin-top: 6px;">
									{{item.Name}}
								</div>
								<div style="font-size: 14px;color: #0076D1;margin-left: 26px;" v-if="item.State==0">待开始
								</div>
								<div style="font-size: 14px;color: #2FD100;margin-left: 26px;" v-if="item.State==1">进行中
								</div>
								<div style="font-size: 14px;color: #6D6D6D;margin-left: 26px;" v-if="item.State>1">已结束
								</div>
							</div>
							<div class="flexal">
								<div style="font-weight: 400;font-size: 12px;color: #272B34;margin-top: 6px;flex: 1;">
									时长：{{item.meetingTime}}<span style="margin-left: 29px;">发起人：{{item.username}}</span>
								</div>
								<div class="frame-item-button flexcen" v-if="item.State < 2"
									@click.stop="joinhandel(item)">进入会议</div>
								<div class="frame-item-button flexcen" v-if="item.State  == 2" style="width: 90px;">
									查看会议录制</div>
							</div>
						</div>
					</div>
					<div class="frame-line"></div>
				</div>
			</div>
			<div style="height: 700px;overflow: auto;" v-infinite-scroll="scrollLoad" v-else>
				<div style="margin-bottom: 17px;" v-for="(item,index) in list" :key="index">
					<!-- <div class="frame-time" style="padding-left: 18px;">{{item.time}}</div>  -->
					<div class="frame-item flexal" @click="handelDetail(item)">
						<!-- <img :src="items.img"  class="imgIcon"  /> -->
						<!-- <div>123456</div> -->
						<div style="width: 119px;height: 70px;border-radius: 8px;" class="flexcen"
							@click.stop="openVideo('https://luosai.oss-cn-shenzhen.aliyuncs.com/Upload/Video/2024/0730/202407301050064716.mp4')">
							<img :src="require('@/assets/img/payIcon.png')"
								style="width: 19px;height: 19px;position: absolute;" />
							<video
								:src="'https://luosai.oss-cn-shenzhen.aliyuncs.com/Upload/Video/2024/0730/202407301050064716.mp4'"
								style="width:100%; height:100%; object-fit: fill;border-radius: 8px;"></video>
							<div
								style="position: absolute;font-weight: 500;font-size: 12px;color: #FFFFFF;margin-top: 45px;margin-left: 65px;">
								10:30</div>
						</div>
						<div style="margin-left: 15px;">
							<div style="display: flex;width: 725px;">
								<div style="font-weight: 400;font-size: 12px;color: #858A99;flex: 1;">
									会议ID：{{item.RoomCode}}
								</div>
								<div style="font-size: 14px;color: #333333;">{{item.MeetDate}} </div>
							</div>
							<div class="flexal">
								<div style="font-weight: 500;font-size: 15px;color: #000000;margin-top: 6px;">
									{{item.Name}}
								</div>
								<!-- <div style="font-size: 14px;color: #0076D1;margin-left: 26px;" v-if="item.State==0">待开始
								</div>
								<div style="font-size: 14px;color: #2FD100;margin-left: 26px;" v-if="item.State==1">进行中
								</div>
								<div style="font-size: 14px;color: #6D6D6D;margin-left: 26px;" v-if="item.State==9">已结束
								</div> -->
							</div>
							<div class="flexal">
								<div style="font-weight: 400;font-size: 12px;color: #272B34;margin-top: 6px;flex: 1;">
									时长：{{item.meetingTime}}<span style="margin-left: 29px;">发起人：{{item.username}}</span>
								</div>
								<!-- <div class="frame-item-button flexcen" v-if="item.State < 9"
									@click.stop="joinhandel(item)">进入会议</div> -->
							</div>
						</div>
					</div>
					<div class="frame-line"></div>
				</div>
			</div>
		</div>

		<!-- 视频预览弹窗 -->
		<videoPlayerPop :dialogVisibleShow="dialogVisibleVideoShow" @updateDialog="updateDialogClick" :VideoUrl="video">
		</videoPlayerPop>
		<!-- 开始会议 -->
		
		<!-- 签到弹窗 -->
		<signConferencePop :dialogVisibleShow="signConferencePopShow" @updateDialog="updateDialogClick">
		</signConferencePop>
		<!-- 文件弹窗 -->
		<documentConferencePop :dialogVisibleShow="documentConferencePopShow" @updateDialog="updateDialogClick">
		</documentConferencePop>
		<!-- 投票弹窗 -->
		<voteConferencePop :dialogVisibleShow="voteConferencePopShow" @updateDialog="updateDialogClick">
		</voteConferencePop>
		<!-- 录制视频弹窗 -->
		<videoConferencePop :dialogVisibleShow="videoConferencePopShow" @updateDialog="updateDialogClick"
			@openVideo="openVideo"></videoConferencePop>

		<z-dialog :title="title" :show.sync="dialogVisible" :zDialogRef="'z-dialog1'" :isFooter="false" >
		<div slot="body">
			<startConferencePop v-if="visibleStartConference" @closeSubimt="closeSubimt" :roomId="conferenceRoomId" v-drug>
			</startConferencePop>
		</div>
		<!-- <div slot="footer">
			对话框footer
		</div> -->
  		</z-dialog>

	</div>
</template>

<script>
	import startConferencePop from '../pop-up/startConferencePop.vue';
	import videoPlayerPop from '../pop-up/videoPlayerPop.vue';
	import signConferencePop from '../conferencePop/signConferencePop.vue'
	import documentConferencePop from '../conferencePop/documentConferencePop.vue'
	import voteConferencePop from '../conferencePop/voteConferencePop.vue'
	import videoConferencePop from '../conferencePop/videoConferencePop.vue'
	import ZDialog from '@/components/ZDialog/ZDialog.vue'

	export default {
		name: 'conferenceContent',

		props: {
			msg: {
				type: String,
				default: ''
			},
			isload: {
				type: Boolean,
				default: true
			},
		},
		watch: {
			isload(newval) {
				console.log('newval', newval)
				this.handload(0)
			},
			visibleVoteInConference(value){
				console.log('监听',value)
			}
		},
		components: {
			startConferencePop, //开始会议
			videoPlayerPop, //视频预览
			signConferencePop, //签到弹窗
			documentConferencePop, //文件弹窗
			voteConferencePop, //投票弹窗
			videoConferencePop, //录制视频弹窗
			ZDialog,				//弹窗
		},
		data() {
			return {
				copyText: '',
				show: false,
				total: 0,
				query: {
					page: 0,
					limit: 20,
				},
				list: [],
				detail: {},
				visibleStartConference: false,
				conferenceRoomId: 0,
				index: 0,
				video: '',
				signConferencePopShow: false,
				documentConferencePopShow: false,
				voteConferencePopShow: false,
				videoConferencePopShow: false,
				title:'提示',
       			dialogVisible: false,
			}
		},
		methods: {
			submit(index) {
				this.tabIndex = index
				this.$emit('conferenceSubmit', index)
			},
			onCopy(val) {
				// console.log('进来了')
				// this.$message.success('复制成功')
				console.log('复制按钮', val)
				// clipboard.writeText(text);
				// alert('已复制到剪贴板！');
				if (navigator.clipboard && window.isSecureContext) {
					// navigator clipboard 向剪贴板写文本
					this.$message.success('复制成功')
					return navigator.clipboard.writeText(val)
				} else {
					// 创建text area
					const textArea = document.createElement('textarea')
					textArea.value = val
					// 使text area不在viewport，同时设置不可见
					document.body.appendChild(textArea)
					textArea.focus()
					textArea.select()
					this.$message.success('复制成功')
					return new Promise((res, rej) => {
						// 执行复制命令并移除文本框
						document.execCommand('copy') ? res() : rej()
						textArea.remove()
					})
				}
			}, // 滑底触发
			scrollLoad() {
				console.log('下拉到底部了')
				if (this.query.page * this.query.limit <= this.total) {
					this.getdatas(true)
				}
			},
			handload(tabIndex) {
				// console.log('我进来了',tabIndex)
				this.list = []
				this.query.page = 0
				this.index = tabIndex
				console.log('tabIndex', tabIndex)
				if (tabIndex == 0) {
					this.getdatas(true)
				} else {

				}

			},
			async getdatas(isnext) {
				console.log('我来了')
				const loading = this.$loading({
					lock: true,
					text: 'Loading'
				});
				if (isnext) this.query.page += 1
				var res = await this.$request.post('/meeting/meetingList', this.query)
				loading.close();
				if (res.code == 200) {
					this.total = res.data.total
					this.list = this.list.concat(res.data.items)
				}
			},
			async handelDetail(row) {
				var data = {
					RoomId: row.RoomId
				}
				var res = await this.$request.post('/meeting/meetingDetail', data)
				if (res.code == 200) {
					this.detail = res.data
					this.show = true
				}
			},
			joinhandel(row) {
				console.log('joinhandel')
				this.conferenceRoomId = row.RoomId
				// this.visibleStartConference = true
				// this.dialogVisible = true
				this.$emit('oepnConferenceRoom',this.conferenceRoomId)
				return
				// if(window.electron){

				// 	window.electron.ipcRenderer.invoke('newwindow',this.conferenceRoomId).then(inputSources => {	
						
				// 	})
				// }else{
				// 	this.visibleStartConference = true
				// }
				
			},
			closeSubimt() {
				this.visibleStartConference = false
			},

			// payVideo(){
			// 	console.log('播放视频')
			// },
			// 点击查看视频
			openVideo(value) {
				console.log('我被点击了')
				this.dialogVisibleVideoShow = true
				this.video = value
			},
			// 弹窗关闭回调
			updateDialogClick() {
				// console.log('我被调用了')
				this.dialogVisibleVideoShow = false
				this.signConferencePopShow = false
				this.documentConferencePopShow = false
				this.voteConferencePopShow = false
				this.videoConferencePopShow = false
			},
			openDialog(row, type) {
				console.log('openDialog', row)
				this.$store.commit("setConferenceId", row.RoomId);
				if (type == 0) {
					//签到
					this.signConferencePopShow = !this.signConferencePopShow
				} else if (type == 1) {
					//投票
					this.voteConferencePopShow = !this.voteConferencePopShow
				} else if (type == 2) {
					//文档
					this.documentConferencePopShow = !this.documentConferencePopShow
				} else if (type == 3) {
					//录制
					this.videoConferencePopShow = !this.videoConferencePopShow
				}
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	.frame {
		// margin-top: 31px;
		margin-left: 12px;
		width: 901px;
		height: 766px;
		background: #FFFFFF;
		border-radius: 16px 16px 16px 16px;

		.people-title {
			// margin-left: 12px;
			font-size: 18px;
			font-weight: 500;
			// color: #333333;
			color: #0052D9;
		}

		.left-item {
			margin-left: 12px;
			margin-top: 5px;
			display: flex;
			width: 185px;
			align-items: center;

			.prefix-icon3 {
				margin-left: 5px;
				width: 15px;
				height: 15px;
				background-image: url('../../assets/img/navigation/search.png');
				background-size: cover;
				/* 确保图像覆盖整个背景区域 */
				background-position: center;
				/* 图像居中对齐 */
				background-repeat: no-repeat;
				/* 防止图像重复 */
			}

			.el-input__inner {
				height: 36px;
				background: #D4DFEA;
				font-size: 15px;
				border-radius: 8px;
				border: 1px solid #C4C6CD;
				color: #272B34;
			}

			.item-text {
				margin-left: 12px;
				color: #858A99;
				font-size: 16px;
				font-weight: 400;
				flex: 1;
			}

		}

		.frame-line {
			margin-top: 6px;
			width: 864px;
			height: 0px;
			border: 1px solid #707070;
			opacity: 0.43;
			margin-left: 18px;
		}

		.frame-time {
			font-size: 14px;
			color: #333333;
		}
	}

	.frame-item {
		margin-left: 8px;
		padding-left: 10px;
		width: 874px;
		height: 98px;
		// background: #F2F3FF;
		border-radius: 16px 16px 16px 16px;
		cursor: pointer;
	}

	.frame-item:hover {
		background: #F2F3FF;
	}

	.frame-right {
		position: absolute;
		width: 261px;
		height: 766px;
		background: #F5F6F7;
		box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
		border-radius: 16px 16px 16px 16px;
		z-index: 10;

		.frame-right-title {
			font-weight: 500;
			font-size: 15px;
			color: #000000;
		}

		.frame-right-text {
			margin-top: 15px;
			font-weight: 400;
			font-size: 12px;
			color: #272B34;
		}

		.frame-right-line {
			margin-top: 35px;
			width: 261px;
			height: 0px;
			border: 1px solid #707070;
			opacity: 0.43;
		}

		.copy-frame {
			width: 37px;
			height: 18px;
			background: #0052D9;
			border-radius: 5px 5px 5px 5px;
			font-weight: 400;
			font-size: 10px;
			color: #FFFFFF;
			margin-top: 13px;
			margin-left: 12px;
			cursor: pointer;
			/* 鼠标悬停时变为手指形状 */
		}
	}

	.frame-item-button {
		width: 73px;
		height: 26px;
		background: #0052D9;
		border-radius: 13px 13px 13px 13px;
		font-weight: 400;
		font-size: 12px;
		color: #FFFFFF;
	}

	.imgIcon {
		width: 127px;
		height: 74px;
		border-radius: 8px 8px 8px 8px;
	}

	.title-block {
		width: 37px;
		height: 3px;
		background: #0052D9;
		border-radius: 2px 2px 2px 2px;
	}

	.people-title-active {
		color: #0052D9;
	}
</style>