<template>
	<div style="width: 100%;float: center;display: flex;justify-content: flex-end;">
		<!-- 编辑群信息 -->
		<div class="right-frame">
			<!-- 编辑群信息 -->
			<div class="right-frame-title flexal">
				<img :src="require('@/assets/img/right.png')" style="width: 10px;height: 16px;transform: rotate(180deg)"
					@click="submit" />
				<div class="right-tips-text" style="font-size: 16px;margin-left: 20px;flex: 1;">公告详情</div>
				<img :src="require('@/assets/img/cancel.png')" style="width: 18px;height: 18px;" @click="submit" />
			</div>
			<div style="padding: 0px 18px;overflow: auto;height: 610px;">
				<img :src="poster" class="img" />
				<div v-html="content" style="width: 100%"></div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'detail',
		props: {
			nId: {
				type: Number,
				default: 0
			}

		},
		watch: {

			nId(newval) {
				if (newval > 0) {
					this.getdetail()
				}
			},
		},
		mounted() {
			this.getdetail()
		},
		data() {
			return {
				// poster: "https://luosai.oss-cn-shenzhen.aliyuncs.com/Upload/2024/0712/202407121752186069.png", //公告图片     
				poster:'',  
			// 	content: `<h1>李拴蛋李拴蛋李拴蛋李拴蛋李拴蛋李拴蛋李拴蛋李拴蛋李拴蛋李拴蛋李拴蛋李拴蛋李拴蛋李拴蛋李拴蛋</h1>
            //   <a href="http://www.baidu.com">百度</a>
            //   <span style="color:red">红色文字</span><br>
            //   <img src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg">
            //   `,
			content:''
			}
		},
		methods: {
			// 发送/关闭，按钮回调  0:关闭  1:发送
			submit() {
				var params = {
					type: 0,
					data: this.input
				}
				this.$emit('detailSubmit', params)
			},
			// 滑底触发
			scrollLoad() {
				// console.log('滑底触发')
			},
			async getdetail() {
				var data = {
					queryId: this.nId
				}
				var res = await this.$request.post('/notice/detail', data)
				if (res.code == 200) {
					console.log('0', res.data)
					this.poster = res.data.ImgUrl
					this.content = res.data.Content
				}
			},
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	.right-frame {
		margin-top: 0px;
		position: absolute;
		width: 457px;
		// height: 750px;
		height: 665px;
		background: #F8F9FA;
		z-index: 101;
		/* Ensure it sits on top of other content */

		.title-text {
			font-weight: 500;
			font-size: 16px;
			color: #333333;
		}

		.right-frame-title {
			padding: 15px 16px;
		}

		.right-line-bottom {
			margin-left: 16px;
			width: 424px;
			height: 0px;
			border: 1px solid #707070;
			opacity: 0.32;
		}

		.right-tips-text {
			font-weight: 400;
			color: #6B6B6B;
			font-size: 14px;
		}

		.img {
			width: 422px;
			height: 180px;
			background: #ACACAC;
			border-radius: 9px 9px 9px 9px;
		}
	}
</style>