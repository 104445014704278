<template>
	<div class="home" id="home">

		<div class="home-frame" id="home-frame">
			<div class="frame-left">
				<!-- 头像+添加按钮 -->
				<div class="left-item" style="justify-content: space-between;">
					<div @click="open" class="flexal">
						<el-avatar :src="udata.Avatar" style="width: 39px;height: 39px;"></el-avatar>
						<div style="margin-left: 5px;">{{ udata.UserName }}</div>
					</div>
					<div>
						<img :src="require('@/assets/img/navigation/add.png')" class="imgIcon" @click="openAddShow" />
						<div class="left-add" v-show="AddShow">
							<div style="width: 100%;height: 20%;" class="flexal" v-for="(item,index) in optTab"
								:key="index" @click="chooseOptTab(index)">
								<img :src="item.icon" class="imgIcon" style="margin-left: 12px;" />
								<div class="left-add-text">{{item.text}}</div>
							</div>
						</div>
					</div>
				</div>
				<!-- 搜索框 -->
				<div class="left-item" style="margin-top: 10px;margin-bottom: 15px;">
					<el-input v-model="input" placeholder="搜索">
						<div slot="prefix"
							style="height: 100%;display: flex;align-items: center;justify-content: center;">
							<div class="prefix-icon3"></div>
						</div>
					</el-input>
				</div>
				<!-- 左侧导航栏 -->
				<div class="left-item" :class="tabIndex ==  index? 'left-item-active':''"
					style="height: 36px;margin-top: 6px;" v-for="(item,index) in tab" :key="index"
					@click="chooseTabIndex(index)">
					<img :src=" tabIndex ==  index ? item.chooseIcon:item.icon" class="imgIcon" style="margin-left: 12px;" />
					<div class="item-text">{{item.text}}</div>
					<div class="item-bubble" v-if="item.count > 0" style="margin-right: 12px;">{{item.count}}</div>
				</div>
			</div>

			<!-- 弹窗组件集 -->
			<memberPop :dialogVisibleShow="dialogVisibleMember" @updateDialog="updateDialogClick" :list="ulist"
				:type="1" :dialogTitle="'创建群组'"></memberPop>

			<!-- 消息 -->
			<!-- 通知界面 -->
			<notice v-if="tabIndex==0" @noticeSubmit="noticeSubmit" :ischange="changeNum" :isinit="isinitNum"
				:detailchange="detailchange" ref="notice"></notice>
			<div style="height: 751px;width: 12px; background: #F3F3F4;" v-if="tabIndex==0"></div>
			<!-- 消息界面 -->
			<message v-if="tabIndex==0" :messageDetail="messageDetail"></message>

			<!-- 工作台 -->
			<!-- 工作台导航栏界面 -->
			<workNotice v-if="tabIndex==1" @workNoticeSubmit="workNoticeSubmit"></workNotice>
			<!-- 工作台列表界面 -->
			<workList v-if="tabIndex==1" :status="workListIndex"></workList>

			<!-- 云文档 -->
			<cloudIndex v-if="tabIndex==2"></cloudIndex>

			<!-- 通讯录 -->
			<!-- 通讯录导航栏界面 -->
			<bookIndex v-if="tabIndex==3" @bookIndexSubmit="bookIndexSubmit" ref="childBookIndex"></bookIndex>
			<!-- 通讯录数据列表界面 -->
			<bookList v-if="tabIndex==3" :type="bookListIndex" :title="bookListTitle" :depid="bookListDepId"
				@broClick="bookBroClick"></bookList>

			<!-- 视频会议 -->
			<!-- 会议导航栏 -->
			<conferenceTab v-if="tabIndex==4" @conferenceTabSubmit="conferenceTabSubmit" @oepnConferenceRoom="oepnConferenceRoom"></conferenceTab>
			<conferenceContent :key="refreshKey" v-if="tabIndex==4" :isload="conferenceLoad" @oepnConferenceRoom="oepnConferenceRoom"></conferenceContent>

			<!-- 日志 -->
			<!-- 日志导航栏 -->
			<logTab v-if="tabIndex==5" @titleIndex="titleIndex" @logTabSubmit="logTabSubmit"></logTab>
			<logContent v-if="tabIndex==5" :tab="logTab" :type="logListTab" :logtype="logTypeData" :logId="logcheck" :key="refreshKey"> </logContent>



			<!-- 用户编辑 -->
			<bookEditPop :dialogVisibleShow="dialogbookEditPop" @updateDialog="updateBookEditPopClick"
				:avatar="udata.Avatar" :name="udata.UserName">
			</bookEditPop>
			
			<!-- 开始会议弹窗 -->
			<z-dialog :title="title" :show.sync="dialogVisible" :zDialogRef="'z-dialog1'" :isFooter="false" @closeZDialog="closeZDialog" @isfullscreenEmit="isfullscreenEmit">
				<div slot="body">
					<startConferencePop ref="startConference" v-if="visibleStartConference" @closeSubimt="closeSubimt" :roomId="conferenceRoomId">
					</startConferencePop>
				</div>
				<!-- <div slot="footer">
					对话框footer
				</div> -->
			</z-dialog>


		</div>
	</div>
</template>

<script lang="js">
	import bookEditPop from '@/components/pop-up/bookEditPop.vue'
	import HelloWorld from '@/components/HelloWorld.vue'
	import Notice from '@/components/message/notice.vue'
	import Message from '@/components/message/message.vue'
	import memberPop from '@/components/pop-up/memberPop.vue'
	import workNotice from '@/components/workbench/workNotice.vue'
	import workList from '@/components/workbench/workList.vue'
	import cloudIndex from '@/components/cloud-document/index.vue'
	import bookIndex from '@/components/address-book/book-index.vue'
	import bookList from '@/components/address-book/book-list.vue'
	import logTab from '@/components/log/logTab.vue'
	import logContent from '@/components/log/logContent.vue'
	import conferenceTab from '@/components/conference/conferenceTab.vue'
	import conferenceContent from '@/components/conference/conferenceContent.vue'
	import { mapState, mapGetters } from 'vuex';
	import store from '@/store/index.js';
	import ZDialog from '@/components/ZDialog/ZDialog.vue'
	import startConferencePop from '@/components/pop-up/startConferencePop.vue';
	export default {
		name: 'HomeView',
		data() {
			return {
				udata: {}, //用户消息
				src: 'https://cdn.uviewui.com/uview/album/1.jpg', //头像路径
				input: '', //搜索内容
				tab: [ //左侧导航栏
					{
						text: '消息',
						icon: require('@/assets/img/navigation/message.png'),
						chooseIcon: require('@/assets/img/navigation/messageIcon.png'),
						count: 0
					}, {
						text: '工作台',
						icon: require('@/assets/img/navigation/work.png'),
						chooseIcon: require('@/assets/img/navigation/workIcon.png'),
						count: 0
					}, {
						text: '云文档',
						icon: require('@/assets/img/navigation/document.png'),
						chooseIcon: require('@/assets/img/navigation/documentIcon.png'),
						count: 0
					}, {
						text: '通讯录',
						icon: require('@/assets/img/navigation/people.png'),
						chooseIcon: require('@/assets/img/navigation/peopleIcon.png'),
						count: 0
					}, {
						text: '会议',
						icon: require('@/assets/img/navigation/video.png'),
						chooseIcon: require('@/assets/img/navigation/videoIcon.png'),
						count: 0
					},
					{
						text: '工作日志',
						icon: require('@/assets/img/navigation/log.png'),
						chooseIcon: require('@/assets/img/navigation/logIcon.png'),
						count: 0
					},
				],
				tabIndex: 5,

				optTab: [ //加号操作栏
					{
						text: '创建群组',
						icon: require('@/assets/img/navigation/create.png'),
					},
					// {
					// 	text: '添加企业成员',
					// 	icon: require('@/assets/img/navigation/add2.png'),
					// },
					{
						text: '创建文档',
						icon: require('@/assets/img/navigation/create2.png'),
					}, {
						text: '加入会议',
						icon: require('@/assets/img/navigation/add3.png'),
					}, {
						text: '发起会议',
						icon: require('@/assets/img/navigation/video2.png'),
					},
					{
						text: '退出登录',
						icon: require('@/assets/img/navigation/exit.png'),
					},
				],

				messageDetail: {}, //消息详情
				// 组织成员
				list: [],
				workListIndex: 0, //工作台导航栏下标
				bookListIndex: 0, //通讯录导航栏下标
				bookListDepId: 0, //通讯录部门id
				bookListTitle: '组织内联系人', //通讯录数据列表标题
				logTab: 0, //工作日志/查看日志
				logListTab: 0, //日报/自由汇报
				logTypeData: 0, //日志类型
				logcheck: 0, //选择的日志
				browerWidth: 0, //浏览器可视宽度
				browerHeight: 0, //浏览器可视高度度

				conferenceLoad: false,
				dialogbookEditPop: false,
				changeNum: 0, //详情修改
				isinitNum: 0, //聊天列表重新加载
				detailchange: 0, //联系信息加载
				ulist: [], //企业成员
				title:'会议中',
       			dialogVisible: false,
				visibleStartConference: false,
				conferenceRoomId: 0,
				refreshKey:0			//日志组件刷新
			};
		},
		components: {
			bookEditPop, //编辑用户信息
			HelloWorld, //测试组件
			Notice, //消息通知列表界面
			Message, //消息详情界面
			memberPop, //创建群组弹窗
			workNotice, //工作台导航栏界面
			workList, //工作台列表界面
			cloudIndex, //云文档界面
			bookIndex, //通讯录导航栏
			bookList, //通讯录数据列表界面
			logTab, //日志导航栏
			logContent, //日志内容界面
			conferenceTab, //视频会议导航栏
			conferenceContent, //视频会议内容列表
			startConferencePop,	//开始会议
			ZDialog,			//自定义弹窗
		},
		created() {
			document.addEventListener("click", this.myFunction);
			console.log('homeviewcreated我进来了')
			this.checkMediaDevices()
			this.getuser()
			// this.moudelweb()
			this.offlineMsgList()
		},
		watch: {
			messageDetail: {
				handler(newValue, oldValue) {
					this.changeNum += 1
				},
				deep: true, // 可以深度检测到 obj 对象的属性值的变化
				immediate: true //刷新加载  立马触发一次handler
			},
			tab: {
				handler(newValue, oldValue) {
					console.log('tab的修改', newValue)
				},
				deep: true, // 可以深度检测到 obj 对象的属性值的变化
				immediate: true //刷新加载  立马触发一次handler
			},
			messageCount:{
				handler(newValue, oldValue) {
					console.log('获取的参数', newValue)
					this.tab[0].count = this.tab[0].count - newValue
					store.commit("setMessageCount",0);
				},
				deep: true, // 可以深度检测到 obj 对象的属性值的变化
				
				immediate: true //刷新加载  立马触发一次handler
			}
		},

		computed: {
			...mapState({
				messageCount:state => state.messageStore.messageCount,
			}),
		},
		mounted() {
			console.log('我是首页初始化')
			this.isMsgTab().then(res => {
				console.log('isMsgTab', res)
			})
			this.deviceOnline()
			this.timer = setInterval(()=>{
				this.deviceOnline();
			},5000)

			var browerWidth = window.innerWidth; //浏览器可视宽度
			if (browerWidth > 1440) {
				document.getElementById("home").style.justifyContent = "center";
				document.getElementById("home-frame").style.alignItems = "center";
			} else {
				// console.log('我进来了')
				document.getElementById("home").style.justifyContent = "flex-start";
				document.getElementById("home-frame").style.alignItems = "flex-start";
			}
			// var baseWidth = 1440; //设计稿宽度
			// var zoomValue = browerWidth / baseWidth; //缩放比例计算

			// console.log('browerWidth',browerWidth)
			// document.getElementById("app").style.transform = "scale(" + (zoomValue) + "," + (zoomValue) +
			// ")"; //mainContainer为主容器id
			// var that = this
			window.onresize = function() { //窗口尺寸变化时，重新计算和缩放
				browerWidth = window.innerWidth;
				// console.log('browerWidth', browerWidth)
				if (browerWidth > 1440) {
					document.getElementById("home").style.justifyContent = "center";
					document.getElementById("home-frame").style.alignItems = "center";
				} else {
					// console.log('我进来了')
					document.getElementById("home").style.justifyContent = "flex-start";
					document.getElementById("home-frame").style.alignItems = "flex-start";
				}
				// zoomValue = browerWidth / baseWidth;
				// that.zoomValue = zoomValue
				// window.localStorage.setItem('browerWidth', browerWidth)
				// console.log('实时监听窗口变化',this.zoomValue)
				// document.getElementById("app").style.transform = "scale(" + zoomValue + "," + zoomValue + ")";
			}


		},
		beforeDestroy() {
			// this.$watermark.set("",this.$refs.content1);
		},
		methods: {

			// 查询离线消息
			async offlineMsgList(){
				var that = this
				let msg = await this.msgloadMaxMsgId()
				console.log('res', msg.MsgId)
				var data = {
					"queryId": parseInt(msg.MsgId) 
				}
				var res = await this.$request({
					url: '/chat/offlineMsgList',
					method: "POST",
					data: data
				})
				if (res.code == 200) {
					var array = res.data
					array.forEach( async function(currentValue) {
						// Code to execute for each element
						var isok = await that.msgAdd(currentValue)
					});
				}
			},

			// 判断用户设备是否在线
			async deviceOnline() {

				var data = {}
				if(window.electron){
					data.device = 2
				}else{
					data.device = 1
				}
				
				var res = await this.$request({
					url: '/chat/deviceOnline',
					method: "POST",
					data: data
				})
				// console.log('设备是否在线',res)
				if (res.code == 200) {
					if(!res.data){
						this.moudelweb()
					}
				}
			},

			async getuser() {
				var data = {}
				var res = await this.$request({
					url: '/Users/getUser',
					method: "POST",
					data: data
				})
				if (res.code == 200) {
					this.udata = res.data
					// console.log('udata',this.udata)
					await this.$localdic.set('udata', JSON.stringify(res.data))
				}
			},
			// 切换导航栏
			chooseTabIndex(index) {
				// if(index==4){
				//   this.$message({
				//     message: '开发中，敬请等待',
				//     type: 'warning'
				//   });
				//   return
				// }
				// console.log('获取参数成功',this.messageCount)
				
				
				this.tabIndex = index
			},
			// 打开加号操作栏
			openAddShow() {
				this.AddShow = !this.AddShow
				this.$overlay.show();
			},
			// 消息通知回调
			noticeSubmit(args, count) {
				console.log('通知回调', args)
				this.messageDetail = args
				this.tab[0].count = this.tab[0].count - count
				// setTimeout(() => {
				// 	this.moudelweb()
				// }, 100);

			},
			// 加号操作栏点击
			chooseOptTab(index) {
				//index 0-创建群组 1-添加企业成员 2-云文档 3-加入会议 4-发起会议（旧）
				//index 0-创建群组 1-云文档 2-加入会议 3-发起会议 4-退出登录（新）
				if (index == 0) {
					this.dialogVisibleMember = !this.dialogVisibleMember
					this.getUsersByDepId(0)
				} else if (index == 1) {
					this.tabIndex = 2
				} else if (index == 2) {
					this.tabIndex = 4
				} else if (index == 3) {
					this.tabIndex = 4
				} else if (index == 4) {
					// this.tabIndex = 4
					console.log('退出登录')
					this.$localdic.set('userId', '')
					this.$router.go(0)
				}
				this.AddShow = !this.AddShow
			},
			// 弹窗关闭回调
			updateDialogClick() {
				// console.log('我被调用了')
				this.dialogVisibleMember = false
				this.$overlay.hide();
			},
			// 打开用户信息编辑
			open() {
				console.log('点击了')
				this.dialogbookEditPop = true
			},
			//用户信息回调
			updateBookEditPopClick() {
				this.getuser()
				this.dialogbookEditPop = false
			},
			// 工作台回调
			workNoticeSubmit(index) {
				this.workListIndex = index
			},
			// 通讯录回调
			bookIndexSubmit(index, title, depid) {
				console.log('通讯录回调', index, title, depid)
				this.bookListTitle = title
				this.bookListDepId = depid
				if (index > 4) {
					this.bookListIndex = 4
					return
				}
				this.bookListIndex = index
			},
			titleIndex(index, data) {
				this.logTab = index
				this.logcheck = data
			},
			logTabSubmit(index) {
				// this.refreshKey += 1
				this.logTypeData = index
				console.log('点击了')
				if (index <= 2) {
					this.logListTab = 0
				} else if(index == 3) {
					this.logListTab = 1
				} else if(index == 4) {
					this.logListTab = 2
				}
			},
			conferenceTabSubmit() {
				console.log('conferenceTabSubmit')
				this.conferenceLoad = !this.conferenceLoad
				console.log('conferenceLoad', this.conferenceLoad)
			},
			// 通讯录兄弟通讯
			bookBroClick(params) {
				console.log('兄弟间传参', params)
				if (params.type == 0) {
					this.$refs.childBookIndex.openEdit()
				} else if (params.type == 1) {
					this.tabIndex = 0
					setTimeout(() => {
						this.$refs.notice.submit(0, params.RoomId)
					}, 100);

				} else if (params.type == 2) {
					this.$refs.childBookIndex.chooseIndex(params.index, 'listIndex', 'tabIndex')
				}
			},
			// 关闭会议弹窗
			closeSubimt() {
				this.visibleStartConference = false
				this.dialogVisible = false
				console.log('我进来了')
				this.conferenceTabSubmit()
			},
			// 打开会议弹窗
			oepnConferenceRoom(id){
				this.conferenceRoomId = id
				this.visibleStartConference = true
				this.dialogVisible = true
			},
			// 关闭会议弹窗
			closeZDialog(){
				// this.$refs.startConference.dialogVisibleDelete = true
				this.visibleStartConference = false
				this.dialogVisible = false
			},
			// 会议是否全屏
			isfullscreenEmit(value){
				// console.log('是否全屏',value)
				this.$refs.startConference.isfullscreen = value
			}
		}
	}
</script>

<style lang="less">
	.home {
		// width: 50%;
		// height: 50%;
		// width: 1920px;
		// height: 968px;
		width: 98vw;
		height: 98vh;
		padding: 0;
		margin: 0;
		// margin-top: 150px;
		font-size: 16px;
		background-position: left top;
		/* color: #fff; */
		// font-family: "Source Sans Pro";
		position: relative;
		// justify-content: center;
		background: #F3F3F4;
		display: flex;
	}

	.home-frame {
		position: absolute;
		display: flex;
		// align-items: center;
		// justify-content: center;
		// width: 1440px;
		// height: 900px;
		background: #F3F3F4;
		top: 0;
		// right: 5%;
		bottom: 0;
		margin: auto;
	}

	.frame-left {
		display: flex;
		flex-direction: column;
		// align-items: center;
		// width: 198px;
		// padding-top: 15px;
		padding: 15px 12px 21px 16px;
		// padding-left: 16px;
		background: #F3F3F4;
		height: 751px;

		.left-item {
			display: flex;
			width: 170px;
			align-items: center;

			.prefix-icon3 {
				margin-left: 5px;
				width: 15px;
				height: 15px;
				background-image: url('../assets/img/navigation/search.png');
				background-size: cover;
				/* 确保图像覆盖整个背景区域 */
				background-position: center;
				/* 图像居中对齐 */
				background-repeat: no-repeat;
				/* 防止图像重复 */
			}

			.el-input__inner {
				height: 36px;
				background: #D4DFEA;
				font-size: 15px;
				border-radius: 8px;
				border: 1px solid #C4C6CD;
				color: #272B34;
			}

			.item-text {
				margin-left: 12px;
				color: #858A99;
				font-size: 16px;
				font-weight: 400;
				flex: 1;
			}

			.item-bubble {
				width: 15px;
				height: 15px;
				background: #F65160;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 9px;
				color: #FFFFFF;
				font-weight: 400;
			}
		}

		.left-item-active {
			background: #FFFFFF;
			border-radius: 8px;

			.item-text {
				margin-left: 12px;
				color: #0052D9;
				font-size: 16px;
				font-weight: 400;
				flex: 1;
			}
		}

		.imgIcon {
			width: 19px;
			height: 19px;
		}

		.left-add {
			width: 170px;
			height: 225px;
			position: absolute;
			margin-left: -10px;
			margin-top: 5px;
			z-index: 101;
			/* Ensure it sits on top of other content */
			background: #FFFFFF;
			box-shadow: 0px 3px 15px 1px rgba(0, 0, 0, 0.16);
			border-radius: 16px 16px 16px 16px;
			cursor: pointer;

			.left-add-text {
				margin-left: 9px;
				font-weight: 400;
				font-size: 14px;
				color: #333333;
			}
		}
	}
</style>