<template>
	<div class="hello">
		<div class="frame"  :style="{ '--box-color': boxColor }">
			<!-- 标题 -->
			<div class="flexal" style="padding-top: 15px;margin-left: 14px;margin-bottom: 10px;">
				<div class="people-title">参会人</div>
				<img :src="require('@/assets/img/conference/575d63bbff7b7e64db25062a2d18892.png')"  style="width: 14px;height: 14px;margin-right: 26px;"  @click="closePop" />
			</div>
			<!-- <div class="flexal">
				<el-input v-model="input" class="memberInput1" placeholder="搜索" @change="seachInput()">
					<div slot="prefix" style="height: 100%;display: flex;align-items: center;justify-content: center;">
						<div class="prefix-icon3"></div>
					</div>
				</el-input>
				<div class="frame-invite flexcen" @click="inviteFun()">邀请</div>
			</div> -->

			<div class="frame-content" :style="{'height': (myuser&&myuser.IsLeader>0) ? boxColor1 :boxColor2}">
				<div class="frame-item flexal" v-for="(item,index) in mlist" :key="index">
					<img :src="item.avatar" style="width: 33px;height: 33px;border-radius: 50%;" />
					<div class="flexal" style="flex: 1;">
						<div class="frame-text" style="margin-left: 10px;">{{item.UserName}}</div>
						<div class="frame-text-block flexcen" v-if="item.IsLeader>0 ">主持人</div>
					</div>
					<img :src="item.IsMicrophone>0?require('@/assets/img/conference/audioClose.png'):require('@/assets/img/conference/audio.png')"
						@click="changeFun(index)" style="width: 15px;height: 16px;" />
				</div>
			</div>

			<div class="frame-buttom flexcen" v-if="myuser&&myuser.IsLeader>0">
				<div class="fram-button flexcen" @click="jingyanFun()">全体静音</div>
				<div class="fram-button flexcen" style="width: 122px;margin-left: 15px;" @click="jiechujingyanFun()">
					解除全体静音</div>
			</div>
		</div>

		<memberPop :dialogVisibleShow="dialogVisibleMember" @updateDialog="updateDialogClick" :list="ulist" :type="4"
			:roomid="roomid"></memberPop>
	</div>
</template>

<script>
	import memberPop from '@/components/pop-up/memberPop.vue'
	export default {
		name: 'attendConferencePop',
		props: {
			joinlists: {
				type: Array,
				default: []
			},
			//群组ID
			roomid: {
				type: Number,
				default: 0
			},
			isfullscreen:{
				type:Boolean,
				default:false
			}
		},
		watch: {
			joinlists: {
				handler(value) {
					this.mlist = value
					this.$localdic.get('userId').then(userid => {
						var uindex = value.findIndex(a => a.UserId == userid)
						if (uindex >= 0) {
							this.myuser = value[uindex]
						}
					})

				},
				deep: true, // 可以深度检测到 obj 对象的属性值的变化
				immediate: true //刷新加载  立马触发一次handler
			},
			isfullscreen(newValue){
				// console.log('我是全屏',newValue)
				if(newValue){
					this.boxColor = '95vh'
					this.boxColor1 = '78vh'
					this.boxColor2 = '83vh'
				}else{
					this.boxColor = '82vh'
					this.boxColor1 = '65vh'
					this.boxColor2 = '70vh'
				}
			}
		},
		data() {
			return {
				input: '',
				// 组织成员
				mlist: [],
				ulist: [], //未参会人员
				myuser: {}, //当前用户
				boxColor: '82vh',
				boxColor1:'65vh',
				boxColor2:'70vh',
			}
		},
		components: {
			memberPop
		},
		methods: {
			seachInput() {
				var name = this.input
				this.mlist = this.joinlists.filter(a => a.UserName.indexOf(name) >= 0)


			},
			async inviteFun() {
				var udata = []
				var data = {
					page: 1,
					limit: 9999,
				}
				var res = await this.$request({
					url: '/Users/usersList',
					method: "POST",
					data: data
				})
				if (res.code == 200) {
					udata = res.data.items
				}

				//排除已参与的

				this.ulist = udata.filter(a => this.mlist.findIndex(it => it.UserId == a.ID) < 0)

				console.log('this.ulist', this.ulist)


				this.dialogVisibleMember = true
			},
			// 添加弹窗回调
			updateDialogClick(udata) {
				if (udata && udata.length > 0) {
					//添加成功
					this.$emit('inviteUpdate', 1)
				}
				this.dialogVisibleMember = false
			},
			//麦克风权限
			async changeFun(index) {
				if (this.myuser.IsLeader > 0) {
					var stt = (this.mlist[index].IsMicrophone > 0 ? 0 : 1)
					var data = {
						Id: this.mlist[index].Id,
						editType: 8,
						IsMicrophone: stt
					}
					var rs = await this.$request.post('/chat/editRoomRole', data)
					if (rs.code == 200) {
						this.mlist[index].IsMicrophone = stt
						this.$emit('inviteUpdate', 1)
					}
				}
			},
			//退出群组
			async tuichuFun(index) {
				if (this.myuser.IsLeader > 0) {
					this.$confirm(`确定退出群组?`, {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(async () => {
						var data = {
							RoomId: this.mlist[index].RoomId,
							userlist: [{
								UserId: this.mlist[index].UserId
							}]
						}
						var res = await this.$request.post('/chat/delUsers', data)
						if (res.code == 200) {
							this.$message.success('操作成功')
							this.mlist.splice(index, 1)
							this.$emit('inviteUpdate', 1)
						}

					})
				}
			},

			//禁言
			async jingyanFun() {
				if (this.myuser.IsLeader > 0) {
					this.$confirm(`确定全体禁言?`, {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(async () => {
						var data = {
							RoomId: this.myuser.RoomId
						}
						var res = await this.$request.post('/meeting/forbiddenSpeech', data)
						if (res.code == 200) {
							this.$message.success('成功')
							this.$emit('inviteUpdate', 1)
						}

					})
				}
			},
			//解除禁言
			async jiechujingyanFun() {
				if (this.myuser.IsLeader > 0) {
					this.$confirm(`确定解除全体禁言?`, {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(async () => {
						var data = {
							RoomId: this.myuser.RoomId
						}
						var res = await this.$request.post('/meeting/liftTheBanOnSpeech', data)
						if (res.code == 200) {
							this.$message.success('成功')
							this.$emit('inviteUpdate', 1)
						}

					})
				}
			},
			// 关闭页面
			closePop(){
				this.$emit('closePop')
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	.frame {
		// margin-left: -5px;
		width: 436px;
		// height: 610px;
		height: var(--box-color);
		background: #FFFFFF;
		border-radius: 0px 16px 16px 0px;
		box-shadow: 0px 3px 26px 1px rgba(0, 0, 0, 0.16);

		.people-title {
			// margin-left: 12px;
			font-size: 18px;
			font-weight: 500;
			color: #333333;
			flex: 1;
		}

		.memberInput1 {
			margin-left: 16px;
			width: 312px;
			height: 35px;
		}

		.prefix-icon3 {
			margin-left: 5px;
			width: 15px;
			height: 15px;
			background-image: url('../../assets/img/navigation/search.png');
			background-size: cover;
			/* 确保图像覆盖整个背景区域 */
			background-position: center;
			/* 图像居中对齐 */
			background-repeat: no-repeat;
			/* 防止图像重复 */
		}

		.frame-invite {
			margin-left: 10px;
			width: 82px;
			height: 35px;
			background: #0052D9;
			border-radius: 8px 8px 8px 8px;
			font-size: 16px;
			color: #FFFFFF;
		}

		.frame-content {
			margin-top: 4px;
			padding: 0px 16px;
			// height: 475px;
			background: #000;
			overflow: auto;
		}

		.frame-item {
			margin-top: 14px;
		}

		.frame-text {
			font-weight: 500;
			font-size: 13px;
			color: #333333;
		}

		.frame-text-block {
			margin-left: 11px;
			width: 53px;
			height: 19px;
			background: #EEF3FF;
			border-radius: 4px 4px 4px 4px;
			font-weight: 400;
			font-size: 11px;
			color: #2953E7;
		}

		.frame-buttom {
			height: 80px;
		}

		.fram-button {
			width: 122px;
			height: 41px;
			background: #FFFFFF;
			border-radius: 16px 16px 16px 16px;
			border: 1px solid #858A99;
			font-size: 14px;
			color: #535D6D;
		}
	}

	.imgIcon {
		width: 19px;
		height: 19px;
	}



	/deep/.memberInput1 .el-input__inner {
		width: 312px;
		height: 35px;
		background: #FFFFFF;
		border-radius: 8px;
		//   border: 1px solid #B0B1B4;
	}
</style>