<template>
	<div class="hello">
		<div class="frame" :style="{ '--box-color': boxColor }">
			<!-- 标题 -->
			<div class="flexal" style="padding-top: 15px;margin-left: 14px;margin-bottom: 10px;">
				<div class="people-title">文档</div>
				<img :src="require('@/assets/img/conference/575d63bbff7b7e64db25062a2d18892.png')"  style="width: 14px;height: 14px;margin-right: 26px;"  @click="closePop" />
			</div>

			<div class="flexal">
				<div @click="clickUpload(0)" class="frame-block flexcumal" style="justify-content: center;">
					<img :src="require('@/assets/img/messageRight/word.png')"
						style="width: 27px;height: 28px;margin-bottom: 2px;" />
					<div>新建文档</div>
				</div>
				<div @click="clickUpload(1)" class="frame-block flexcumal" style="justify-content: center;">
					<img :src="require('@/assets/img/messageRight/ppt.png')"
						style="width: 27px;height: 28px;margin-bottom: 2px;" />
					<div>新建PPT</div>
				</div>
				<div @click="clickUpload(2)" class="frame-block flexcumal" style="justify-content: center;">
					<img :src="require('@/assets/img/messageRight/xlx.png')"
						style="width: 27px;height: 28px;margin-bottom: 2px;" />
					<div>新建表格</div>
				</div>
			</div>
			<div class="frame-line"></div>
			<div class="frame-content" :style="{ '--box-color1': boxColor1 }">
				<div class="frame-item flexal ellipsis" v-for="(item,index) in doclist" :key="index" @click="downloadFiles(item)">
					<img :src="fileIcon[item.FileSuffix]" style="width: 35px;height: 35px;margin-left: 19px;" />
					<div style="margin-left: 15px;">
						<div style="font-weight: 400;font-size: 15px;color: #000000;">{{item.FileName}}</div>
						<div style="font-weight: 400;font-size: 11px;color: #666666;">创建人：{{item.username}}
							{{item.CreateTime}} </div>
					</div>
				</div>

			</div>

			<el-upload class="upload-resource" ref="uploadInstance" action="#" accept="/*"
			:show-file-list="false" :http-request="sendUpload">
		</el-upload>
		</div>
		
	</div>
</template>

<script>
	export default {
		name: 'fileConferencePop',
		props: {
			roomId: {
				type: Number,
				default: 0
			},
			isfullscreen:{
				type:Boolean,
				default:false
			}
		},
		watch: {
			isfullscreen(newValue){
				// console.log('我是全屏',newValue)
				if(newValue){
					this.boxColor = '95vh'
					this.boxColor1 = '73vh'
				}else{
					this.boxColor = '82vh'
					this.boxColor1 = '60vh'
				}
			}
		},
		data() {
			return {
				input: '',
				doclist: [],
				commandType: 0,
				boxColor:'82vh',
				boxColor1:'60vh'
			}
		},
		computed: {
			// 文件消息图标
			fileIcon() {
				let fileIcon = []
				fileIcon['.ppt'] = require('@/assets/img/messageRight/ppt.png')
				fileIcon['.pdf'] = require('@/assets/img/messageRight/pdf.png')
				fileIcon['.doc'] = require('@/assets/img/messageRight/word.png')
				fileIcon['.docx'] = require('@/assets/img/messageRight/word.png')
				fileIcon['.xls'] = require('@/assets/img/messageRight/xlx.png')
				fileIcon['.xlsx'] = require('@/assets/img/messageRight/xlx.png')
				fileIcon['.zip'] = require('@/assets/img/messageRight/package.png')
				fileIcon['.rar'] = require('@/assets/img/messageRight/package.png')
				fileIcon['.txt'] = require('@/assets/img/messageRight/package.png')
				fileIcon['.package'] = require('@/assets/img/messageRight/package.png')
				// console.log('fileIcon',fileIcon['ppt'])
				return fileIcon
			}
		},
		components: {},
		mounted() {
			this.loaddoclist()
		},
		methods: {
			submit(index) {
				this.tabIndex = index
				this.$emit('workNoticeSubmit', index)
			},
			// 添加弹窗回调
			updateDialogClick() {
				this.dialogVisibleMember = false
			}, 
			//加载文档记录
			async loaddoclist() {
				this.doclist = []
				var data = {
					type: 1, //0-云文档 1-会议文档 2-会议录制
					page: 1,
					limit: 999,
					parentId: this.roomId
				}
				var rs = await this.$request.post('/document/docList',data)
				if (rs.code == 200) {
					this.doclist = rs.data.items
				}

			},
			clickUpload(type) {
				this.commandType = type
				document.querySelector(".upload-resource .el-upload").click()

			},

			async sendUpload(item) {

				var ftype = this.getFileTypeByMime(item.file.type)
				console.log('文件类型', ftype)
				if (this.commandType < 2 && ftype != 'document') {
					this.$message(`请上传${(this.commandType==0?'word':'pdf')}类型文件`)
					return false

				} else if (this.commandType == 2 && ftype != 'spreadsheet') {
					this.$message(`请上传xls类型文件`)
					return false
				} else if (this.commandType == 3 && ftype != 'spreadsheet' && ftype != 'document') {
					this.$message(`请上传word/xls/pdf类型文件`)
					return false
				}

				const formData = new FormData()
				formData.append('roomId', this.roomId)
				var fileno = new Date().getTime();
				if (fileno && fileno != '') formData.append('uploadfileNo', fileno)
				var groupno = 'api_meetingdoument_groupno:111111'
				if (groupno && groupno != '') formData.append('groupNo', groupno)
				formData.append('uploadfile', item.file)
				var res = await this.$request.post('/upload/UploadFile', formData)
				console.log(res)
				if (res.code == 200) {
					this.loaddoclist()
				}
				//成功后清除文件列表
				this.$refs.uploadInstance.clearFiles()
			},
			// 关闭页面
			closePop(){
				this.$emit('closePop')
			},
			// 下载并打开文件
			downloadFiles(item){
				console.log('打开文件',item)
				this.download(item.FileUrl, item.FileName)
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	.frame {
		// margin-left: -5px;
		width: 436px;
		// height: 610px;
		height: var(--box-color);
		background: #FFFFFF;
		border-radius: 0px 16px 16px 0px;
		box-shadow: 0px 3px 26px 1px rgba(0, 0, 0, 0.16);

		.people-title {
			// margin-left: 12px;
			flex: 1;
			font-size: 18px;
			font-weight: 500;
			color: #333333;
		}

		.frame-block {
			margin-left: 36px;
			width: 91px;
			height: 74px;
			background: #FFFFFF;
			border-radius: 16px 16px 16px 16px;
			border: 2px solid #858A99;
			font-weight: 400;
			font-size: 15px;
			color: #15181D;
		}

		.frame-line {
			margin-top: 30px;
			width: 436px;
			height: 0px;
			border: 1px solid #707070;
			opacity: 0.43;
		}

		.frame-content {
			// height: 440px;
			height: var(--box-color1);
			padding: 0px 16px;
			// background: #000;
			overflow: auto;
		}

		.frame-item {
			margin-top: 20px;
			width: 405px;
			height: 72px;
			background: #F9F9F9;
			border-radius: 16px 16px 16px 16px;
		}
	}

	.imgIcon {
		width: 19px;
		height: 19px;
	}
</style>