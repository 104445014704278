<template>
	<div>
		<el-dialog :title="title" :visible.sync="dialogVisible" width="40%" top="35vh" style="border-radius: 16px 16px 16px 16px;" 
			class="myDialogClass"  append-to-body  :close-on-click-modal="false" @close="qrFun(false)">
			<template #title>
				<span class="myDialogFooter flexcen">
					<img :src="require('@/assets/img/messageRight/warn.png')" style="width: 31px;height: 31px;" />
					<div style="font-weight: 400;font-size: 16px;color: #333333;margin-left: 10px;">确认退出会议？</div>
				</span>
			</template>
			<!--弹窗底部-->
			<template #footer>
				<span class="myDialogFooter">
					<el-button type="primary" @click="qrFun(false)">取消</el-button>
					<el-button type="primary" @click="qrFun(true)"
						style="background: #D54941;color: #FFFFFF;border: 0px solid #858A99;">确定</el-button>
				</span>
			</template>
		</el-dialog>
	</div>

</template>

<script>
	export default {
		name: 'deletePop',
		props: {
			// 是否显示弹窗
			dialogVisibleShow: {
				type: Boolean,
				default: false
			},
			//  类型，0:清空聊天记录  1:移除文档
			type: {
				type: Number,
				default: 0
			},
			// 弹窗标题
			title: {
				type: String,
				default: '删除'
			},
		},
		data() {
			return {
				dialogVisible: false,
				isok: false, //确认或取消
			};
		},

		watch: {
			// New: 新值 | Old: 老值
			dialogVisibleShow(New, Old) {
				this.dialogVisible = New
				//  console.log(`新值:${New} ——— 老值:${Old}`)
			},

		},
		mounted() {
			//  console.log('组件初始化',this.dialogVisible)
		},
		methods: {
			qrFun(isok) {
				this.isok = isok
				this.dialogVisible = false
				this.$emit('updateDialog', this.isok)
			},

			closeDialog() {
				console.log('我被取消了', this.dialogVisibleShow)
				this.$emit('updateDialog', this.isok)
			},
		},
	};
</script>

<style scoped lang="less">
// .el-dialog {
// 		margin-top: 35vh !important;
// 		}

	// .myDialogClass{
		
	// }

	.el-dialog .el-dialog__footer {
		padding: 10px;
	}

	/deep/.el-dialog__body {
		padding: 0 1vw;
	}

	/deep/.el-checkbox__inner {
		width: 21px;
		height: 21px;
		border: 2px solid #9B9B9B;
		border-radius: 50%;
	}

	/* 对勾样式 */
	/deep/.el-checkbox__inner::after {
		border: 2px solid #fff;
		border-left: 0;
		border-top: 0;
		left: 6px;
		top: 3px;
	}

	/deep/.el-textarea__inner {
		background: #F1F1F1;
		min-height: 214px;
	}

	/deep/.el-dialog__footer {
		text-align: center;
	}

	.myDialogFooter .el-button {
		width: 113px;
		height: 45px;
		background: #FFFFFF;
		border-radius: 16px 16px 16px 16px;
		border: 2px solid #858A99;
		font-size: 14px;
		color: #535D6D;
	}
</style>