<template>
	<div style="width: 100%;float: right;display: flex;justify-content: flex-end;">
		<!-- 操作菜单栏 -->
		<div class="right-frame" v-if="editShow == false" v-infinite-scroll="scrollLoad">
			<!-- 群名称 -->
			<div v-if="messageDetail.type == 1">
				<div class="right-frame-title flexal" @click="editshowFun()">
					<img :src="messageDetail.avatar" style="width: 68px;height: 68px;border-radius: 50%;" />
					<div class="ellipsis title-text" style="width: 220px;font-size: 18px;margin-left: 17px;flex:1;">
						{{messageDetail.RoomName}}
					</div>
					<div>
						<el-tooltip effect="dark" content="群二维码" placement="top">
							<img :src="qrCode" style="width: 25px;height: 25px;" @click.stop="openQrcodeClick" />
						</el-tooltip>
						<div v-if="openQrcode" class="flexcumal">
						<div class="title-qrCode-frame flexcumal" id="poster-container">
							<div style="font-weight: 500;font-size: 14px;color: #FFFFFF;margin-top: 32px;">
								{{messageDetail.RoomName}}
							</div>
							<img :src="qrCode" style="width: 174px;height: 174px;margin-top: 46px;" />
							<div style="font-weight: 400;font-size: 12px;color: #000000;margin-top: 22px;">仅限企业内部成员加入
							</div>
							<div style="font-weight: 400;font-size: 11px;color: #8D8D8D;margin-top: 11px;">
								该二维码7天内（7月30日前）有效</div>
						</div>
							<div class="flexal" style="position: absolute;z-index: 102;margin-top: 355px;left: 110px;">
								<div class="saveQrCode flexcen" @click.stop="convert2canvas">保存图片</div>
								<div class="shareQrCode flexcen" @click.stop="generatePoster">分享</div>
							</div>
						</div>
					</div>
					<img :src="require('@/assets/img/right.png')" style="width: 10px;height: 16px;margin-left: 14px;" />
				</div>
				<div class="right-line-bottom"></div>
			</div>
			<!-- 群成员 -->
			<div v-if="messageDetail.type == 1">
				<div class="right-frame-title flexal" style="padding-top: 22px;padding-bottom: 0px;" @click="allMemberShow = !allMemberShow">
					<div class="ellipsis title-text" style="width: 220px;font-size: 16px;flex:1;">群成员</div>
					<div class="right-tips-text">{{glist.length}}</div>
					<img :src="require('@/assets/img/right.png')" style="width: 10px;height: 16px;margin-left: 14px;" />
				</div>
				<!-- 群成员头像 -->
				<div class="flexal" style="flex-wrap: wrap;">
					<img :src="item.Avatar" @click="infoClick(item.ID)"
						style="width: 50px;height: 50px;border-radius: 50%;margin-left: 16px;margin-top: 17px;"
						v-for="(item,index) in glist" :key="index" />
					<img :src="require('@/assets/img/messageRight/peopleAdd.png')"
						style="width: 50px;height: 50px;border-radius: 50%;margin-left: 16px;margin-top: 17px;"
						@click="openGroupUsersList(0)" v-if="messageDetail.roomDep == 0">
					<img :src="require('@/assets/img/messageRight/peopleLess.png')"
						style="width: 50px;height: 50px;border-radius: 50%;margin-left: 16px;margin-top: 17px;"
						v-if="messageDetail.IsLeader>0 && messageDetail.roomDep == 0" @click="openGroupUsersList(2)" />
				</div>
				<div class="right-line-bottom" style="margin-top: 15px;"></div>
			</div>
			<!-- 群应用 -->
			<div v-if="messageDetail.type == 1&&messageDetail.IsLeader>0">
				<div class="right-frame-title flexal" style="padding-bottom: 0px;">
					<div class="ellipsis title-text" style="width: 220px;font-size: 16px;flex:1;">群应用</div>
				</div>
				<div class="right-frame-title flexal" style="flex-wrap: wrap;">
					<div style="width: 25%;flex-direction: column;" class="flexcen"
						@click="dialogVisibleNotice = !dialogVisibleNotice">
						<img :src="require('@/assets/img/messageRight/groupNotice.png')"
							style="width: 25px;height: 25px;" />
						<div style="margin-top: 5px;" class="right-tips-text">群公告</div>
					</div>
				</div>
				<div class="right-line-bottom"></div>
			</div>
			<!-- 搜索会话内容 -->
			<div
				v-if="messageDetail.type == 0||messageDetail.type == 1||messageDetail.type == 4||messageDetail.type == 5">
				<div class="right-frame-title flexal" style="padding-bottom: 0px;">
					<div class="ellipsis title-text" style="width: 220px;font-size: 16px;flex:1;">搜索会话内容</div>
				</div>
				<div class="flexal" style="flex-wrap: wrap;padding-top: 22px;padding-bottom: 22px;">
					<div style="width: 20%;flex-direction: column;" class="flexcen" v-for="(item,index) in searchList"
						:key="index" @click="openSearchMessage(index)">
						<img :src="item.icon" style="width: 27px;height: 27px;" />
						<div style="margin-top: 5px;" class="right-tips-text">{{item.text}}</div>
					</div>
				</div>
				<div class="right-line-bottom"></div>
			</div>
			<!-- 群昵称 -->
			<div v-if="messageDetail.type == 1">
				<div class="right-frame-title flexal">
					<div class="ellipsis title-text" style="width: 150px;font-size: 16px;flex:1;">我在本群的昵称</div>
					<!-- <div class="right-tips-text">{{messageDetail.UserName}}</div> -->
					<el-input v-model="groupNickname" class="enterpriseNickname" placeholder="请输入我在本群的昵称"
						style="width:170px;text-align: right;" @blur="changeFun(5)"></el-input>
					<img :src="require('@/assets/img/right.png')" style="width: 10px;height: 16px;" />
				</div>
				<div class="right-line-bottom"></div>
			</div>
			<!-- 消息免打扰 -->
			<div class="right-frame-title flexal">
				<div class="ellipsis title-text" style="width: 220px;font-size: 16px;flex:1;">消息免打扰</div>
				<el-switch @change="changeFun(0)" v-model="noDisturbing" active-color="#0052D9"
					inactive-color="#DCDFE6"></el-switch>
			</div>
			<div class="right-line-bottom"></div>
			<!-- @所有人的消息不提示 -->
			<div v-if="messageDetail.type == 1">
				<div class="right-frame-title flexal">
					<div class="ellipsis title-text" style="width: 220px;font-size: 16px;flex:1;">@所有人的消息不提示</div>
					<el-switch @change="changeFun(2)" v-model="noHintAll" active-color="#0052D9"
						inactive-color="#DCDFE6"></el-switch>
				</div>
				<div class="right-line-bottom"></div>
			</div>
			<!-- 置顶会话 -->
			<div class="right-frame-title flexal">
				<div class="ellipsis title-text" style="width: 220px;font-size: 16px;flex:1;">置顶会话</div>
				<el-switch v-model="topMessage" @change="changeFun(1)" active-color="#0052D9"
					inactive-color="#DCDFE6"></el-switch>
			</div>
			<div class="right-line-bottom"></div>
			<!-- 进群验证 -->
			<div v-if="messageDetail.type == 1&&messageDetail.IsLeader>0">
				<div class="right-frame-title flexal">
					<div class="ellipsis title-text" style="width: 220px;font-size: 16px;flex:1;">进群验证</div>
					<el-switch v-model="enterVerify" @change="changeFun(3)" active-color="#0052D9"
						inactive-color="#DCDFE6"></el-switch>
				</div>
				<div class="right-line-bottom"></div>
			</div>
			
			<!-- 新加入成员 -->
			<div v-if="messageDetail.type == 1&&messageDetail.IsLeader>0">
				<div class="right-frame-title flexal" @click="openVerify()">
					<div class="ellipsis title-text" style="width: 220px;font-size: 16px;flex:1;">新加入的成员</div>
					<!-- <div class="right-tips-text">{{messageDetail.RoomName}}</div> -->
					<img :src="require('@/assets/img/right.png')" style="width: 10px;height: 16px;margin-left: 14px;" />
				</div>
				<div class="right-line-bottom"></div>
			</div>
			<!-- 清空聊天记录 -->
			<div class="right-frame-title flexal" @click="dialogVisibleDelete = !dialogVisibleDelete">
				<div class="ellipsis title-text" style="width: 220px;font-size: 16px;flex:1;">清空聊天记录</div>
				<img :src="require('@/assets/img/messageRight/delete.png')"
					style="width: 22px;height: 24px;margin-left: 14px;" />
			</div>

			<div class="right-frame-title flexcen" style="margin-bottom: 30px;" v-if="messageDetail.type == 1 && messageDetail.roomDep == 0">
				<el-button @click="tuichuFun()"
					style="height: 45px;width: 96px;font-size: 18px;border-radius: 8px;background: #FFFFFF;border: 2px solid #D54941;color: #D54941;">
					退出群组</el-button>
				<el-button v-if="messageDetail.IsLeader>0" @click="jiesanFun()"
					style="height: 45px;width: 96px;font-size: 18px;border-radius: 8px;background: #D54941;margin-left: 30px;">
					解散群组</el-button>
			</div>
		</div>

		<!-- 编辑群信息 -->
		<div class="right-frame" v-else>
			<!-- 编辑群信息 -->
			<div class="right-frame-title flexal">
				<img :src="require('@/assets/img/right.png')" style="width: 10px;height: 16px;transform: rotate(180deg)"
					@click="editShow = false" />
				<div class="right-tips-text" style="font-size: 16px;margin-left: 20px;flex: 1;">编辑群信息</div>
				<img :src="require('@/assets/img/cancel.png')" style="width: 18px;height: 18px;"
					@click="editShow = false" />
			</div>

			<!-- 群头像 -->
			
				<el-upload action="#" accept="image/png,image/jpg" :show-file-list="false" :http-request="imgUpload">
					<div class="right-frame-title flexal">
					<div class="ellipsis title-text" style="width: 350px;font-size: 14px;flex:1;color: #333333;text-align: left;">群头像
					</div>
					<img :src="avatar" style="width: 53px;height: 53px;border-radius: 50%;" />
					<img :src="require('@/assets/img/right.png')" style="width: 10px;height: 16px;margin-left: 14px;" />
					</div>
				</el-upload>
			
			<div class="right-line-bottom"></div>

			<div class="right-frame-title">
				<div class="ellipsis title-text" style="width: 220px;font-size: 14px;flex:1;color: #333333;">群名称</div>
				<div class="flexal" style="margin-top: 20px;">
					<el-input v-model="groupName" class="enterpriseName" placeholder="请输入群名称"
						style="width:100px;" @blur="changeFun(4)"></el-input>
					<img :src="require('@/assets/img/navigation/edit.png')" style="width: 14px;height: 14px;"
						 />
				</div>
			</div>

			<div class="right-line-bottom"></div>
			<!-- 群二维码 -->
			<!-- <div class="right-frame-title flexal">
				<div class="ellipsis title-text" style="width: 220px;font-size: 14px;flex:1;color: #333333;">群二维码</div>
				<img :src="qrCode" style="width: 40px;height: 40px;" />
				<img :src="require('@/assets/img/right.png')" style="width: 10px;height: 16px;margin-left: 14px;" />
			</div>
			<div class="right-line-bottom"></div> -->
		</div>

		<!-- 群公告编辑弹窗 -->
		<noticePop :dialogVisibleShow="dialogVisibleNotice" @updateDialog="updateDialogClick"
			:roomid="messageDetail.RoomId" :intro="messageDetail.intro"></noticePop>

		<!-- 添加群成员弹窗 -->
		<memberPop :dialogVisibleShow="dialogVisibleMember" @updateDialog="updateDialog1Click" :list="list"
			:roomid="messageDetail.RoomId" :type="grouptype"></memberPop>
		<!-- 搜索会话内容弹窗 -->
		<searchMessage v-if="searchMessageShow" :index="searchMessageIndex" @updateDialog="updateDialog2Click"
			:ulist="ulist" :roomid="messageDetail.RoomId">
		</searchMessage>
		<!-- 删除聊天记录弹窗 -->
		<detelePop :dialogVisibleShow="dialogVisibleDelete" @updateDialog="updateDialog3Click"
			:roomid="messageDetail.RoomId"></detelePop>
			
		<!-- 进群验证 -->
		<verifyFrame v-if="verifyShow" :list="vlist" @updateDialog="updateDialogVerifyClick"></verifyFrame>

		<allFrame v-if="allMemberShow" :list="glist" @updateDialog="updateDialogVerifyClick"></allFrame>

	</div>
</template>

<script>
	import memberPop from '../pop-up/memberPop.vue'
	import noticePop from '../pop-up/noticePop.vue'
	import searchMessage from '../message/searchMessage.vue'
	import verifyFrame from '../message/verifyFrame.vue'
	import detelePop from '../pop-up/detelePop.vue'
	import html2canvas from "html2canvas";
	import allFrame from '../message/allFrame.vue'

	export default {
		name: 'optMessage',
		props: {
			// 联系人列表
			ulist: {
				type: Array,
				default: []
			},
			messageDetail: {
				type: Object,
				default: {}
			}
		},
		watch: {
			messageDetail: {
				handler(newValue, oldValue) {
					//这是vue的规定写法，当你watch的值发生变化的时候，就会触发这个handler，这是vue内部帮你做的事情
					console.log('opt_messageDetail变化', newValue, oldValue,this.ulist)
					this.glist=this.ulist.filter(a => a.chatstate == 0)
					this.noDisturbing = newValue.NotDisturb > 0
					this.topMessage = newValue.IsTop > 0
					this.noHintAll = newValue.NotCall > 0
					this.enterVerify = newValue.joinState > 0
					this.groupName = newValue.RoomName
					this.groupNickname =  newValue.UserName
				},
				deep: true, // 可以深度检测到 obj 对象的属性值的变化
				immediate: true //刷新加载  立马触发一次handler
			},
			ulist: {
				handler(newValue, oldValue) {
					//这是vue的规定写法，当你watch的值发生变化的时候，就会触发这个handler，这是vue内部帮你做的事情
					console.log('opt_ulist变化', newValue, oldValue)
					this.glist = newValue.filter(a => a.chatstate == 0)
					
				},
				deep: true, // 可以深度检测到 obj 对象的属性值的变化
				immediate: true //刷新加载  立马触发一次handler
			},
		},
		data() {
			return {
				avatar: require('@/assets/img/messageRight/group.png'), //最顶部聊天界面头像

				searchList: [ //搜索会话内容
					{
						text: '消息',
						icon: require('@/assets/img/messageRight/messageRecord.png')
					},
					{
						text: '云文档',
						icon: require('@/assets/img/messageRight/cloudRecord.png')
					},
					{
						text: '文件',
						icon: require('@/assets/img/messageRight/fileRecord.png')
					},
					{
						text: '图片/视频',
						icon: require('@/assets/img/messageRight/imageRecord.png')
					},
					{
						text: '链接',
						icon: require('@/assets/img/messageRight/linkRecord.png')
					},
				],

				qrCode: require('@/assets/img/messageRight/qrCode.png'), //二维码图片
				noticeShow: true, //是否显示公告
				noDisturbing: false, //消息免打扰
				noHintAll: false, //@所有人的消息不提示 
				topMessage: false, //置顶会话
				enterVerify: false, //进群验证
				groupName: '', //群名称
				editShow: false, //是否编辑群信息
				// 验证成员
				vlist: [],
				glist:[],//群成员
				list:[],//添加成员
				searchMessageIndex: 0, //要搜索的内容下标
				grouptype: 0, //0:添加成员 2:删除人员
				user:null,
				groupNickname:'',			//我在本群的昵称
			}
		},
		components: {
			memberPop,
			noticePop,
			searchMessage,
			detelePop,
			verifyFrame, 	//进群验证
			allFrame,		//全部群成员
		},
		async created() {
			document.addEventListener("click", this.myFunction);
			// var udata = await this.$localdic.get('udata')
			// this.user = JSON.parse(udata)
			// for (const item of this.glist) {
			// 	console.log('item',item)
			// 	if(item.ID == this.user.ID){
			// 		this.groupNickname = item.UserName
			// 	}
			// }
			console.log('获取用户信息',this.user)
		},
		methods: {

			editshowFun() {
				console.log('进来了editshowFun')
				this.avatar = this.messageDetail.avatar
				this.groupName = this.messageDetail.RoomName

				this.editShow = !this.editShow
			},

			openQrcodeClick() {
				this.openQrcode = !this.openQrcode
				this.$overlay.show();
			},
			openSearchMessage(index) {
				console.log('index', index)
				this.searchMessageShow = !this.searchMessageShow
				this.searchMessageIndex = index
				this.$overlay.show();

			},
			openVerify() {
				this.verifyShow = true
				this.$overlay.show();
			},

			//群公告
			updateDialogClick(intro) {
				// console.log('我被调用了')
				this.dialogVisibleNotice = false
				if (intro && intro != '') this.messageDetail.intro = intro
			},
			//群成员操作
			async openGroupUsersList(type) {
				this.list = []
				if (type == 2) {
					this.list = this.ulist
				} else {
					//获取所有的人员
					var data = {
						page: 1,
						limit: 9999,
					}
					var res = await this.$request.post('/Users/usersList', data)
					if (res.code == 200) {
						var udata = res.data.items
						var uulist = this.ulist
						udata.map(it => {
							if (uulist.findIndex(a => a.ID == it.ID) < 0) {
								this.list.push(it)
							}
						})
					}
				}
				this.grouptype = type
				this.dialogVisibleMember = true
			},
			//群成员
			updateDialog1Click(ulist) {
				console.log('我关闭了添加窗口')
				this.dialogVisibleMember = false
				// const ulist = this.ulist
				// if (this.ulist && this.ulist.length > 0) {
				// 	if (this.grouptype > 0) {
				// 		this.ulist.map(it => {
				// 			var index = this.ulist.findIndex(a => a.ID == it.ID)
				// 			if (index >= 0) this.ulist.splice(index, 1)
				// 		})
				// 	} else {
				// 		//添加成员
				// 		this.ulist = this.ulist.concat(ulist)

				// 	}
				// }
				this.$parent.getusers(this.messageDetail.RoomId)
				// this.glist = this.ulist.filter(a => a.chatstate == 0)
			},
			//搜索会话
			updateDialog2Click() {
				// console.log('我被调用了')
				this.searchMessageShow = false
			},
			updateDialogVerifyClick() {
				this.verifyShow = false
				this.allMemberShow = false
			},
			//删除聊天记录
			updateDialog3Click() {
				// console.log('我被调用了',this.messageDetail)
				this.dialogVisibleDelete = false
				this.messageDetail.count -= 1
				this.$parent.initShowD(this.messageDetail.RoomId)
				this.$parent.refreshNotice({type:0})
			},
			scrollLoad() {
				console.log('滑底触发')
			},
			async changeFun(type = 0) {
				//type 0-免打扰 1-置顶 2-免@打扰 3-进群校验 4-群组名称  5-我在本群的昵称
				console.log('changeFun', type)
				if (type == 0) {
					var stt = (this.noDisturbing ? 1 : 0)
					var data = {
						Id: this.messageDetail.Id,
						editType: 1,
						NotDisturb: stt
					}
					var rs = await this.$request.post('/chat/editRoomRole', data)
					if (rs.code == 200) {
						this.messageDetail.NotDisturb = stt
					}

				} else if (type == 1) {
					// 置顶
					var stt = (this.topMessage ? 1 : 0)
					var data = {
						Id: this.messageDetail.Id,
						editType: 0,
						IsTop: stt
					}
					var rs = await this.$request.post('/chat/editRoomRole', data)
					if (rs.code == 200) {
						this.messageDetail.IsTop = stt
						this.$parent.refreshNotice({type:0})
					}

				} else if (type == 2) {
					var stt = (this.noHintAll ? 1 : 0)
					var data = {
						Id: this.messageDetail.Id,
						editType: 11,
						NotCall: stt
					}
					var rs = await this.$request.post('/chat/editRoomRole', data)
					if (rs.code == 200) {
						this.messageDetail.NotCall = stt
					}
				} else if (type == 3) {
					var stt = (this.enterVerify ? 1 : 0)
					var data = {
						Id: this.messageDetail.Id,
						editType: 66
					}
					var rs = await this.$request.post('/chat/editRoomRole', data)
					if (rs.code == 200) {
						this.messageDetail.joinState = stt
					}
				} else if (type == 4) {
					var stt = this.groupName 
					var data = {
						Id: this.messageDetail.Id,
						editType: 14,
						RoomName: stt
					}
					var rs = await this.$request.post('/chat/editRoomRole', data)
					if (rs.code == 200) {
						this.messageDetail.RoomName = this.groupName
					}
				}else if(type == 5){
					var stt = this.groupNickname
					var data = {
						Id: this.messageDetail.Id,
						editType: 3,
						UserName: stt
					}
					var rs = await this.$request.post('/chat/editRoomRole', data)
					if (rs.code == 200) {
						this.$parent.editMember(this.groupNickname)
						this.$parent.refreshNotice({type:0})
						
					}
				}
			},
			//退出群组
			async tuichuFun() {
				this.$confirm(`确定退出群组?`, {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					var data = {
						RoomId: this.messageDetail.RoomId
					}
					var res = await this.$request.post('/chat/exitRoom', data)
					if (res.code == 200) {
						this.$message.success('操作成功')
						this.messageDetail.Id = 0
						this.$emit('optMessageFun')
					}

				})
			},
			//解散群组
			async jiesanFun() {
				this.$confirm(`确定解散群组?`, {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					var data = {
						RoomId: this.messageDetail.RoomId
					}
					var res = await this.$request.post('/chat/delRoom', data)
					if (res.code == 200) {
						this.$message.success('操作成功')
						this.messageDetail.Id = 0
						this.$emit('optMessageFun')
					}

				})
			},
			//更换群组头像
			async imgUpload(item) {
				const formData = new FormData()
				var fileno = ''
				if (fileno && fileno != '') formData.append('uploadfileNo', fileno)
				formData.append('uploadfile', item.file)
				var res = await this.$request.post('/upload/UploadFile', formData)
				console.log(res)
				if (res.code == 200) {
					this.avatar = res.data
					this.messageDetail.avatar = this.avatar
					var data = {
						Id: this.messageDetail.Id,
						editType: 15,
						avatar: res.data
					}
					var rs = await this.$request.post('/chat/editRoomRole', data)
					if (rs.code == 200) {}

				}
			},
			// 保存图片
			generatePoster(){
				// 获取要生成海报的DOM元素
				const element = document.getElementById("poster-container");
				html2canvas(element).then((canvas) => {
					canvas.toBlob((blob) => {
						if ('clipboard' in navigator) {
						navigator.clipboard.write([
							new ClipboardItem({ 'image/png': blob })
						]).then(() => {
							// console.log('图片已成功复制到剪贴板');
							this.$message({
							message: '图片已成功复制到剪贴板',
							type: 'success'
							});
						}).catch((error) => {
							// console.error('复制图片到剪贴板失败:', error);
							this.$message.error('复制图片到剪贴板失败');
						});
						} else {
						// 在不支持 ClipboardItem 的浏览器中，使用其他方法复制到剪贴板
						const data = [new File([blob], 'poster.png', { type: 'image/png' })];
						const clipboardData = new DataTransfer();
						clipboardData.items.add(data[0]);
				
						navigator.clipboard.setData('image/png', clipboardData).then(() => {
							// console.log('图片已成功复制到剪贴板');
							this.$message({
							message: '图片已成功复制到剪贴板',
							type: 'success'
							});
						}).catch((error) => {
							// console.error('复制图片到剪贴板失败:', error);
							this.$message.error('复制图片到剪贴板失败');
						});
						}
					}, 'image/png');
				});
			},
			// 保存图片
			convert2canvas() {
				const element = document.getElementById("poster-container");
				
				html2canvas(element, {
					scale: 2,//缩放比例,默认为1
					allowTaint: false,//是否允许跨域图像污染画布
					useCORS: true,//是否尝试使用CORS从服务器加载图像
					width: '302',//画布的宽度
					height: '412',//画布的高度
					// backgroundColor: '#000000',//画布的背景色，默认为透明
				}).then((canvas) => {
					//将canvas转为base64格式
					var imgUri = canvas.toDataURL("image/png");
					// console.log('输出',imgUri)
					this.downloadFileByBase64(imgUri,'群二维码.png')
				});
			},
					// 下载Base64文件
				downloadFileByBase64(data, fileName) {
					const blob = this.base64ToBlob(data);
					const url = URL.createObjectURL(blob);
					
					this.downloadFile(url, fileName)
				},
		
				// base64转blob
				base64ToBlob(data) {
					let arr = data.split(',');
					let mime = arr[0].match(/:(.*?);/)[1];
					let bStr = window.atob(arr[1]);
					let n = bStr.length;
					let u8arr = new Uint8Array(n);
					
					while (n--) {
					u8arr[n] = bStr.charCodeAt(n);
					}
					return new Blob([u8arr], {type: mime});
				},
		
				// 下载
				downloadFile(url, fileName) {
					const a = document.createElement("a");
					a.setAttribute("href", url)
					a.setAttribute("download", fileName)
					a.setAttribute("target", "_blank")
					let clickEvent = document.createEvent("MouseEvents");
					clickEvent.initEvent("click", true, true);
					a.dispatchEvent(clickEvent);
				},

			// 头像个人跳转到聊天界面
			async infoClick(uid) {
				var data = {
					UserId: uid
				}
				console.log('点击头像',uid)
				// return
				var udata =await this.$localdic.get('udata')//localStorage.getItem('udata')
				var user = JSON.parse(udata);
				// console.log('udata',udata,uid)
				if(uid == user.ID){
					console.log('我是本人')
					// this.dialogVisibleMember = true
					let params = {
						type:0,			//0:打开编辑管理  1:跳转到消息界面  2:组织架构
					}
					console.log('我是本人')
					// this.$emit('broClick',params)
					return 
				}
				var res = await this.$request.post('/chat/friendUser', data)
				if (res.code == 200) {
					console.log('添加聊天界面',res)
					this.$parent.refreshNotice({type:1,RoomId:res.data.RoomId})
					// this.$emit('')
					// this.openInfo = res.data
					// this.setStart = this.openInfo.IsStar > 0
					// this.openInfoFrame = !this.openInfoFrame
					// this.$overlay.show();
				}
			},

			fatherDelete(id){
				this.$parent.deleteMessage(id);
				this.$parent.refreshNotice({type:0})
			}

		}

	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	.right-frame {
		margin-top: 0px;
		position: absolute;
		width: 457px;
		// height: 750px;
		height: 665px;
		background: #F8F9FA;
		z-index: 101;
		/* Ensure it sits on top of other content */
		-webkit-overflow-scrolling: touch;
		overflow-scrolling: touch;
		overflow-y: auto;
		overflow-x: hidden;

		.title-text {
			font-weight: 500;
			font-size: 16px;
			color: #333333;
		}

		.right-frame-title {
			padding: 15px 16px;
		}

		.right-line-bottom {
			margin-left: 16px;
			width: 424px;
			height: 0px;
			border: 1px solid #707070;
			opacity: 0.32;
		}

		.right-tips-text {
			font-weight: 400;
			color: #6B6B6B;
			font-size: 14px;
		}

		.el-button {
			// width: 96px;
			// height: 45px;
			padding: 0px 0px;
			border-radius: 8px;
			background-color: rgba(0, 0, 0, 0.3);
			font-size: 24px;
			color: rgba(255, 255, 255, 1);
			border: transparent;
			overflow: hidden;
			// &:hover{
			//   background: #ecf5ff;
			//   color: #fff;
			// }
			// &:focus{
			//   background: #ecf5ff;
			//   color: #fff;
			// }
		}

		.title-qrCode-frame {
			position: absolute;
			margin-left: -300px;
			z-index: 101;
			/* Ensure it sits on top of other content */
			width: 302px;
			height: 412px;
			background: #FFFFFF;
			background-image: url('../../assets/img/navigation/qrCode-frame.png');
			/* 替换为实际的图片路径 */
			background-size: cover;
			/* 确保背景图像覆盖整个容器 */
			background-position: center;
			/* 将背景图像居中对齐 */
			background-repeat: no-repeat;
			/* 避免背景图像重复 */
			// box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.17);
			border-radius: 16px 16px 16px 16px;
		}

		.saveQrCode {
			width: 132px;
			height: 39px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			border: 1px solid #0052D9;
			font-size: 12px;
			color: #0052D9;
			cursor: pointer;
		}

		.shareQrCode {
			margin-left: 10px;
			width: 132px;
			height: 39px;
			background: #0052D9;
			border-radius: 8px 8px 8px 8px;
			color: #FFFFFF;
			font-size: 12px;
			cursor: pointer;
		}

	}

	::v-deep .enterpriseName .el-input__inner {
		width: 100px;
		height: 24px;
		background-color: #F8F9FA;
		text-align: center;
		border-color: #F8F9FA;
		color: #000;
		border: 0px solid #DCDFE6;
		padding: 0px 0px;
		text-align: left;
		font-size: 14px;
	}
	::v-deep .enterpriseNickname .el-input__inner {
		width: 100px;
		height: 24px;
		background-color: #F8F9FA;
		text-align: center;
		border-color: #F8F9FA;
		color: #000;
		border: 0px solid #DCDFE6;
		padding: 0px 0px;
		text-align: right;
		font-size: 14px;
	}
</style>