<template>
	<div class="hello">
		<div class="frame">
			<!-- 标题 -->
			<div class="flexal" style="padding-top: 15px;margin-left: 14px;margin-bottom: 10px;">
				<!-- <img :src="require('@/assets/img/message/message.png')"  class="imgIcon"  /> -->
				<div class="people-title" @click="userFeedback">视频会议</div>
			</div>
			<!-- 搜索框 -->
			<!-- <div class="left-item" style="margin-top: 10px;margin-bottom: 15px;">
                <el-input v-model="input"  placeholder="搜索">
                <div slot="prefix" style="height: 100%;display: flex;align-items: center;justify-content: center;">
                    <div  class="prefix-icon3"></div>
                </div>
                </el-input>
            </div> -->

			<template v-for="(item,index) in tab ">
				<div class="frame-item flexal" :key="index" :class="index == tabIndex? 'frame-item-active': ''"
					@click="openConferencePop(index)">
					<img :src="item.icon" style="width: 37px;height: 37px;margin-left: 12px;margin-right: 6px;" />
					<div class="frame-item-text">{{item.text}}</div>
				</div>
			</template>
		</div>
		<!-- 加入会议弹窗 -->
		<joinConferencePop v-if="visibleJoinConference" @closeSubimt="closeSubimt"  @startSubimt="startSubimt"></joinConferencePop>
		<!-- 发起会议弹窗 -->
		<launchConferencePop v-if="visibleLaunchConference" @closeSubimt="closeSubimt" @startSubimt="startSubimt"></launchConferencePop>
		
		<!-- 开始会议弹窗 -->
		<startConferencePop v-if="visibleStartConference" @closeSubimt="closeSubimt" :roomId="conferenceRoomId"></startConferencePop>
	</div>
</template>

<script>
	import joinConferencePop from '../pop-up/joinConferencePop.vue';
	import launchConferencePop from '../pop-up/launchConferencePop.vue';
	import startConferencePop from '../pop-up/startConferencePop.vue';
	export default {
		name: 'conferenceTab',
		props: {
			msg: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				input: '',
				tab: [{
						icon: require('@/assets/img/conference/ef86cef57232e6b111e5c54c218bc31.png'),
						chooseIcon: require('@/assets/img/workbench/stayIcon.png'),
						text: '发起会议'
					},
					{
						icon: require('@/assets/img/conference/cdf2e0b60290b179e7480a33c8cf8f4.png'),
						chooseIcon: require('@/assets/img/workbench/endIcon.png'),
						text: '加入会议'
					},
				],
				tabIndex: 0,
				// 组织成员
				conferenceRoomId: 0,
				visibleStartConference: false,
			}
		},
		components: {
			joinConferencePop,
			launchConferencePop,
			startConferencePop
		},
		methods: {
			openConferencePop(index) {
				if (index) {
					this.visibleJoinConference = true
					this.visibleLaunchConference = false
				} else {
					this.visibleLaunchConference = true
					// this.visibleStartConference = true
					this.visibleJoinConference = false
				}
			},
			// 开始会议
			startSubimt(id) {
				this.conferenceRoomId = id
				console.log('conferenceRoomId',id)
				this.visibleLaunchConference = false
				// this.visibleStartConference = true
				// if(window.electron){
				// 	// this.userFeedback()
				// 	window.electron.ipcRenderer.invoke('newwindow',this.conferenceRoomId).then(inputSources => {	
						
				// 	})
				// }else{
				// 	this.visibleJoinConference = false
				// }
				this.$emit('oepnConferenceRoom',this.conferenceRoomId)
				this.$emit('conferenceTabSubmit')

			},
			closeSubimt() {
				this.visibleJoinConference = false
				this.visibleLaunchConference = false
				this.visibleStartConference = false
				this.$emit('conferenceTabSubmit')
			},
			// 路由跳转新界面
			userFeedback() {
				// window.electron.ipcRenderer.invoke('newwindow',this.conferenceRoomId).then(inputSources => {	
						
				// })
				// this.$router.push({ path: '/feedback' });
				// 用push,也能实现跳转，但是不能打开新页面。
				return 
				const {
					href
				} = this.$router.resolve({
					path: '/about',
					query: { id: this.conferenceRoomId }
				});
				window.open(href, '_blank');
			},
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	.frame {
		// margin-top: 31px;
		width: 313px;
		height: 766px;
		background: #FFFFFF;
		border-radius: 16px 16px 16px 16px;

		.people-title {
			// margin-left: 12px;
			font-size: 18px;
			font-weight: 500;
			color: #333333;
		}

		.left-item {
			margin-left: 12px;
			margin-top: 5px;
			display: flex;
			width: 185px;
			align-items: center;

			.prefix-icon3 {
				margin-left: 5px;
				width: 15px;
				height: 15px;
				background-image: url('../../assets/img/navigation/search.png');
				background-size: cover;
				/* 确保图像覆盖整个背景区域 */
				background-position: center;
				/* 图像居中对齐 */
				background-repeat: no-repeat;
				/* 防止图像重复 */
			}

			.el-input__inner {
				height: 36px;
				background: #D4DFEA;
				font-size: 15px;
				border-radius: 8px;
				border: 1px solid #C4C6CD;
				color: #272B34;
			}

			.item-text {
				margin-left: 12px;
				color: #858A99;
				font-size: 16px;
				font-weight: 400;
				flex: 1;
			}

			.item-bubble {
				width: 15px;
				height: 15px;
				background: #F65160;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 9px;
				color: #FFFFFF;
				font-weight: 400;
			}
		}

		.frame-item {
			margin-top: 12px;
			margin-left: 12px;
			width: 289px;
			height: 63px;
			background: #EBEFFE;
			color: #272B34;
			border-radius: 8px 8px 8px 8px;
			cursor: pointer;
		}

		.frame-item-active {
			background: #EFF2FF;
			color: #272B34;
		}

		.frame-item-text {
			font-size: 15px;

			font-weight: 400;
		}
	}

	.imgIcon {
		width: 19px;
		height: 19px;
	}
</style>