<template>
	<div class="hello">
		<div class="frame">
			<!-- 标题 -->
			<div class="flexal" style="padding-top: 15px;margin-bottom: 31px;">
				<!-- <img :src="require('@/assets/img/message/message.png')"  class="imgIcon"  /> -->
				<div class="people-title">通讯录</div>
			</div>
			<!-- 搜索框 -->
			<!-- <div class="left-item" style="margin-top: 10px;margin-bottom: 15px;">
                <el-input v-model="input"  placeholder="搜索">
                <div slot="prefix" style="height: 100%;display: flex;align-items: center;justify-content: center;">
                    <div  class="prefix-icon3"></div>
                </div>
                </el-input>
            </div> -->
			<div class="flexal">
				<img :src="avatar" class="imgIcon-avatar" style="margin-left: 12px;" />
				<div style="margin-left: 8px;flex: 1;" class="frame-item-text">{{username}}</div>
				<div class="edit-frame flexcen" @click="openEdit">编辑管理</div>
			</div>
			<div style="width: 100%;height: 10px;"></div>
			<template v-for="(item,index) in tab">
				<div class="frame-item flexal" v-if="item.show" :class="index == tabIndex? 'frame-item-active': ''"
					:key="index" @click="chooseIndex(index,'tabIndex','listIndex')">
					<img :src="item.icon" style="margin-left: 12px;margin-right: 6px;" class="imgIcon" />
					<div class="frame-item-text">{{item.text}}</div>
				</div>
			</template>

			<div class="frame-line"></div>

			<div class="flexal" style="margin-top: 20px;margin-left: 24px;">
				<img :src="require('@/assets/img/book/organ.png')" style="width: 28px;height: 28px;" />
				<div style="font-weight: 400;font-size: 14px;margin-left: 12px;">{{companyname}}</div>
			</div>
			<div style="width: 100%;height: 450px;overflow:auto;" v-infinite-scroll="scrollLoad">
				<!-- <el-tree :data="data" :props="defaultProps" @node-click="handleNodeClick" style="margin-top: 12px;margin-left: 24px;"></el-tree> -->
				<template v-for="(item,index) in list ">
					<div class="frame-item-organ flexal" :class="listIndex == index? 'frame-item-organ-active':''"
						:key="index" @click="chooseIndex(index,'listIndex','tabIndex')">
						<img :src="require('@/assets/img/book/L.png')"
							style="width: 7px;height: 8px;margin-left: 26px;" />
						<div class="frame-item-organ-text">{{item}}</div>
					</div>
				</template>
			</div>
		</div>

		<bookEditPop :dialogVisibleShow="dialogVisibleMember" @updateDialog="updateDialogClick" :avatar="avatar" :name="username"></bookEditPop>

		

	</div>
</template>

<script>
	import bookEditPop from '@/components/pop-up/bookEditPop.vue'
	export default {
		name: 'bookIndex',
		props: {
			msg: {
				type: String,
				default: ''
			}
		},
		data() {
			return {

				userdepid: 0,
				username: "名称",
				companyname: '鼎智',
				input: '',
				list: [
					'组织架构',
					'技术部门'
				],
				listIndex: null,
				tab: [{
						icon: require('@/assets/img/book/tree.png'),
						// chooseIcon:require('@/assets/img/workbench/stayIcon.png'),
						text: '组织内联系人',
						show: false,
					},
					{
						icon: require('@/assets/img/messageRight/peopleButton.png'),
						// chooseIcon:require('@/assets/img/workbench/endIcon.png'),
						text: '我的群组',
						show: true,
					},
					{
						icon: require('@/assets/img/book/new.png'),
						// chooseIcon:require('@/assets/img/workbench/startIcon.png'),
						text: '星标联系人',
						show: true,
					},
				],
				avatar: 'https://cdn.uviewui.com/uview/album/7.jpg',
				avatars: 'https://cdn.uviewui.com/uview/album/7.jpg',
				tabIndex: 0,
				bookListIndex: 0,
			}
		},
		components: {
			bookEditPop
		},
		created() {
			this.loadUserData()
			console.log('头像',this.avatar)
			// this.avatars = this.avatar
			this.chooseIndex(1, 'tabIndex', 'listIndex')
		},
		methods: {
			submit(index) {
				this.tabIndex = index

			},
			handleNodeClick(data) {
				console.log(data);
			},
			chooseIndex(index, field, oldField) {
				this[oldField] = null
				this[field] = index
				var title = ''
				if (field == 'tabIndex') {
					this.bookListIndex = index
					title = this.tab[index].text
				} else {
					this.bookListIndex = index + 3
					title = this.list[index]
				}
				this.$emit('bookIndexSubmit', this.bookListIndex, title, this.userdepid)
			},
			scrollLoad() {
				console.log('滑底触发')
			},
			// 弹窗关闭回调
			updateDialogClick() {
				// console.log('我被调用了')
				this.dialogVisibleMember = false
				this.loadUserData()
			},
			// 打开编辑管理
			openEdit(){
				this.dialogVisibleMember = !this.dialogVisibleMember
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	.frame {
		// margin-top: 31px;
		width: 352px;
		// height: 759px;
		height: 766px;
		background: #FFFFFF;
		border-radius: 16px 0px 0px 16px;
		border-right: 1px solid #d0d0d0;

		.people-title {
			margin-left: 12px;
			font-size: 18px;
			font-weight: 500;
			color: #333333;
		}

		.left-item {
			margin-left: 12px;
			margin-top: 5px;
			display: flex;
			width: 185px;
			align-items: center;

			.prefix-icon3 {
				margin-left: 5px;
				width: 15px;
				height: 15px;
				background-image: url('../../assets/img/navigation/search.png');
				background-size: cover;
				/* 确保图像覆盖整个背景区域 */
				background-position: center;
				/* 图像居中对齐 */
				background-repeat: no-repeat;
				/* 防止图像重复 */
			}

			.el-input__inner {
				height: 36px;
				background: #D4DFEA;
				font-size: 15px;
				border-radius: 8px;
				border: 1px solid #C4C6CD;
				color: #272B34;
			}

			.item-text {
				margin-left: 12px;
				color: #858A99;
				font-size: 16px;
				font-weight: 400;
				flex: 1;
			}

			.item-bubble {
				width: 15px;
				height: 15px;
				background: #F65160;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 9px;
				color: #FFFFFF;
				font-weight: 400;
			}
		}

		.frame-item {
			margin-top: 5px;
			margin-left: 12px;
			width: 328px;
			height: 52px;
			background: #FFFFFF;
			color: #000000;
			border-radius: 8px 8px 8px 8px;
		}

		.frame-item-active {
			background: #EFF2FF;
			color: #0052D9;
		}

		.frame-item-text {
			font-size: 15px;
			font-weight: 400;
		}

		.edit-frame {
			margin-right: 16px;
			width: 65px;
			height: 21px;
			border-radius: 24px 24px 24px 24px;
			border: 1px solid #0052D9;
			font-size: 12px;
			color: #0052D9;
		}

		.frame-item-organ {
			margin-left: 11px;
			margin-top: 10px;
			width: 328px;
			height: 32px;
			background: #FFFFFF;
			color: #333333;
			border-radius: 16px 16px 16px 16px;
		}

		.frame-item-organ-active {
			color: #0052D9;
			background: #F2F3FF;
		}

		.frame-item-organ-text {
			margin-left: 15px;
			font-weight: 400;
			font-size: 14px;

		}
	}

	.imgIcon {
		width: 30px;
		height: 31px;
	}

	.imgIcon-avatar {
		width: 39px;
		height: 38px;
		border-radius: 50%;
	}

	.frame-line {
		margin-left: 24px;
		margin-top: 28px;
		width: 328px;
		height: 0px;
		border: 1px solid #d0d0d0;
		// opacity: 0.33;
	}
</style>