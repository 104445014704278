export default {
	data() {
		return {}
	},
	methods: {
		//查询未读消息数
		msggetcount(id) {
			return new Promise((resolve, reject) => {
				if (window.electron) {
					var sql = "select count(1) cnum from msglist where RoomId=@id and State=@state ";
					var parms = {
						id: id,
						state: 0
					}
					window.electron.oneDataIPC(sql, parms).then(rs => {
						// console.log(' 返回结果', rs);
						var num=0
						if('cnum' in rs) num=rs.cnum
						resolve(num)
					}).catch((error) => {
						console.log("Inside the promise", error);
						reject(0)
					})

				} else {
					var userid = localStorage.getItem('userId')
					var key = `msglist:${userid}`
					var list = this.readArrayFromLocalStorage(key)
					let filteredArray = list.filter(a => a.RoomId == id && a.State == 0);
					var len = filteredArray.length
					resolve(len)
				}

			});
		},
		//查询所有未读消息数
		msgloadcount() {
			return new Promise((resolve, reject) => {
				if (window.electron) {
					var sql = "select count(1) cnum from msglist where RoomId>0 and State=@state ";
					var parms = {
						state: 0
					}
					window.electron.oneDataIPC(sql, parms).then(rs => {
						// console.log(' 返回结果', rs);
						var num=0
						if('cnum' in rs) num=rs.cnum
						 
						resolve(num)
					}).catch((error) => {
						console.log("Inside the promise", error);
						reject(0)
					})

				} else {
					var userid = localStorage.getItem('userId')
					var key = `msglist:${userid}`
					var list = this.readArrayFromLocalStorage(key)
					let filteredArray = list.filter(a => a.State == 0);
					var len = filteredArray.length
					resolve(len)
				}
			});
		},

		//查询最新的一条数据
		msgloadMaxMsgId() {
			return new Promise((resolve, reject) => {
				if (window.electron) {
					var sql = "select max(MsgId)  MsgId from msglist";
					var parms = {
						// MsgId: ''
					}
					window.electron.oneDataIPC(sql, parms).then(rs => {
						// console.log(' 返回结果', rs);
						// var num=0
						// if('cnum' in rs) num=rs.cnum
						resolve(rs)
					}).catch((error) => {
						console.log("Inside the promise", error);
						reject(0)
					})

				} else {
					var userid = localStorage.getItem('userId')
					var key = `msglist:${userid}`
					var list = this.readArrayFromLocalStorage(key)
					// console.log('list',list)
					// let filteredArray = list.forEach(a => a.State == 0);
					var len = list[list.length-1]
					resolve(len)
				}
			});
		},

		//查询消息列表
		msggetlist(id, page, pageSize, rows = null) {
			const offset = (page - 1) * pageSize;
			return new Promise((resolve, reject) => {
				if (window.electron) {
					var parms = {
						id: id,
						pageSize: pageSize,
						offset: offset
					}
					var where = ''
					if (rows) {
						if (rows.state >= 0) {
							parms.state = rows.state
							where += " and State=@state"
						}
						if (rows.name && rows.name != '') {
							parms.name = rows.name
							where += " and MsgName like '%@name%' "
						}
						if (rows.type >= 0) {
							if (rows.type2 >= 0) {
								parms.type = rows.type
								parms.type2 = rows.type2
								where += " and ( MsgType=@type or MsgType=@type2) "
							} else {
								parms.type = rows.type
								where += " and MsgType=@type"
							}
						}
						if (rows.uid >= 0) {
							parms.uid = rows.uid
							where += " and UserId=@uid"
						}
					}
					var sql =
						`select * from msglist where RoomId=@id ${where} order by MsgId desc limit @pageSize offset @offset `;
					window.electron.selectDataIPC(sql, parms).then(rs => {
						console.log(' 返回结果数组', rs);
						resolve(rs)
					}).catch((error) => {
						console.log("Inside the promise", error);
						reject([])
					})

				} else {
					var userid = localStorage.getItem('userId')
					var key = `msglist:${userid}`
					var list = this.readArrayFromLocalStorage(key)
					let filteredArray = list.filter(a => a.RoomId == id);
					if (rows) {
						if (rows.state >= 0) {
							filteredArray = filteredArray.filter(a => a.State == rows.state);
						}
						if (rows.type >= 0) {
							if (rows.type2 >= 0) {
								filteredArray = filteredArray.filter(a => a.MsgType == rows.type || a.MsgType ==
									rows.type2);
							} else {
								filteredArray = filteredArray.filter(a => a.MsgType == rows.type);
							}
						}

						if (rows.name && rows.name != '') {
							filteredArray = filteredArray.filter(a => typeof a.MsgName == 'string' && a.MsgName
								.includes(rows.name));
						}
						if (rows.uid >= 0) {
							filteredArray = filteredArray.filter(a => a.UserId == rows.uid);
						}
					}
					filteredArray.sort((a, b) => b.MsgId - a.MsgId);
					var newlist = []
					var len = filteredArray.length
					if (len > offset) {
						var end = offset + pageSize
						if (end > len) end = len
						newlist = filteredArray.slice(offset, len)
					}
					resolve(newlist)
				}

			});
		},
		//查询一条消息
		getonebymsgid(id) {
			return new Promise((resolve, reject) => {
				if (window.electron) {
					var parms = {
						id: id,
					}
					var sql = `select * from msglist where MsgId=@id`;
					window.electron.oneDataIPC(sql, parms).then(rs => {
						// console.log(' getonebymsgid结果', rs);
						resolve(rs)
					}).catch((error) => {
						console.log("Inside the promise", error);
						reject({})
					})

				} else {
					var userid = localStorage.getItem('userId')
					var key = `msglist:${userid}`
					var list = this.readArrayFromLocalStorage(key)
					let filteredArray = list.filter(a => a.MsgId == id);
					filteredArray.sort((a, b) => b.MsgId - a.MsgId);
					var newdata = null
					var len = filteredArray.length
					if (len > 0)
						newdata = filteredArray[0]
					resolve(newdata)
				}

			});
		},

		//查询一条消息
		msgoneget(id, rows = null) {
			return new Promise((resolve, reject) => {
				if (window.electron) {
					var parms = {
						id: id,
					}
					var where = ''
					if (rows) {
						if (rows.state >= 0) {
							parms.state = rows.state
							where += " and State=@state"
						}
					}
					var sql =
						`select * from msglist where RoomId=@id ${where}  order by MsgId desc`;
					window.electron.oneDataIPC(sql, parms).then(rs => {
						// console.log(' 返回结果', rs);
						resolve(rs)
					}).catch((error) => {
						console.log("Inside the promise", error);
						reject({})
					})

				} else {
					var userid = localStorage.getItem('userId')
					var key = `msglist:${userid}`
					var list = this.readArrayFromLocalStorage(key)
					let filteredArray = list.filter(a => a.RoomId == id);
					if (rows) {
						if (rows.state >= 0) {
							filteredArray = filteredArray.filter(a => a.State == rows.state);
						}
					}
					filteredArray.sort((a, b) => b.MsgId - a.MsgId);
					var newdata = null
					var len = filteredArray.length
					if (len > 0)
						newdata = filteredArray[0]
					resolve(newdata)
				}

			});
		},
		//更新消息状态
		msgUpdState(roomid, state) {
			return new Promise((resolve, reject) => {

				if (window.electron) {
					var sql = "update  msglist set State=@state where RoomId=@id";
					var parms = {
						id: roomid,
						state: state
					}
					window.electron.modifyDataIPC(sql, parms).then(rs => {
						console.log(' 返回结果true/false', rs);
						resolve(rs)
					})

				} else {
					var userid = localStorage.getItem('userId')
					var key = `msglist:${userid}`
					var list = this.readArrayFromLocalStorage(key)

					list.map(it => {
						if (it.RoomId == roomid && it.State == 0) {
							it.State = 1
						}
					})
					this.saveArrayToLocalStorage(key, list)
					resolve(true)
				}
			});
		},

		//更新消息(name)信息
		msgUpdName(msgid, msgname) {
			return new Promise((resolve, reject) => {

				if (window.electron) {
					var sql = "update  msglist set MsgName=@msgname where MsgId=@id";
					var parms = {
						id: msgid,
						msgname: msgname
					}
					window.electron.modifyDataIPC(sql, parms).then(rs => {
						console.log(' 返回结果true/false', rs);
						resolve(rs)
					})

				} else {
					var userid = localStorage.getItem('userId')
					var key = `msglist:${userid}`
					var list = this.readArrayFromLocalStorage(key)

					var index = list.findIndex(a => a.MsgId == msgid)
					if (index >= 0) {
						list[index].MsgName = msgname
						this.saveArrayToLocalStorage(key, list)
					}
					resolve(true)
				}
			});
		},

		//更新消息(content)信息
		msgUpdContent(msgid, msgcontent) {
			return new Promise((resolve, reject) => {

				if (window.electron) {
					var sql = "update  msglist set MsgContent=@msgcontent where MsgId=@id";
					var parms = {
						id: msgid,
						msgcontent: msgcontent
					}
					window.electron.modifyDataIPC(sql, parms).then(rs => {
						console.log(' 返回结果true/false', rs);
						resolve(rs)
					})

				} else {
					var userid = localStorage.getItem('userId')
					var key = `msglist:${userid}`
					var list = this.readArrayFromLocalStorage(key)

					var index = list.findIndex(a => a.MsgId == msgid)
					if (index >= 0) {
						list[index].MsgContent = msgcontent
						this.saveArrayToLocalStorage(key, list)
					}
					resolve(true)
				}
			});
		},

		//添加消息
		msgAdd(row) {
			return new Promise((resolve, reject) => {
				if (window.electron) {
					var sql =
						"INSERT INTO msglist (MsgId, RoomId,RoomType,MsgType, MsgName,UserId, MsgContent,State, YearNum,MonthNum,IsCall,CallUserId,CreateTime) VALUES (@MsgId,@RoomId,@RoomType,@MsgType, @MsgName,@UserId, @MsgContent,@State, @YearNum,@MonthNum,@IsCall,@CallUserId,@CreateTime)";
						// console.log('你猜猜我到哪儿了',row)
						row.MsgName = JSON.stringify(row.MsgName)
						var parms = [row]
					window.electron.insertDataIPC(sql, parms).then(rs => {
						// console.log(' 返回结果true/false', rs);
						resolve(rs)
					})
				} else {
					var userid = localStorage.getItem('userId')
					var key = `msglist:${userid}`
					var list = this.readArrayFromLocalStorage(key)
					list.push(row)
					this.saveArrayToLocalStorage(key, list)
					resolve(true)
				}
			});
		},
		//删除单条消息
		msgDelByMsgId(id) {
			return new Promise((resolve, reject) => {
				console.log('删除的id',id)
				if (window.electron) {
					var sql = "delete from msglist where MsgId=@id";
					var parms = {
						id: id
					}
					window.electron.deleteDataIPC(sql, parms).then(rs => {
						console.log(' 返回结果true/false', rs);
						resolve(rs)
					})
				} else {
					var userid = localStorage.getItem('userId')
					var key = `msglist:${userid}`
					var list = this.readArrayFromLocalStorage(key)
					let filteredArray = list.filter(a => a.MsgId != id);
					this.saveArrayToLocalStorage(key, filteredArray)
					resolve(true)
				}
			});
		},
		//删除消息
		msgDelByroomId(id) {
			return new Promise((resolve, reject) => {

				if (window.electron) {
					var sql = "delete from msglist where RoomId=@id";
					var parms = {
						id: id
					}
					window.electron.deleteDataIPC(sql, parms).then(rs => {
						console.log(' 返回结果true/false', rs);
						resolve(rs)
					})
				} else {
					var userid = localStorage.getItem('userId')
					var key = `msglist:${userid}`
					var list = this.readArrayFromLocalStorage(key)
					let filteredArray = list.filter(a => a.RoomId != id);
					this.saveArrayToLocalStorage(key, filteredArray)
					resolve(true)
				}
			});
		},
		//判断表是否存在，不存在添加
		isMsgTab() {
			return new Promise((resolve, reject) => {
				if (window.electron) {
					var sql =
						"CREATE TABLE IF NOT EXISTS msglist (MsgId TEXT, RoomId TEXT, RoomType Text, UserId TEXT, MsgType TEXT,MsgName TEXT,MsgContent TEXT,State TEXT,YearNum TEXT,MonthNum TEXT,IsCall TEXT,CallUserId TEXT,CreateTime TEXT)";
					window.electron.createTableIPC(sql).then(rs => {
						console.log('返回结果true/false', rs);
						resolve(rs)
					})
				} else {
					var userid = localStorage.getItem('userId')
					var key = `msglist:${userid}`
					var msgstr = localStorage.getItem(key)
					if (msgstr == null || msgstr == '[]') {
						var list = []
						this.saveArrayToLocalStorage(key, list)
					}
					resolve(true)
				}
			});
		},
		// 保存数组到localStorage
		saveArrayToLocalStorage(key, array) {
			const arrayString = JSON.stringify(array);
			localStorage.setItem(key, arrayString);
		},
		// 从localStorage读取数组
		readArrayFromLocalStorage(key) {
			const arrayString = localStorage.getItem(key);
			if (arrayString) {
				return JSON.parse(arrayString);
			}
			return null;
		},

		//获取屏幕媒体流
		getDisplayMediaFun() {
			return new Promise((resolve, reject) => {
				if (window.electron) {
					window.electron.ipcRenderer.invoke('getSources').then(inputSources => {
						console.log('ipcRenderer视频流', inputSources);
						var soures = JSON.parse(inputSources)
						var sourceId = soures[0].id
						let constraints = {
							audio: false,
							video: {
								mandatory: {
									chromeMediaSource: 'desktop',
									chromeMediaSourceId: sourceId
								}
							}
						}
						navigator.mediaDevices.getUserMedia(constraints).then(stream => {
							resolve(stream)
						}).catch((e) => {
							console.log('electron-获取屏幕媒体流错误', e)
						})
					})
				} else {
					const mediaConstraints = {
						video: true,
						audio: false,
					};
					navigator.mediaDevices.getDisplayMedia(mediaConstraints).then(stream => {
						resolve(stream)
					}).catch((e) => {
						console.log('获取屏幕媒体流错误', e)
					})
				}
				
			});
		},




	}
}