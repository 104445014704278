<template>
	<div style="width: 100%;float: right;display: flex;justify-content: flex-end;">

		<!-- 编辑群信息 -->
		<div class="right-frame">
			<!-- 编辑群信息 -->
			<div class="right-frame-title flexal">
				<img :src="require('@/assets/img/right.png')" style="width: 10px;height: 16px;transform: rotate(180deg)"
					@click="closeDialog" />
				<div class="right-tips-text" style="font-size: 16px;margin-left: 20px;flex: 1;">
					搜索会话内容-{{tabs[tabsIndex].name}}</div>
				<img :src="require('@/assets/img/cancel.png')" style="width: 18px;height: 18px;" @click="closeDialog" />
			</div>
			<!-- 搜索框 -->
			<div class="left-item" style="margin-top: 15px;margin-left: 14px;">
				<el-input v-model="input" placeholder="搜索">
					<div slot="prefix" style="height: 100%;display: flex;align-items: center;justify-content: center;">
						<div class="prefix-icon3"></div>
					</div>
				</el-input>
			</div>
			<!-- 导航栏 -->
			<div class="flexal">
				<div class="frame-text flexcen" :class="tabsIndex == index? 'frame-text-active':''"
					v-for="(item,index) in tabs" :key="index" @click="tabsChange(index)">
					{{item.name}}
					<div class="frame-slid-block" v-if="tabsIndex == index"></div>
				</div>
			</div>
			<!-- 筛选条件 -->
			<div class="flexal">
				<el-select v-model="memberId" filterable placeholder="来自用户" clearable
					style="width: 140px;height: 50px;margin-left: 16px;" v-if="tabsIndex != 3">
					<el-option v-for="item in ulist" :key="item.id" :label="item.UserName" :value="item.ID">
					</el-option>
				</el-select>

				<el-date-picker v-if="tabsIndex == 0" v-model="dateTime" type="date" placeholder="时间" class="frame-date"
					style="width: 140px;height: 50px;margin-left: 16px;"></el-date-picker>
				<div style="flex: 1;"></div>
				<div class="frame-reset" v-if="memberId!=''&&dateTime !=null" @click="resetClick">重置</div>
			</div>
			<!-- 消息搜索结果 -->
			<div style="padding: 0px 17px;overflow:auto;height: 425px;" v-infinite-scroll="scrollLoad"
				v-if="tabsIndex == 0">
				<div class="flexal" style="margin-top: 17px;" v-for="(item,index) in isSearch? fillist:msglist" :key="index">
					<img :src="getutxt(item.UserId,ulist,'Avatar','ID')" style="width: 46px;height: 46px;border-radius: 50%;" />
					<div style="margin-left: 9px;flex: 1;">
						<div style="font-weight: 500;font-size: 14px;color: #333333;width: 273px;" class="ellipsis">
							{{getutxt(item.UserId,ulist,'UserName','ID')}}
						</div>
						<div style="font-weight: 400;font-size: 12px;color: #666666;width: 273px;margin-top: 5px;"
							class="ellipsis" v-html="item.MsgContent"></div>
					</div>
					<div style="font-weight: 400;font-size: 12px;color: #666666;">
						{{dateFtt(item.CreateTime,'MM:dd hh:mm')}}
					</div>
				</div>
			</div>
			<!-- 云文档搜索结果 -->
			<div style="padding: 0px 17px;overflow:auto;height: 425px;" v-infinite-scroll="scrollLoad"
				v-if="tabsIndex == 1">
				<div class="flexal" style="margin-top: 17px;" v-for="(item,index) in yunList" :key="index">
					<img :src="require('@/assets/img/messageRight/searchCloud.png')"
						style="width: 30px;height: 37px;" />
					<div style="margin-left: 10px;flex: 1;">
						<div style="font-weight: 500;font-size: 14px;color: #333333;width: 273px;" class="ellipsis">
							{{item.MsgName}}
						</div>
						<div style="font-weight: 400;font-size: 12px;color: #666666;width: 273px;margin-top: 5px;"
							class="ellipsis">{{getutxt(item.UserId,ulist,'UserName','ID')}}
							发送于{{dateFtt(item.CreateTime,'MM:dd hh:mm')}}</div>
					</div>
					<img :src="require('@/assets/img/skip.png')" style="width: 14px;height: 14px;" />
				</div>
			</div>
			<!-- 文件搜索结果 -->
			<div style="padding: 0px 17px;overflow:auto;height: 425px;" v-infinite-scroll="scrollLoad"
				v-if="tabsIndex == 2">
				<div class="flexal" style="margin-top: 17px;" v-for="(item,index) in isSearch? fillist:doumentList" :key="index" @contextmenu.prevent="openMenu($event,item)">
					<div class="flexcen">
					<el-progress v-if="item.downloading" type="circle" :percentage="item.percentComplete" :color="'#fff'" :define-back-color="'#6e6e6e'" :show-text="false" :width="30" :stroke-width="4" style="position: absolute;" ></el-progress>
					<img :src="fileIcon[item.MsgContent.suffix]" style="width: 40px;height: 37px;" />
					</div>
					<div style="margin-left: 10px;flex: 1;">
						<div style="font-weight: 500;font-size: 14px;color: #333333;width: 273px;" class="ellipsis">
							{{item.MsgName}}
						</div>
						<div style="font-weight: 400;font-size: 12px;color: #666666;width: 273px;margin-top: 5px;"
							class="ellipsis">{{getutxt(item.UserId,ulist,'UserName','ID')}}
							发送于{{dateFtt(item.CreateTime,'MM:dd hh:mm')}}</div>
					</div>
					<img :src="require('@/assets/img/8dca6f421c997c4620c4cc56c95c5bc.png')" style="width: 14px;height: 16px;margin-right: 15px;" @click="deleteFile(item)" />
					<img :src="require('@/assets/img/skip.png')" style="width: 14px;height: 14px;transform: rotate(90deg);"   @click="downloadFile(item)" />
				</div>
			</div>
			
			<!-- 图片/视频搜索结果 -->
			<div style="padding: 0px 16px;overflow:auto;height: 425px;" v-infinite-scroll="scrollLoad"
				v-if="tabsIndex == 3">
				<div style="margin-top: 29px;" v-for="(item,index) in photoList" :key="index">
					<div
						style="margin-left: 6px;font-weight: 400;font-size: 16px;color: #666666;width: 273px;margin-bottom: 10px;">
						{{item.year}}年{{item.month}}月
					</div>
					<div style="display: flex; flex-wrap: wrap;">
						<template v-for="(img,imgKey) in item.list">
							<!-- @click="handlePriveImg(img)" :preview-src-list="item.list" -->
							<el-image :src="img" ref="myImg"
								style="width: 98px;height: 98px;margin-left: 6px;margin-top: 6px;" :key="imgKey"
								 v-if="isImgOfVideo(img) == 'image'"
								@click="handlePrive(item.list,imgKey)" class="my-img" />
								<!-- @click="openVideo(img)" -->
							<div style="width: 98px;height: 98px; margin-left: 6px;margin-top: 6px;" :key="imgKey"
								v-if="isImgOfVideo(img) == 'video'"  @click="handlePrive(item.list,imgKey)">
								<div
									style="width: 98px;height: 98px;position: absolute;background-color: rgba(0, 0, 0, 0.4);display: flex; ">
									<img :src="require('@/assets/img/messageRight/video-white.png')"
										style="width: 20px;height: 15px;margin-top: 72px;margin-left: 9px;" />
									<div style="color: #FFFFFF;font-size: 12px;margin-top: 72px;margin-left: 9px;">00:20
									</div>
								</div>
								<video :src="img" style="width:100%; height:100%; object-fit: fill"></video>
							</div>
						</template>
					</div>
				</div>
			</div>

			<!-- 链接搜索结果 -->
			<div style="padding: 0px 17px;overflow:auto;height: 425px;" v-infinite-scroll="scrollLoad"
				v-if="tabsIndex == 4">
				<div class="flexal" style="margin-top: 17px;" v-for="(item,index) in isSearch? fillist:linklist" :key="index" @click="openLink(item)">
					<img :src="require('@/assets/img/messageRight/searchLine.png')"
						style="width: 55px;height: 55px; border-radius: 50%;" />
					<div style="margin-left: 10px;flex: 1;">
						<div style="font-weight: 500;font-size: 14px;color: #333333;width: 273px;" class="ellipsis">
							{{item.MsgName}}
						</div>
						<div
							style="font-weight: 400;font-size: 12px;color: #666666;width: 245px;margin-top: 5px;overflow-wrap: break-word;">
							{{item.MsgContent}}
						</div>
						<div style="font-weight: 400;font-size: 12px;color: #666666;width: 273px;margin-top: 5px;"
							class="ellipsis">{{getutxt(item.UserId,ulist,'UserName','ID')}}
							发送于{{dateFtt(item.CreateTime,'MM:dd hh:mm')}}</div>
					</div>
					<img :src="require('@/assets/img/skip.png')" style="width: 14px;height: 14px;" />
				</div>
			</div>
		</div>
		<!-- 右键菜单部分 -->
		<ul v-show="visible"  class="contextmenu" :style="{left:left+'px',top:top+'px'}">
				<li @click="handleOpen" v-if="electron">打开目录</li>
				<!-- <li @click="handleDownloadFile">重新下载</li> -->
			</ul>
		<!-- 视频预览弹窗 -->
		<videoPlayerPop :dialogVisibleShow="dialogVisibleVideoShow" @updateDialog="updateDialogClick" :VideoUrl="video"></videoPlayerPop>

		
		<!-- 图片/视频预览 -->
		<gallery :images="images" :index="galleryIndex" @close="galleryIndex = null"></gallery>
	</div>
</template>

<script>
	import videoPlayerPop from '../pop-up/videoPlayerPop.vue';
	import VueGallery from 'vue-gallery';


	export default {
		name: 'searchMessage',
		props: {
			// 弹窗标题
			index: {
				type: Number,
				default: 1
			},
			ulist: {
				type: Array,
				default: []
			},
			roomid: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				input: '',
				tabs: [{
					type: 0,
					name: '消息'
				}, {
					type: 6,
					name: '云文档'
				}, {
					type: 4,
					name: '文件'
				}, {
					type: 2,
					name: '图片/视频'
				}, {
					type: 3,
					name: '链接'
				}],
				tabsIndex: 0,
				video: 'https://luosai.oss-cn-shenzhen.aliyuncs.com/Upload/Video/2024/0730/202407301050064716.mp4',
				// 组织成员
				list: [],
				// 图片/视频数据集
				photoList: [],
				yunList: [], //云文档
				doumentList: [], //文件
				msglist: [], //文字消息
				linklist: [], //链接消息
				srcList: [], //预览图片集
				memberId: '', //选择成员
				dateTime: null, //选择日期
				query: {
					id: 0, //群组ID
					uid: 0, //用户id
					type: 0, //消息类型
					page: 1, //页码
					limit: 20, //条数
					isnext: true, //可否加载下一页
					isload: false, //加载中

				},
				images: [
					// {
					// // title: 'Fruits',
					// type: 'video/mp4',
					// href: 'https://luosai.oss-cn-shenzhen.aliyuncs.com/Upload/Video/2024/0730/202407301050064716.mp4',
					// //   poster: 'https://example.org/images/fruits.jpg'
					// },
					// {
					// // title: 'Banana',
					// type: 'image/jpeg',
					// href: 'https://luosai.oss-cn-shenzhen.aliyuncs.com/Upload/2024/0730/202407301050133207.jpg',
					// //   thumbnail: 'https://example.org/thumbnails/banana.jpg'
					// }
				],
				galleryIndex: null,
				fillist:[],				//查询后的数组
				isSearch:false,			//是否查询
				start:0,				//开始时间
				end:0,					//结束时间
				// 自定义右键弹窗
				rightClickItem: '',
				visible: false, // 是否展示右键菜单
				top: 0,
				left: 0,
				electron:false,
			}	
		},
		watch: {
			// New: 新值 | Old: 老值
			memberId(New, Old) {
				// this.tabsIndex = New
				this.searchList()
				console.log(`新值:${New} ——— 老值:${Old}`)
			},
			dateTime(New, Old) {
				this.start = this.startTime(New)
				this.end = this.endTime(New)
				this.searchList()
				console.log(`开始:${this.start} ——— 结束:${this.end}`)
			},
			ulist(newval) {
				console.log('我是组织成员',newval)
				this.list = newval
			},
			input:{
				immediate:true,//在框的值还没变化时执行如下函数显示出所有的情况
            	handler(val){
					console.log('文本内容',val)
					this.searchList()
				}
			},
			// 监听 visible，来触发关闭右键菜单，调用关闭菜单的方法
			visible(value) {
				if (value) {
					document.body.addEventListener('click', this.closeMenu)
				} else {
					document.body.removeEventListener('click', this.closeMenu)
				}
			}
			// memberId:{
			// 	immediate:true,//在框的值还没变化时执行如下函数显示出所有的情况
            // 	handler(val){
			// 		console.log('文本内容',val)
			// 		if(val){
			// 			this.fillist = this.msglist.filter((p)=>{
			// 				return p.MsgContent.indexOf(val) !==-1 
			// 			})
			// 		}else{
			// 			this.fillist = []
			// 		}
				
			// 	console.log('搜索后的数组',this.fillist,this.ulist)
			// 	}
			// },

		},
		mounted() {
			this.tabsIndex = this.index
			this.query.page = 1
			this.getlists()
			if(window.electron){
				this.electron = true
			}
		},
		components: {
			videoPlayerPop,
			"gallery":VueGallery
		},
		created() {
			document.addEventListener("click", this.myFunction)
		},
		methods: {
			//搜索功能
			searchList(){
				if(this.tabsIndex == 0){
					this.fillist = []
					if(this.input && this.memberId && this.dateTime){
						this.isSearch = true
						this.fillist = this.msglist.filter((p)=>{
						var date = new Date(p.CreateTime).getTime();
						let userId =  p.UserId.toString()
						let memberId = this.memberId.toString()
						return p.MsgContent.indexOf(this.input) !==-1 && userId.indexOf(memberId) !==-1 && ( date >= this.start && date <= this.end)
					})
				}else if(this.input && !(this.memberId) && this.dateTime == null){
						this.isSearch = true
						this.fillist = this.msglist.filter((p)=>{
						return p.MsgContent.indexOf(this.input) !==-1 
					})
				}else if(!(this.input) && this.memberId && this.dateTime == null){
						this.isSearch = true
						this.fillist = this.msglist.filter((p)=>{
						let userId =  p.UserId.toString()
						let memberId = this.memberId.toString()
						return  userId.indexOf(memberId) !==-1
					})
				}else if(this.input && this.memberId && this.dateTime == null){
					this.isSearch = true
						this.fillist = this.msglist.filter((p)=>{
						let userId =  p.UserId.toString()
						let memberId = this.memberId.toString()
						return p.MsgContent.indexOf(this.input) !==-1 && userId.indexOf(memberId) !==-1 
					})
				}else if(this.input && !(this.memberId) && this.dateTime){
					this.isSearch = true
						this.fillist = this.msglist.filter((p)=>{
						var date = new Date(p.CreateTime).getTime();
						return p.MsgContent.indexOf(this.input) !==-1 && ( date >= this.start && date <= this.end)
					})
				}else if(!(this.input) && this.memberId && this.dateTime){
					this.isSearch = true
						this.fillist = this.msglist.filter((p)=>{
						var date = new Date(p.CreateTime).getTime();
						let userId =  p.UserId.toString()
						let memberId = this.memberId.toString()
						return  userId.indexOf(memberId) !==-1 && ( date >= this.start && date <= this.end)
					})
				}else if(!(this.input) && !(this.memberId) && this.dateTime){
					this.isSearch = true
						this.fillist = this.msglist.filter((p)=>{
						var date = new Date(p.CreateTime).getTime();
						return  ( date >= this.start && date <= this.end)
					})
				}else{
					this.isSearch = false
				}
				}else if(this.tabsIndex == 2){
					this.fillist = []
					if(this.input && this.memberId){
						this.isSearch = true
						this.fillist = this.doumentList.filter((p)=>{
						let userId =  p.UserId.toString()
						let memberId = this.memberId.toString()
						return p.MsgName.indexOf(this.input) !==-1 && userId.indexOf(memberId) !==-1
					})
					}else if(this.input && !(this.memberId)){
							this.isSearch = true
							this.fillist = this.doumentList.filter((p)=>{
							return p.MsgName.indexOf(this.input) !==-1 
						})
					}else if(!(this.input) && this.memberId){
							this.isSearch = true
							this.fillist = this.doumentList.filter((p)=>{
							let userId =  p.UserId.toString()
							let memberId = this.memberId.toString()
						return  userId.indexOf(memberId) !==-1
					})
					}else{
						this.isSearch = false
					}
				}else if(this.tabsIndex == 4){
					this.fillist = []
					if(this.input && this.memberId){
						this.isSearch = true
						this.fillist = this.linklist.filter((p)=>{
						let userId =  p.UserId.toString()
						let memberId = this.memberId.toString()
						return p.MsgContent.indexOf(this.input) !==-1 && userId.indexOf(memberId) !==-1
					})
					}else if(this.input && !(this.memberId)){
							this.isSearch = true
							this.fillist = this.linklist.filter((p)=>{
							return p.MsgContent.indexOf(this.input) !==-1 
						})
					}else if(!(this.input) && this.memberId){
							this.isSearch = true
							this.fillist = this.linklist.filter((p)=>{
							let userId =  p.UserId.toString()
							let memberId = this.memberId.toString()
						return  userId.indexOf(memberId) !==-1
					})
					}else{
						this.isSearch = false
					}
				}
				
				console.log('我进来了',this.fillist)
			},

			// 打开链接
			openLink(item){
				console.log('打开链接',item)
				window.open(item.MsgContent)
			},

			// 关闭
			closeDialog() {
				console.log('我被取消了', this.dialogVisibleMember)
				this.$emit('updateDialog')
			},
			
			// 重置
			resetClick() {
				this.memberId = '' //选择成员
				this.dateTime = null //选择日期
				this.searchList()
			},
			//滑动到底部
			scrollLoad() {
				console.log('下拉到底部了')
			},

			// 点击查看大图
			handlePriveImg(row) {
				this.srcList.push(row); //将后端的这一行的数据push进数组中
				//   this.$refs.myImg.clickHandler();
			},
			// 点击查看视频
			openVideo(value) {
				console.log('我被点击了')
				this.dialogVisibleVideoShow = true
				this.video = value
			},
			// 弹窗关闭回调
			updateDialogClick() {
				// console.log('我被调用了')
				this.dialogVisibleVideoShow = false
			},
			tabsChange(index) {
				this.tabsIndex = index
				this.query.page = 1
				this.msglist = []
				this.yunList = []
				this.doumentList = []
				this.photoList = []
				this.linklist = []
				this.getlists()
			},
			async getlists() {
				var rlist = []
				if (!this.query.isload) {
					this.query.isload = true
					if (this.query.page == 1) {
						this.query.isnext = true
					}
					if (this.query.isnext) {

						this.query.id = this.roomid
						this.query.type = this.tabs[this.tabsIndex].type
						var row = {
							type: this.query.type
						}
						if (this.query.type == 2) {
							row.type2 = 5
						}
						rlist = await this.msggetlist(this.query.id, this.query.page, this.query.limit, row)
						this.query.page += 1
						if (rlist.length < this.query.limit) this.query.isnext = false
						console.log('rlist', rlist)
					}
					this.query.isload = false
				}

				if (this.tabsIndex == 0) {
					// rlist.sort((a, b) => a.MsgId - b.MsgId);
					this.msglist = this.msglist.concat(rlist)
				} else if (this.tabsIndex == 1) {
					this.yunList = this.yunList.concat(rlist)
				} else if (this.tabsIndex == 2) {
					rlist.map(it => {
						it.MsgContent=JSON.parse(it.MsgContent)
						it.downloading = false
						it.percentComplete = 0
					})
					this.doumentList = this.doumentList.concat(rlist)
					console.log('doumentList',this.doumentList)
				} else if (this.tabsIndex == 3) {
					rlist.sort((a,b)=>a.CreateTime-b.CreateTime)
					rlist.map(it => {
						var content=JSON.parse(it.MsgContent)
						var index = this.photoList.findIndex(a => a.year == it.YearNum && a.month == it.MonthNum)
						if (index >= 0) {
							this.photoList[index].list.push(content.url)
						} else {
							var data = {
								year: it.YearNum,
								month: it.MonthNum,
								list: []
							}
							data.list.push(content.url)
							this.photoList.push(data)
						}
					})
				} else if (this.tabsIndex == 4) {
					this.linklist = this.linklist.concat(rlist)
				}
			},
			// 跳转到预览页面
			// goPages(){

			// },
			// 预览图片和视频
			handlePrive(list,index){
				console.log('list',list)
				let array = []
				list.forEach(element => {
					if(this.isImgOfVideo(element) == 'video'){
						let arr = {
							  title: 'Fruits',
							type: 'video/mp4',
							href: element,
							//   poster: 'https://example.org/images/fruits.jpg'
							}
						array.push(arr)
					}
					if(this.isImgOfVideo(element) == 'image'){
						let arr = {
							//   title: 'Fruits',
							type: 'image/jpeg',
							href: element,
							//   poster: 'https://example.org/images/fruits.jpg'
							}
						array.push(arr)
					}
				});
				this.images = array
				this.galleryIndex = index
				console.log('images',array,index)
			},
			downloadFiles(item){
				
				// let res = JSON.parse(item.MsgContent)
				// console.log('res',res,item)
				this.download(item.MsgContent.url,item.MsgName)
				// window.open(res.url);
				// this.download(res.url, item.MsgName)
			},
			//下载消息文件
			downloadFile(message,index){
				// message.MsgContent.downloading = true
				// message.MsgContent.isxiazai = true
				
				this.$store.commit("setFileList", message);
				var value = this.$store.state.messageStore.fileList;
				// console.log('下载中文件列表',value,message)
				console.log('文件下载',message)
				this.downloadCode(message.MsgContent.url,message.MsgName,message)
				
				// console.log('消息文件',this.messages)
				// let upd = `${JSON.stringify(message.MsgContent)}`
				// this.msgUpdContent(message.MsgId, upd).then(rs => {
				// 		console.log("通话更新消息：", rs);
				// 	})
			},
			// 打开右键菜单
			openMenu(e, item) {
				this.visible = true
				this.left = e.pageX > 1330 ? 1330:e.pageX
				this.top = e.pageY
				
				this.rightClickItem = item;
				console.log('触发了', this.visible, this.left, this.top)
			},
			// 关闭右键菜单
			closeMenu() {
				this.visible = false;
			},
			// 重新下载
			handleDownloadFile(){

			},
			// 打开目录
			handleOpen(){
				if(window.electron){
					window.electron.ipcRenderer.invoke('downloadFilePath').then(inputSources => {	
						
					})
				}
			},
			// 删除文件
			async deleteFile(item){
				console.log('删除',this.roomid,item)
				// var stt = this.groupNickname
					var MsgId = parseInt(item.MsgId)
					var data = {
						roomid: this.roomid,
						msgids: [MsgId],
					}
				var rs = await this.$request.post('/chat/delMsg', data)
				if (rs.code == 200) {
					// this.messageDetail.RoomName = this.groupName
					// this.$parent.fatherDelete(MsgId)
					this.doumentList.forEach((element,index) => {
						console.log('删除',element)
						if(element.MsgId == MsgId){
							this.doumentList.splice(index, 1);
						}
					});
				}
			}
		}

	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	.my-img:hover {
		//使鼠标悬浮在图片上时出现手的形状
		cursor: pointer;
	}

	.right-frame {
		margin-top: 0px;
		position: absolute;
		width: 457px;
		// height: 750px;
		height: 665px;
		background: #F8F9FA;
		z-index: 101;
		/* Ensure it sits on top of other content */
		-webkit-overflow-scrolling: touch;
		overflow-scrolling: touch;
		overflow-y: auto;
		overflow-x: hidden;

		.title-text {
			font-weight: 500;
			font-size: 16px;
			color: #333333;
		}

		.right-frame-title {
			padding: 15px 16px;
		}

		.right-line-bottom {
			margin-left: 16px;
			width: 424px;
			height: 0px;
			border: 1px solid #707070;
			opacity: 0.32;
		}

		.right-tips-text {
			font-weight: 400;
			color: #6B6B6B;
			font-size: 14px;
		}

		.el-button {
			padding: 0px 0px;
			border-radius: 8px;
			background-color: rgba(0, 0, 0, 0.3);
			font-size: 24px;
			color: rgba(255, 255, 255, 1);
			border: transparent;
			overflow: hidden;
		}

		.title-qrCode-frame {
			position: absolute;
			margin-left: -300px;
			z-index: 101;
			/* Ensure it sits on top of other content */
			width: 302px;
			height: 412px;
			background-image: url('../../assets/img/navigation/qrCode-frame.png');
			/* 替换为实际的图片路径 */
			background-size: cover;
			/* 确保背景图像覆盖整个容器 */
			background-position: center;
			/* 将背景图像居中对齐 */
			background-repeat: no-repeat;
			/* 避免背景图像重复 */
			box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.17);
			border-radius: 16px 16px 16px 16px;
		}

		.saveQrCode {
			width: 132px;
			height: 39px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			border: 1px solid #0052D9;
			font-size: 12px;
			color: #0052D9;
		}

		.shareQrCode {
			margin-left: 10px;
			width: 132px;
			height: 39px;
			background: #0052D9;
			border-radius: 8px 8px 8px 8px;
			color: #FFFFFF;
			font-size: 12px;
		}

		.frame-reset {
			margin-top: -10px;
			margin-right: 16px;
			font-weight: 400;
			font-size: 16px;
			color: #2953E7;
		}
	}

	.left-item {
		display: flex;
		width: 425px;
		align-items: center;
		border-radius: 8px;

		.prefix-icon3 {
			margin-left: 5px;
			width: 15px;
			height: 15px;
			background-image: url('../../assets/img/navigation/search.png');
			background-size: cover;
			/* 确保图像覆盖整个背景区域 */
			background-position: center;
			/* 图像居中对齐 */
			background-repeat: no-repeat;
			/* 防止图像重复 */
		}

		/deep/.el-input__inner {
			height: 36px;
			font-size: 15px;
			border-radius: 8px;
		}
	}

	::v-deep .enterpriseName .el-input__inner {
		width: 100px;
		height: 24px;
		background-color: #F8F9FA;
		text-align: center;
		border-color: #F8F9FA;
		color: #000;
		border: 0px solid #DCDFE6;
		padding: 0px 0px;
		text-align: left;
		font-size: 14px;
	}

	.frame-text {
		font-weight: 400;
		font-size: 16px;
		color: #333333;
		width: 20%;
		padding-top: 24px;
		padding-bottom: 25px;
		flex-direction: column;

		.frame-slid-block {
			width: 28px;
			height: 3px;
			background: #0052D9;
			border-radius: 4px 4px 4px 4px;
		}
	}

	.frame-text-active {
		color: #0052D9;
	}

	::v-deep .frame-date .el-input__icon {
		height: 40px;
	}

	// 自定义右键弹窗样式
	.contextmenu {
		margin: 0;
		background: #fff;
		z-index: 3000;
		position: absolute;
		list-style-type: none;
		padding: 5px 0;
		border-radius: 4px;
		font-size: 12px;
		font-weight: 400;
		color: #333;
		box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
	}

	.contextmenu li {
		margin: 0;
		padding: 7px 16px;
		cursor: pointer;
	}

	.contextmenu li:hover {
		background: #eee;
	}
</style>