<template>
	<div>
		<el-dialog :title="title" :visible.sync="dialogVisible" top="10vh" width="648px" class="myDialogClass"
			@close="closeDialog">

			<div style="height: 415px;overflow: auto;" class="flexcumal">


				<div class="frame-item" v-for="(item,index) in list" :key="index">

					<div class="flexal" @click="item.show = !item.show">
						<div style="margin-left: 13px;flex: 1;">
							<div class="">{{item.total}}人签到</div>
							<div class="flexal" style="margin-top: 5px;">
								<div class="item-icon" :style="{ 'background': item.State==0? '#366EF4':'#333333' }">
								</div>
								<span class="item-text"
									:style="{ 'color':  item.State==0? '#366EF4':'#333333' }">{{ item.State==0? '进行中':'已结束'}}</span>
								<span class="item-time">{{dateFtt(item.CreateTime,'yyyy/MM/dd hh:mm')}} 发起</span>
							</div>
						</div>
						<img :src="require('@/assets/img/right.png')"
							style="width: 10px;height: 16px;margin-right: 20px; "
							:style="{'transform':  item.State==0?  'rotate(-90deg)':'rotate(90deg)'}" />
					</div>

					<div class="flexal" style="margin-top: 18px;" v-if="item.show">
						<div style="font-weight: 400;font-size: 11px;color: #424A57;flex: 1;margin-left: 13px;">签到成员
						</div>
						<div style="font-weight: 400;font-size: 11px;color: #424A57;margin-right: 26px;">签到时间</div>
					</div>
					<!-- 分割线 -->
					<div class="item-line" v-if="item.show"></div>

					<div style="margin-left: 13px;" v-if="item.show">
						<div class="flexal" style="margin-top: 10px;" v-for="(items,indexs) in item.list" :key="indexs">
							<img :src="items.Avatar" style="width: 32px;height: 32px;border-radius: 50%;" />
							<div style="margin-left: 12px;flex: 1;">
								<div style="font-weight: 500;font-size: 13px;color: #333333;">{{items.UserName}}</div>
								<div style="font-weight: 400;font-size: 10px;color: #A2A6B1;">{{items.posiName}}</div>
							</div>
							<div style="margin-right: 23px;">
								<div style="font-weight: 400;font-size: 13px;color: #15181D;">
									{{dateFtt(items.CreateTime,'hh:mm')}}</div>
								<div style="font-weight: 400;font-size: 11px;color: #858A99;">
									{{dateFtt(items.CreateTime,'yyyy/MM/dd')}}</div>
							</div>
						</div>
					</div>

				</div>



			</div>

			<!--弹窗底部-->
			<template #footer>
				<span class="myDialogFooter">
					<!-- <el-button type="primary" @click="dialogVisible = false">取消</el-button>
					<el-button type="primary" @click="handleeditusers()"
						style="background: #0052D9;color: #FFFFFF;">确定</el-button> -->
				</span>
			</template>
		</el-dialog>
	</div>

</template>

<script>
	export default {
		name: 'bookEditPop',
		props: {
			// 是否显示弹窗
			dialogVisibleShow: {
				type: Boolean,
				default: false
			},
			// 弹窗标题
			title: {
				type: String,
				default: '签到'
			},
		},
		data() {
			return {
				roomId: 0,
				dialogVisible: false,
				input: '',
				num: 0,
				content: '',
				useravatar: '',
				username: '',
				list: []
			};
		},
		watch: {
			// New: 新值 | Old: 老值
			dialogVisibleShow(New, Old) {
				this.dialogVisible = New
				this.useravatar = this.avatar
				this.content = this.name
				console.log(`新值:${New} ——— 老值:${Old}`)
				if (New) {

					var value = this.$store.state.messageStore.conferenceId;
					console.log(`conferenceId:${this.roomId}`)
					this.roomId = value
					this.loadlist()
				}
			},
		},
		mounted() {
			console.log('组件初始化', this.avatar)
		},
		methods: {
			closeDialog() {
				this.$emit('updateDialog')
			},
			cancelClick(index) {
				this.list[index].checked = false
			},
			scrollLoad() {
				console.log('下拉到底部了')
			},
			//加载记录
			async loadlist() {
				var data = {
					RoomId: this.roomId
				}

				var rs = await this.$request.post('/meeting/signList', data)
				if (rs.code == 200) {
					this.list = rs.data
				}

			},
		},
	};
</script>

<style scoped lang="less">
	.frame-item {
		margin-top: 14px;
		width: 587px;
		padding: 13px 0px;
		// height: 61px;
		background: #F9F9F9;
		border-radius: 8px 8px 8px 8px;

		.item-icon {
			width: 8px;
			height: 9px;
			background: #366EF4;
			border-radius: 50%;
		}

		.item-text {
			margin-left: 5px;
			font-weight: 400;
			font-size: 10px;
			color: #366EF4;
		}

		.item-time {
			margin-left: 12px;
			font-weight: 400;
			font-size: 10px;
			color: #333333;
		}

		.item-line {
			margin-top: 11px;
			margin-left: 13px;
			width: 564px;
			height: 0px;
			border: 1px solid #707070;
			opacity: 0.17;
		}
	}

	.myDialogClass {
		border-radius: 16px;
	}

	.el-dialog .el-dialog__footer {
		padding: 10px;
	}

	/deep/.el-dialog__body {
		padding: 0 1vw;
	}

	/deep/.el-checkbox__inner {
		width: 21px;
		height: 21px;
		border: 2px solid #9B9B9B;
		border-radius: 50%;
	}

	/* 对勾样式 */
	/deep/.el-checkbox__inner::after {
		border: 2px solid #fff;
		border-left: 0;
		border-top: 0;
		left: 6px;
		top: 3px;
	}

	/deep/.memberInput .el-input__inner {
		width: 836px;
		height: 56px;
		background: #FFFFFF;
		border-radius: 16px 16px 16px 16px;
		border: 1px solid #B0B1B4;
	}

	.myDialogFooter .el-button {
		// border-color: rgb(42,155,152);
		// background-color: rgb(242,249,249);
		// color: rgb(42,155,152);
		width: 113px;
		height: 45px;
		background: #FFFFFF;
		border-radius: 16px 16px 16px 16px;
		border: 2px solid #0052D9;
		font-size: 16px;
		color: #0052D9;
	}
</style>