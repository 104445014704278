<template>
	<div style="width: 100%;float: center;display: flex;justify-content: flex-end;">
		<div class="right-frame">
			<!-- 新加入的成员 -->
			<div class="right-frame-title flexal">
				<img :src="require('@/assets/img/right.png')" style="width: 10px;height: 16px;transform: rotate(180deg)"
					@click="submit" />
				<div class="right-tips-text" style="font-size: 17px;margin-left: 20px;color: #333333;flex: 1;">接收人</div>
				<img :src="require('@/assets/img/cancel.png')" style="width: 18px;height: 18px;" @click="submit" />
			</div>

			<!-- 新加入的成员 -->
			<div v-infinite-scroll="scrollLoad" style="height: 605px;overflow: auto;padding: 14px 20px 14px 28px;">
				<div class="flexal" style="margin-bottom: 13px;" v-for="(item,index) in list " :key="index">
					<img :src="item.Avatar" style="width: 40px;height: 40px;border-radius: 50%;">
					<div style="margin-left: 10px;flex: 1;">
						<div class="join-name">{{item.UserName}}</div>
						<div class="join-text">{{item.time}}</div>
					</div>
					<div class="button flexcen" :class="item.state==0? 'button-active':''">
						{{ item.state==0? '未签收':'已签收' }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'signInListFrame',
		props: {
			list: {
				type: Array,
				default: []
			}
		},
		data() {
			return {
				input: '',
				avatar: 'https://luosai.oss-cn-shenzhen.aliyuncs.com/Upload/2024/0730/202407301050133207.jpg',
				avatar1: 'https://cdn.uviewui.com/uview/album/7.jpg'
			}
		},
		methods: {
			// 发送/关闭，按钮回调  0:关闭  1:发送
			submit() {
				// var params = {
				//     type:0,
				//     data:this.input
				// }
				this.$emit('updateDialog')
			},

			// 滑底触发
			scrollLoad() {
				console.log()
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	.right-frame {
		margin-top: 0px;
		position: absolute;
		width: 457px;
		// height: 750px;
		height: 665px;
		background: #F8F9FA;
		z-index: 101;
		/* Ensure it sits on top of other content */

		.title-text {
			font-weight: 500;
			font-size: 16px;
			color: #333333;
		}

		.right-frame-title {
			padding: 15px 16px;
		}

		.right-line-bottom {
			margin-left: 16px;
			width: 424px;
			height: 0px;
			border: 1px solid #707070;
			opacity: 0.32;
		}

		.right-tips-text {
			font-weight: 400;
			color: #6B6B6B;
			font-size: 14px;
		}

		.el-button {
			// width: 96px;
			// height: 45px;
			padding: 0px 0px;
			border-radius: 8px;
			background-color: rgba(0, 0, 0, 0.3);
			font-size: 24px;
			color: rgba(255, 255, 255, 1);
			border: transparent;
			overflow: hidden;
		}

		.title-qrCode-frame {
			position: absolute;
			margin-left: -300px;
			z-index: 101;
			/* Ensure it sits on top of other content */
			width: 302px;
			height: 412px;
			// background: #FFFFFF;
			background-image: url('../../assets/img/navigation/qrCode-frame.png');
			/* 替换为实际的图片路径 */
			background-size: cover;
			/* 确保背景图像覆盖整个容器 */
			background-position: center;
			/* 将背景图像居中对齐 */
			background-repeat: no-repeat;
			/* 避免背景图像重复 */
			box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.17);
			border-radius: 16px 16px 16px 16px;
		}

		.saveQrCode {
			width: 132px;
			height: 39px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			border: 1px solid #0052D9;
			font-size: 12px;
			color: #0052D9;
		}

		.shareQrCode {
			margin-left: 10px;
			width: 132px;
			height: 39px;
			background: #0052D9;
			border-radius: 8px 8px 8px 8px;
			color: #FFFFFF;
			font-size: 12px;
		}

		.frame-time-list {
			margin-left: 13px;
			margin-top: 14px;
			width: 130px;
			height: 19px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			border: 2px solid #366EF4;
			font-weight: 400;
			font-size: 11px;
			color: #366EF4;
		}

		.frame-audit-content {
			padding: 20px 44px;
		}

		.frame-stand {
			margin-left: 50px;
			margin-top: 4px;
			margin-bottom: 4px;
			margin-right: 30px;
			width: 1px;
			height: 24px;
			background: #D6D6D6;
			border-radius: 0px 0px 0px 0px;
		}

		.frame-across {
			// margin-left: 24px;
			// margin-top: 18px;
			margin: 18px 24px;
			width: 415px;
			height: 1px;
			background: #C9C9C9;
			border-radius: 0px 0px 0px 0px;
		}
	}

	.frame-button-send {

		width: 122px;
		height: 47px;
		background: #0052D9;
		border-radius: 16px 16px 16px 16px;
		font-size: 16px;
		color: #FFFFFF;
	}

	.join-name {
		font-weight: 500;
		font-size: 12px;
		color: #333333;
	}

	.join-text {
		font-weight: 400;
		font-size: 11px;
		color: #6E6E6E;
	}

	.button {
		width: 71px;
		height: 24px;
		border-radius: 24px 24px 24px 24px;
		border: 1px solid #A2A6B1;
		color: #858A99;
		font-weight: normal;
		font-size: 13px;
	}

	.button-active {
		color: #0052D9;
		border: 1px solid #0052D9;
	}

	/deep/.el-textarea__inner {
		background: #F1F1F1;
		min-height: 114px;
		border-radius: 16px;
	}
</style>