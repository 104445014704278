import Vue from 'vue'
import moudelWebSocket from "@/util/webSocket.js";
import {
	saveAs as FileSaver
} from 'file-saver'

import localdic from '@/util/dicHelper.js'
import dayjs from 'dayjs'
const axios = require('axios');
// const fs = require('fs');
export const mixins = {
	data() {
		return {
			msgs: "我是小猪课堂",
			AddShow: false, //是否显示弹窗操作栏
			openQrcode: false, //打开二维码
			optShow: false, //右侧操作栏是否显示
			dialogVisibleMember: false, //添加弹窗
			dialogVisibleNotice: false, //群公告编辑弹窗
			searchMessageShow: false, //搜索会话内容
			imageExtensions: ['jpg', 'jpeg', 'gif', 'png'], //图片后缀
			videoExtensions: ['mp4', 'wmv', 'avi', 'mov'], //视频后缀
			dialogVisibleVideoShow: false, //视频预览是否显示
			dialogVisibleDelete: false, //删除弹窗是都显示
			auditShow: false, //审批详情
			longFrameShow: false, //远程桌面是否显示
			dialogVisibleBookEdit: false, //通讯录编辑管理弹窗
			visibleJoinConference: false, //加入会议弹窗
			visibleLaunchConference: false, //发起会议弹窗
			visibleStartConference: false, //开始会议弹窗
			visibleSignInConference: false, //签到弹窗
			visibleVoteInConference: false, //投票弹窗
			visiblefileConference: false, //文档弹窗
			openInfoFrame: false, //个人信息弹窗
			openInfoFrameEdit: false, //个人信息修改弹窗
			detailFrameShow: false, //公告详情界面
			verifyShow: false, //进群验证界面
			allMemberShow: false, //全部群成员界面
			signInListShow: false, //签收人列表界面
			percentComplete:0,	//下载进度
			downloading:false,		//下载中
			// $eventBus: null, //stocket事件
		};
	},
	computed: {
		// 文件消息图标
		fileIcon() {
			let fileIcon = []
			fileIcon['ppt'] = require('@/assets/img/messageRight/ppt.png')
			fileIcon['pdf'] = require('@/assets/img/messageRight/pdf.png')
			fileIcon['doc'] = require('@/assets/img/messageRight/word.png')
			fileIcon['docx'] = require('@/assets/img/messageRight/word.png')
			fileIcon['xls'] = require('@/assets/img/messageRight/xlx.png')
			fileIcon['xlsx'] = require('@/assets/img/messageRight/xlx.png')
			fileIcon['zip'] = require('@/assets/img/messageRight/package.png')
			fileIcon['rar'] = require('@/assets/img/messageRight/package.png')
			fileIcon['txt'] = require('@/assets/img/messageRight/package.png')
			fileIcon['package'] = require('@/assets/img/messageRight/package.png')
			// console.log('fileIcon',fileIcon['ppt'])
			return fileIcon
		}
	},
	created() {

		//   console.log("我是mixin中的created生命周期函数");
	},
	mounted() {
		//   console.log("我是mixin中的mounted生命周期函数");
	},
	methods: {
		async moudelweb() {
			// this.$eventBus = new Vue();
			var data = {}
			if(window.electron){
				data.device = 2
			}else{
				data.device = 1
			}
			// var data = {
			// 	device: 1
			// }
			var rs = await this.$request.post('/chat/preConnect', data)
			if (rs.code == 200) {
				var url = rs.data
				//启动连接
				moudelWebSocket.startWebSocket(this.$eventBus, url)();
				var userid = await localdic.get('userId')

				this.$eventBus.$on("webSocketClose", async (data) => {
					console.log('断开链接',data)
					var rs = await this.$request.post('/chat/preConnect', data)
					if(rs.code == 200){
						var url = rs.data
						moudelWebSocket.reconWebSocket(url)();
					}

				})

				this.$eventBus.$on("remotemessage", async (wdata) => {

					var cdata = JSON.parse(wdata.data)
					var _isok = false
					//远程控制请求
					this.$overlayRemote.show(cdata.type, cdata.touser, _isok)

				})

				this.$eventBus.$on("message", async (wdata) => {
					console.log('主页面-message', wdata)
					
					//新增信息记录
					if (wdata.UserId == userid) {
						wdata.State = 1
					}
					if (wdata.MsgType != 10 && wdata.MsgType != 99) {
						var mdata = wdata;
						if (typeof mdata.MsgContent != 'string')
							mdata.MsgContent = JSON.stringify(mdata.MsgContent)
						if (typeof mdata.MsgName != 'string')
							mdata.MsgName = JSON.stringify(mdata.MsgName)
						var isok = await this.msgAdd(mdata)
						if (isok) {
							if (wdata.MsgType > 0 && wdata.MsgType < 7 && wdata.MsgType != 3) {
								if (typeof wdata.MsgContent == 'string') {
									wdata.MsgContent = JSON.parse(wdata.MsgContent)
								}
							}
							//更新聊天
							var data = await localdic.get('chatData')
							if (data && data != '') {
								var chatlist = JSON.parse(data)
								var index = chatlist.findIndex(a => a.RoomId == wdata.RoomId)
								if (index >= 0) {
									console.log('我进来了,我是更新聊天', chatlist)
									chatlist[index].msg = wdata
									chatlist[index].messageDetele = false
									await localdic.set('chatData', JSON.stringify(chatlist))
									this.detailchange += 1;
								} else {
									this.isinitNum += 1;
								}
							}
						}
					}

					if(wdata.MsgType == 99){
						console.log('当前是删除',wdata)
						this.msgDelByMsgId(parseInt(wdata.MsgContent))
						//删除聊天
						// this.deleteMessage(parseInt(wdata.MsgContent))
						this.detailchange += 1;
					}



					//通话
					if (wdata.MsgType == 7 || wdata.MsgType == 8) {
						//判断当前用户是否被呼叫用户
						if (typeof wdata.MsgContent == 'string') wdata.MsgContent = JSON.parse(wdata
							.MsgContent)
						var ulist = wdata.MsgContent.ulist
						if (userid != wdata.UserId && ulist.findIndex(a => a.ID == userid) >= 0) {
							var teltype = (wdata.MsgType > 7 ? 1 : 0);

							//判断是否已显示通话
							var telshow =await localdic.get('overlayPhoneShow')
							if (telshow == undefined || telshow==0) {
								this.$overlayPhone.show(teltype, ulist, wdata.MsgId, 0)
							}
						}
					} else if (wdata.MsgType == 10) {
						//签收信息
						if (userid && wdata.CallUserId == 0) {
							console.log('我进来了')
							var ulist = Array.isArray(wdata.MsgName) ? wdata
								.MsgName : JSON.parse(wdata.MsgName)
							if (userid == wdata.UserId || ulist.findIndex(a => a.ID == userid) >= 0) {
								var isok = await this.msgAdd(wdata)
								if (isok) {
									if (wdata.RoomId == this.messageDetail.RoomId) {
										this.messageDetail.msg = wdata
									}
									//更新聊天
									var data = await localdic.get('chatData')
									if (data && data != '') {
										var chatlist = JSON.parse(data)
										var index = chatlist.findIndex(a => a.RoomId == wdata.RoomId)
										if (index >= 0) {
											console.log('我进来了,我是更新聊天', chatlist)
											chatlist[index].msg = wdata
											await localdic.set('chatData', JSON.stringify(chatlist))
											this.detailchange += 1;
										} else {
											this.isinitNum += 1;
										}
									}
								}
							}
						} else {
							console.log('你好呀')
							var content = Array.isArray(wdata.MsgName) ? JSON.stringify(
								wdata.MsgName) : wdata.MsgName
							var isok = await this.msgUpdName(wdata.MsgId, content)
							if (isok) {

							}
						}
					}
					this.ischange++
					var that = this
					//最后统计所有未读条数
					this.tab[0].count = await this.msgloadcount()
					setTimeout(() => {
						console.log('延迟执行了',that.tab[0].count)
						if(that.tab[0].count > 0){
							that.flicker()
						}
					  }, 500);
				});
				//最后统计所有未读条数
				this.tab[0].count = await this.msgloadcount()
			}
		},
		

		//获取用户信息
		getutxt(userid, ulist, col, idcol = 'UserId') {
			let txt = '';
			if (userid > 0 && ulist && ulist.length > 0) {
				var index = ulist.findIndex(a => a[idcol] == userid)
				if (index >= 0) {
					var user = ulist[index]
					txt = user[col]
				}
			}
			return txt
		},
		//计算头像布局
		computedAvatar(avatarList) {
			if (avatarList.length > 4) {
				return "avatarItem--3"
			} else if (avatarList.length > 1) {
				return "avatarItem--2"
			} else {
				return "avatarItem--1"
			}
		},

		// 开发中
		inDevelopment() {
			this.$message({
				message: '开发中，敬请等待',
				type: 'warning'
			});
			return
		},
		// 获取文件名中最后一个点的位置
		getFileExtension(filename) {
			const lastDot = filename.lastIndexOf('.');
			// 如果没有点或者点位于第一个位置，返回空字符串
			if (lastDot === -1 || lastDot === 0) return '';
			// 返回点之后的字符串，即后缀名
			return filename.substring(lastDot + 1).toLowerCase();
		},
		//文件类型
		getFileTypeByMime(mimeType) {

			if (mimeType.startsWith('image/')) {
				return 'image';
			} else if (mimeType.startsWith('video/')) {
				return 'video';
			} else if (mimeType.startsWith('audio/')) {
				return 'audio';
			} else if (mimeType.startsWith('text/')) {
				return 'document';
			} else if (mimeType.startsWith('application/pdf')) {
				return 'document';
			} else if (mimeType.startsWith('application/msword') || mimeType.startsWith(
					'application/vnd.openxmlformats-officedocument.wordprocessingml.')) {
				return 'document';
			} else if (mimeType.startsWith('application/vnd.ms-excel') || mimeType.startsWith(
					'application/vnd.openxmlformats-officedocument.spreadsheetml.')) {
				return 'spreadsheet';
			} else if (mimeType.startsWith('application/vnd.ms-powerpoint') || mimeType.startsWith(
					'application/vnd.openxmlformats-officedocument.presentationml.')) {
				return 'presentation';
			} else if (mimeType.startsWith('application/zip') || mimeType.startsWith(
					'application/x-rar-compressed') ||
				mimeType.startsWith('application/x-zip-compressed')) {
				return 'archive';
			} else if (mimeType.startsWith('application/json')) {
				return 'data';
			} else if (mimeType.startsWith('application/javascript')) {
				return 'code';
			} else if (mimeType.startsWith('application/xml')) {
				return 'code';
			} else if (mimeType.startsWith('multipart/form-data')) {
				return 'formData';
			} else {
				return 'unknown';
			}
		},

		// 点击是否关闭操作栏
		myFunction(e) {
			var that = this
			// console.log('e',e)
			if (this.AddShow == true && e.target._prevClass == 'overlay') {
				this.AddShow = false
				this.$overlay.hide();
			}
			if (this.optShow == true && e.target._prevClass == 'overlay') {
				this.openQrcode = false
				this.searchMessageShow = false
				this.optShow = false
				this.$overlay.hide();
			}
			if (e.target._prevClass == 'overlay') {
				this.auditShow = false
				this.openInfoFrame = false
				this.openInfoFrameEdit = false
				this.detailFrameShow = false
				this.verifyShow = false
				this.allMemberShow = false
				this.signInListShow = false
				this.$overlay.hide();
			}
		},
		// 判断是否图片还是视频
		isImgOfVideo(url) {
			var extension = url.split('.').pop().toLowerCase();
			// console.log('后缀',extension)
			if (this.imageExtensions.includes(extension)) {
				return 'image'
			}
			if (this.videoExtensions.includes(extension)) {
				return 'video'
			}
			return 'image'
		},
		// 检测数组是否为空
		isEmpty(obj) {
			return Object.keys(obj).length === 0 && obj.constructor === Object;
		},
		getTimes(t) {
			let h = parseInt(t / 60 / 60 % 24)
			let m = parseInt(t / 60 % 60)
			let s = parseInt(t % 60)
			//三元表达式 补零 如果小于10 则在前边进行补零 如果大于10 则不需要补零
			let data = {
				m: m,
				s: s
			}
			h = h < 10 ? '0' + h : h
			m = m < 10 ? '0' + m : m
			s = s < 10 ? '0' + s : s

			return data
		},
		/********时间格式化处理********/
		//使用事例 return top.dateFtt(crtTime,"yyyy/MM/dd hh:mm:ss");
		dateFtt(date, fmt) {
			date = new Date(date);
			var o = {
				"M+": date.getMonth() + 1, //月份   
				"d+": date.getDate(), //日   
				"h+": date.getHours(), //小时   
				"m+": date.getMinutes(), //分   
				"s+": date.getSeconds(), //秒   
				"q+": Math.floor((date.getMonth() + 3) / 3), //季度   
				"S": date.getMilliseconds() //毫秒   
			};
			if (/(y+)/.test(fmt))
				fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
			for (var k in o)
				if (new RegExp("(" + k + ")").test(fmt))
					fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[
							k])
						.length)));
			// console.log(fmt)
			return fmt;
		},
		// 实现深拷贝
		deepClone(source) {
			if (typeof source !== 'object' || source == null) {
				return source;
			}
			const target = Array.isArray(source) ? [] : {};
			for (const key in source) {
				if (Object.prototype.hasOwnProperty.call(source, key)) {
					if (typeof source[key] === 'object' && source[key] !== null) {
						target[key] = this.deepClone(source[key]);
					} else {
						target[key] = source[key];
					}
				}
			}
			return target;
		},
		/**
		 * 文件下载指定目录（background.js: 'src/assets/img'）
		 * @param {*} fileUrl 待下载文件url
		 * @param {*} fileName 文件保存名
		 * return 返回文件下载后所在路径
		 */
		async download(fileUrl, fileName) {
			const options = {
				url: fileUrl,
				filename: fileName
			};
			console.log('我进来了',window.electron)
			// this.downloadCode(fileUrl,fileName)
			// return 
			if(window.electron){
				window.electron.ipcRenderer.invoke('downloadFile',options).then(inputSources => {
				console.log('进来', inputSources);
				if (inputSources.success) {
					// this.$message({
					// 	message: '文件下载成功',
					// 	type: 'success'
					//   });
					//   window.electron.ipcRenderer.invoke('downloadFilePath').then(inputSources => {	
						
					//   })
				} else {
					// this.$message({
					// 	message: '文件下载失败',
					// 	type: 'warning'
					//   });
				}
			})
			} else {
				console.log('当前是网页下载')
				const oReq = new XMLHttpRequest()
				const URL = fileUrl // URL 为下载的URL地址
				oReq.open('GET', URL, true)
				oReq.responseType = 'blob'
				oReq.onload = function() {
					const file = new Blob([oReq.response], {
						type: 'blob'
					})
					
					FileSaver.saveAs(file, fileName) // fileName为文件名
				}
				oReq.send()
			}
			

		},

		// 点击下载代码的方法	
		async downloadCode(url,fileName,message) {
			// 定义全局变量percentComplete 为下载进度值（定义data此处忽略）
			
			// return 
		// if(message.MsgContent.isxiazai){
		// 	// console.log('下载中')
		// 	this.$message('已下载');
		// 	return 
		// }
		// console.log('url,fileName,message',url,fileName,message)
		fileName = fileName.replace(/\"/g, "");
		// return
		if(message.downloading){
			// console.log('下载中')
			this.$message('下载中');
			return 
		}
		
		message.percentComplete = 0
		message.downloading = true
		// axios调用下载接口,timeout可删掉，我这里是因为文件太大想延长请求时间
		axios({responseType: 'stream',method: 'get',url: url,
			// ,data: xxx, 
			// headers: { 'Authorization': Cookies.get('token') }, responseType: 'blob', timeout: 600000,                         
			// withCredentials: true,可配可不配，根据项目实际情况选择
			// 使用此方法监听
			onDownloadProgress:  (e) => {
				console.log('e:',e);
				// e是一个包含下载进度信息的事件对象，其中包括loaded 、total属性：
				message.percentComplete = Math.floor((e.loaded / e.total) * 100)
				console.log('进度',message.percentComplete)
			} })
			.then((res) => {
				// this.downloading = false
				message.downloading = false
				message.MsgContent.isxiazai = true
				let upd = `${JSON.stringify(message.MsgContent)}`
				this.msgUpdContent(message.MsgId, upd).then(rs => {
						console.log("通话更新消息：", rs);
					})
				if(window.electron){

				}else{
					const blob = new Blob([res.data], {type: 'application/octet-stream'}); //处理文档流
					console.log('blob',blob)
					// const fileName = res.headers['content-disposition'].split('filename=')[1];
					const elink = document.createElement("a");
					elink.download = fileName;
					elink.style.display = "none";
					elink.href = URL.createObjectURL(blob);
					document.body.appendChild(elink);
					elink.click();
					URL.revokeObjectURL(elink.href); // 释放URL 对象
					document.body.removeChild(elink);
				}
				
			}).finally(() => {
				this.spinVisible = false
			});
			if(window.electron){
				this.download(url,fileName)
			}
			
				
		},



		// 图标闪烁
		flicker(){
			if(window.electron){
				console.log('我是闪烁',window.electron)
				window.electron.ipcRenderer.invoke('flicker').then(inputSources => {	
					
				})
			}
		},
		// 图标闪烁取消
		cancelFlicker(){
			if(window.electron){
				console.log('我是闪烁',window.electron)
				window.electron.ipcRenderer.invoke('cancelFlicker').then(inputSources => {	
					
				})
			}
		},
		// 格式化时间戳
		formDataTime(time) {
			// console.log('time',time)
			// time = new Date(time);
			// time = time.getTime()
			// console.log('time2',time.getTime())
			// return 
			//当前年的最开始
			// console.log( dayjs().startOf('year').$d.valueOf());
			let yearStar = dayjs().startOf('year').$d.valueOf();

			//当前月的最开始
			// console.log(dayjs().startOf('month').$d.valueOf());
			let monthStar = dayjs().startOf('month').$d.valueOf();

			//当前周的最开始
			// console.log(dayjs().startOf('week').$d.valueOf());
			let weekStar = dayjs().startOf('week').$d.valueOf();

			//当前天的最开始
			// console.log(dayjs().startOf('day').$d.valueOf());
			let todayStar = dayjs().startOf('day').$d.valueOf();
			//昨天的最开始
			let yestodayStar = dayjs().startOf('day').$d.valueOf() - 1000 * 60 * 60 * 24;

			let currenttime;



			let ss = time;

			//超出今年的年份
			if (ss < yearStar) {
				//本身所在的年份 let ss = 1525492800000;
				//  console.log('本身所在的年份');
				let month = dayjs(ss).month() + 1
				let date = dayjs(ss).date()

				month >= 10 ? month = month : month = '0' + month
				date >= 10 ? date = date : date = '0' + date

				currenttime = `${dayjs(ss).year()}-${month}-${date}`
			}

			//在今年不在本月中
			if (ss > yearStar && ss < monthStar) {
				//当前年的日期 let ss = 1571760000000;
				//  console.log('当前年的日期');

				let month = dayjs(ss).month() + 1
				let date = dayjs(ss).date()

				month >= 10 ? month = month : month = '0' + month
				date >= 10 ? date = date : date = '0' + date
				currenttime = `${dayjs(ss).year()}-${month}-${date}`

			}

			//在本月不在本周
			if (ss > monthStar && ss < weekStar) {
				//当前月的日期 let ss = 1575302400000;
				//  console.log('当前月的日期');
				let month = dayjs(ss).month() + 1
				let date = dayjs(ss).date()

				month >= 10 ? month = month : month = '0' + month
				date >= 10 ? date = date : date = '0' + date

				currenttime = `${month}-${date}`
			}

			//在本周不在昨天
			if (ss > weekStar && ss < yestodayStar) {
				//周几  let ss = 1576987200000;
				//  console.log('周几');
				let weekObj = {
					0: '日',
					1: '一',
					2: '二',
					3: '三',
					4: '四',
					5: '五',
					6: '六'
				};
				let week = dayjs(ss).day()
				let weekValue = weekObj[week];

				let hour = dayjs(ss).hour()
				let minute = dayjs(ss).minute()
				hour >= 10 ? hour = hour : hour = '0' + hour
				minute >= 10 ? minute = minute : minute = '0' + minute

				// currenttime = `周${weekValue}-${hour}:${minute}`
				currenttime = `周${weekValue}`
			}

			//昨天的
			if (ss > yestodayStar && ss < todayStar) {
				let hour = dayjs(ss).hour()
				let minute = dayjs(ss).minute()
				hour >= 10 ? hour = hour : hour = '0' + hour
				minute >= 10 ? minute = minute : minute = '0' + minute
				currenttime = `昨天 ${hour}:${minute}`
			}

			//当天的
			if (ss > todayStar) {
				//时 + 分 let ss = 1577145600000;
				//  console.log('今天'); 
				let hour = dayjs(ss).hour()
				let minute = dayjs(ss).minute()
				hour >= 10 ? hour = hour : hour = '0' + hour
				minute >= 10 ? minute = minute : minute = '0' + minute
				currenttime = `${hour}:${minute}`
			}
			//  console.log(currenttime);
			return currenttime
		},
		//一天的开始时间
		startTime(time) {
			const nowTimeDate = new Date(time);
			return nowTimeDate.setHours(0, 0, 0, 0);
		},
		//一天的结束时间
		endTime(time) {
			const nowTimeDate = new Date(time);
			return nowTimeDate.setHours(23, 59, 59, 999);
		},
		// 监测当前可用的媒体设备
		checkMediaDevices() {

			var isMicrophone = 0 //麦克风
			var isCamera = 0 //摄像头
			var isSpeaker = 0 //扬声器

			try {
				navigator.mediaDevices.enumerateDevices().then(function(devices) {
					devices.forEach(function(device) {
						switch (device?.kind) {
							case 'audioinput':
								// console.log('音频输入设备(麦克风|话筒)：', device);
								isMicrophone = 1
								break;
							case 'audiooutput':
								// console.log('音频输出设备(扬声器|音响)：', device);
								isSpeaker = 1
								break;
							case 'videoinput':
								// console.log('视频输入设备(摄像头|相机)：', device);
								isCamera = 1
								break;
							default:
								// console.log('当前可用的媒体设备: ', device);
								break;
						}
					});
					localdic.set('isMicrophone', isMicrophone)
					localdic.set('isCamera', isCamera)
					localdic.set('isSpeaker', isSpeaker)


				}).catch(function(err) {
					console.error(err);
				});
			} catch (err) {
				console.error(err);
			} finally {
				if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
					console.log("不支持mediaDevices.enumerateDevices(), 未识别到多媒体设备！");
				}
			}
		},

	}
};