import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// 引入数据存储
import localdic from '@/util/dicHelper.js'
import messageStore from './modules/messageStore';

export default new Vuex.Store({
	state: {
		userId: 0,
		token: null,
		udata: null,
		chatData: null,
		isMicrophone: false,
		isCamera: false,
		isSpeaker: false,
		overlayPhoneShow: false,
	},

	// 组件通过this.$store.getters.getUserId
	getters: {
		getUserId(state) {
			return state.userId
		},
		getUserToken(state) {
			return state.token
		},
		getUserData(state) {
			return state.udata
		},
		getChatData(state) {
			return state.chatData
		},
		getisCamera(state) {
			return state.isCamera
		},
		getisMicrophone(state) {
			return state.isMicrophone
		},
		getisSpeaker(state) {
			return state.isSpeaker
		},
		getoverlayPhoneShow(state) {
			return state.overlayPhoneShow
		}
	},
	//组件调用this.$store.commit('SET_TOKEN','new token')挂载方法
	mutations: {
		SET_USERID(state, params) {
			state.userId = params
		},
		SET_TOKEN: (state, token) => {
			state.token = token
		},
		SET_UserData: (state, param) => {
			state.udata = param
		},
		SET_ChatData: (state, param) => {
			state.chatData = param
		},
		SET_IsCamera: (state, param) => {
			state.isCamera = param
		},
		SET_IsSpeaker: (state, param) => {
			state.isSpeaker = param
		},
		SET_IsMicrophone: (state, param) => {
			state.isMicrophone = param
		},
		SET_OverlayPhoneShow: (state, param) => {
			state.overlayPhoneShow = param
		}
	},
	// 组件中使用this.$store.dispatch('aEdit','new TOKEN')
	actions: {
		aEdit(context, payload) {
			return new Promise((resolve, reject) => {
				setTimeout(() => {
					context.commit('SET_TOKEN', payload)
					resolve()
				}, 2000)
			})
		}
	},

	modules: {
		messageStore
	}
})