<template>
	<div class="hello">
		<div class="frame" @click.stop=""  :style="{ '--box-color': boxColor }">
			<!-- 标题 -->
			<div class="flexal" style="padding-top: 15px;margin-left: 14px;margin-bottom: 10px;">
				<div class="people-title" v-if="answerShow">参与投票</div>
				<div class="people-title" v-else>投票</div>
				<img :src="require('@/assets/img/conference/575d63bbff7b7e64db25062a2d18892.png')"  style="width: 14px;height: 14px;margin-right: 26px;"  @click="closePop" />
			</div>

			<!-- 创建投票 -->
			<div v-if="createShow">
				<div class="create-frame"  :style="{ '--box-color1': boxColor1 }">
					<div class="flexal">
						<div class="create-text" style="width: 50%;">投票主题</div>
						<el-input v-model="cVote.Name" class="memberInput2" style="width: 50%;text-align: right;"
							placeholder="请输入投票主题"></el-input>
					</div>
					<div class="frame-line"></div>
					<div style="margin-top: 15px;">
						<div class="create-text">补充描述（选填）</div>
						<el-input type="textarea" :autosize="{ minRows:4, maxRows: 4}" style="margin-top: 10px;"
							placeholder="请输入内容" v-model="cVote.Content"></el-input>
					</div>
					<div class="frame-line"></div>

					<div style="margin-top: 10px;" v-for="(item,index) in cVote.problems " :key="index">
						<!-- <div class="flexal">
                    <div class="create-text" style="width: 50%;">问题{{ index+1 }}</div>
                    <el-input v-model="item.title"  class="memberInput2" style="width: 50%;text-align: right;" placeholder="请输入问题"></el-input>
                    </div> -->
						<div class="flexal" style="padding: 10px 10px; background: #F5F6F8;border-radius: 8px;">
							<el-input v-model="item.Title" class="memberInput4"
								style="width: 70%;text-align: left;flex: 1;" placeholder="填输入问题"></el-input>
							<img :src="require('@/assets/img/conference/d4e77c585c13043570678b1caee619e.png')"
								style="width: 13px;height: 13px;" @click="deleteIssue(index)" />
						</div>
						<div class="frame-line"></div>
						<div style="margin-top: 10px;" v-for="(items,indexs) in  item.child" :key="indexs">
							<div class="flexal">
								<el-input v-model="items.Title" class="memberInput3"
									style="width: 70%;text-align: left;flex: 1;" placeholder="填写选项"></el-input>
								<img :src="require('@/assets/img/conference/db6f3498d9a9627e050172a1dcd5d0a.png')"
									style="width: 13px;height: 13px;" @click="deleteAnswer(index,indexs)" />
							</div>
							<div class="frame-line"></div>
						</div>
						<div class="flexal create-add-text" @click="addAnswer(index)">
							+ 添加选项
						</div>
						<div class="frame-line"></div>
					</div>
				</div>

				<div class="frame-buttom flexcen" style="height: 40px;margin-top: 20px;">
					<div class="frame-button-add flexcen" @click="addIssue">+ 添加问题</div>
				</div>

				<div class="frame-buttom" style="height: 20px;margin-top: 20px;margin-left: 16px;">
					<el-checkbox v-model="checked" @change="nimingChangeFun">匿名投票</el-checkbox>
				</div>
			</div>

			<!-- 投票进度 -->
			<div class="progress-frame" v-if="progressShowEdit" :style="{'height': cVote.ProblemId==0||voteInfo.isuser ? boxColor2:boxColor3}" >
				<div class="create-text">{{cVote.Name}}</div>
				<div class="frame-desc" style="margin-top: 5px;">{{cVote.Content}}</div>
				<div class="flexal" style="margin-top: 5px;margin-bottom: 2px;">
					<div class="frame-desc" style="font-size: 11px;">
						<span style="margin-right: 10px;" v-if="cVote.State>0">{{voteInfo.votecount}}个人已投</span>
						{{cVote.IsAnonymous>0?'匿名':''}}
						<span
							:style="{'color':cVote.State>0? '#2BA471':'#D54941','margin-left': '10px'}">{{cVote.State>0? '进行中':'未开始'}}</span>
					</div>
				</div>
				<div class="frame-line"></div>

				<div style="margin-top: 15px;" v-for="(item,index) in cVote.problems" :key="index">
					<div class="create-text">{{item.Title}}</div>
					<div style="margin-top: 12px;" v-for="(items,indexs) in item.child" :key="indexs">
						<div class="flexal">
							<div class="create-text" style="font-size: 13px;flex: 1;">{{items.Title}}</div>
							<div class="create-text" style="font-size: 12px;" v-if="cVote.State>0">
								{{items.total}}票
								{{ voteInfo.votecount>0? (items.total/voteInfo.votecount*100).toFixed(0):0 }}%
							</div>
						</div>
						<el-progress style="margin-top: 10px;"
							:percentage="(cVote.State>0&&voteInfo.votecount>0?items.total/voteInfo.votecount*100:0)"
							:show-text="false"></el-progress>
					</div>
				</div>
			</div>

			<div class="progress-frame" v-if="answerShow" :style="{ 'height': boxColor2 }">
				<div class="create-text">{{cVote.Name}}</div>
				<div class="frame-desc" style="margin-top: 5px;">{{cVote.Content}}</div>
				<div class="flexal" style="margin-top: 5px;margin-bottom: 2px;">
					<div class="frame-desc" style="font-size: 11px;"> <span style="margin-right: 10px;"
							v-if="cVote.State>0">{{voteInfo.votecount}}个人已投</span> {{cVote.IsAnonymous>0?'匿名':''}}
						<span
							:style="{'color':cVote.State>0? '#2BA471':'#D54941','margin-left': '10px'}">{{cVote.State>0? '进行中':'未开始'}}</span>
					</div>
				</div>
				<div class="frame-line"></div>

				<div style="margin-top: 15px;" v-for="(item,index) in cVote.problems " :key="index">
					<div class="create-text">{{item.Title}}</div>
					<el-radio-group v-model="item.radio">
						<div style="margin-top: 12px;" v-for="(items,indexs) in item.child" :key="indexs">
							<el-radio :label="items.SelectionId">{{items.Title}}</el-radio>
							<!-- <el-radio :label="6">备选项</el-radio>
                        <el-radio :label="9">备选项</el-radio> -->
						</div>
					</el-radio-group>
				</div>
			</div>
			<div class="frame-buttom"
				style="display: flex;justify-content: flex-end;padding-right: 16px;align-items: center;">
				<!-- <div class="fram-button flexcen">创建投票</div> -->
				<div class="fram-button flexcen" style="width: 122px;margin-left: 15px;" v-if="createShow"
					@click="create">创建投票</div>

				<div v-if="progressShowEdit" class="flexal">
					<div class="fram-button flexcen" v-if="cVote.ProblemId==0||voteInfo.isuser" @click="delVote()"
						style="width: 107px;border: 1px solid #D54941;background: #FFFFFF;color: #D54941;">删除</div>
					<div v-if="!progressShow" class="flexal">
						<div class="fram-button flexcen"
							style="width: 107px;margin-left: 15px;background: #FFFFFF;color: #0052D9;border: 1px solid #0052D9;"
							@click="edit">编辑</div>
						<div class="fram-button flexcen" style="width: 107px;margin-left: 15px;" @click="subimtVote()">
							发起投票</div>
					</div>
					<div class="flexal" v-else>
						<div class="fram-button flexcen"
							style="width: 107px;margin-left: 15px;background: #FFFFFF;color: #0052D9;border: 1px solid #0052D9;"
							v-if="!isAnswer" @click="joinAnswer">参与投票</div>
						<div class="fram-button flexcen" style="width: 107px;margin-left: 15px;" v-if="voteInfo.isuser"
							@click="stopVote()">终止投票</div>
					</div>
				</div>

				<div class="fram-button flexcen" style="width: 122px;margin-left: 15px;" v-if="answerShow"
					@click="subimtAnswer">提交</div>

			</div>
		</div>
		<!-- 是否终止投票弹窗 -->
		<detelePop :dialogVisibleShow="dialogVisibleDelete" :type="2" @updateDialog="updateDialog"></detelePop>
	</div>
</template>

<script>
	import detelePop from '../pop-up/detelePop.vue'
	export default {
		name: 'voteConferencePop',
		props: {
			roomId: {
				type: Number,
				default: 0
			},
			isfullscreen:{
				type:Boolean,
				default:false
			}
		},
		watch: {
			roomId() {
				console.log('watch-this.roomId', this.roomId)
				this.loadvote()
			},
			isfullscreen(newValue){
				// console.log('我是全屏',newValue)
				if(newValue){
					this.boxColor = '95vh'
					this.boxColor1 = '67vh'
					this.boxColor2 = '77vh'
					this.boxColor3 = '87vh'
				}else{
					this.boxColor = '82vh'
					this.boxColor1 = '54vh'
					this.boxColor2 = '64vh'
					this.boxColor3 = '74vh'
				}
			}
		},
		data() {
			return {
				boxColor:'82vh',
				boxColor1:'54vh',
				boxColor2:'64vh',
				boxColor3:'74vh',
				cVote: {
					ProblemId: 0,
					Name: '', //投票主题
					Content: '', //描述
					RoomId: 0,
					IsAnonymous: 0, //是否匿名 1-是
					State: 0, //状态 0-未开始 1-进行中 2- 已结束
					problems: [] //问题
				},

				input: '', //投票主题
				content: '', //描述
				checked: false, //是否匿名
				createShow: true, //创建页面
				progressShowEdit: false, //投票进度页面状态(未开始/进行中)
				// NoprogressShow:true,     //投票进度页面(未开始)
				progressShow: false, //投票进度页面
				answerShow: false, //答题页面

				isAnswer: false, //是否已答题
				voteInfo: {},

				// 问题列表
				list: []
			}
		},
		components: {
			detelePop
		},
		mounted() {
			console.log('mounted-this.roomId', this.roomId)
			this.loadvote()
		},
		methods: {
			//加载判断进行中的投票
			async loadvote() {
				var data = {
					RoomId: this.roomId
				}
				var res = await this.$request.post('/meeting/getVote', data)
				if (res.code == 200) {
					//是否存在进行中的投票
					var vid = res.data
					if (vid > 0) {
						this.createShow = false
						this.getdetail(vid)
					} else {
						this.createShow = true
					}
				}
			},
			//加载投票信息
			async getdetail(vid) {
				var data = {
					ProblemId: vid
				}
				var res = await this.$request.post('/meeting/voteDetail', data)
				if (res.code == 200) {
					this.cVote = res.data.vote
					this.voteInfo = res.data
					this.isAnswer = this.voteInfo.isvote
					this.progressShowEdit = true
					this.progressShow = true
					this.answerShow = false
				}
			},
			async subimtVote() {
				//提交投票
				this.cVote.State = 1;
				this.cVote.RoomId = this.roomId;
				var data = this.cVote
				var res = await this.$request.post('/meeting/crateVote', data)
				if (res.code == 200) {
					this.$message.success('成功')
					this.progressShow = false
					this.getdetail(res.data)
				}
			},
			nimingChangeFun() {
				this.cVote.IsAnonymous = (this.checked ? 1 : 0)
			},
			submit(index) {
				this.tabIndex = index
				this.$emit('workNoticeSubmit', index)
			},
			// 添加弹窗回调
			updateDialogClick() {
				this.dialogVisibleMember = false
			},
			// 添加问题
			addIssue() {
				let data = {
					Title: '', //问题内容
					child: [{
						Title: ''
					}, {
						Title: ''
					}]
				}
				let list = this.cVote.problems
				list.push(data)
				this.cVote.problems = list
			},
			// 添加选项
			addAnswer(index) {
				let data = {
					Title: ''
				}
				let list = this.cVote.problems
				list[index].child.push(data)
				this.cVote.problems = list
			},
			//删除问题
			deleteIssue(index) {
				let list = this.cVote.problems
				list.splice(index, 1);
				this.cVote.problems = list
			},
			// 删除选项
			deleteAnswer(index, indexs) {
				let list = this.cVote.problems
				list[index].child.splice(indexs, 1);
				this.cVote.problems = list
			},
			// 弹窗关闭回调
			updateDialog() {
				// console.log('回调了')
				this.dialogVisibleDelete = false
			},
			// 创建投票
			create() {
				if (!this.cVote.Name || this.cVote.Name == '') {
					this.$message.error('投票主题不能为空')
					return
				}
				if (this.cVote.problems.length < 1) {
					this.$message.error('投票问题不能为空')
					return
				}
				for (var index = 0; index < this.cVote.problems.length; index++) {
					var it = this.cVote.problems[index]
					if (!it.Title || it.Title == '') {
						this.$message.error(`问题${index+1}-标题不能为空`)
						return
					}
					for (var aindex = 0; aindex < it.child.length; aindex++) {

						var a = it.child[aindex]
						if (!a.Title || a.Title == '') {
							this.$message.error(`问题${index+1}-的选项${aindex+1}不能为空`)
							return
						}
					}
				}

				this.createShow = false //创建页面
				this.progressShowEdit = true //投票进度页面状态(未开始/进行中)
			},
			// 编辑投票
			edit() {
				this.createShow = true //创建页面
				this.progressShowEdit = false //投票进度页面状态(未开始/进行中)
			},
			// 参与投票
			joinAnswer() {
				this.answerShow = true //答题页面
				this.progressShowEdit = false //投票进度页面状态(未开始/进行中)
			},
			// 提交问题
			async subimtAnswer() {

				var checkids = []

				for (var index = 0; index < this.cVote.problems.length; index++) {
					var it = this.cVote.problems[index]
					if (it.radio && it.radio != '') checkids.push({
						SelectionId: it.radio
					})

				}
				console.log('checkids', checkids)

				if (checkids.length < this.cVote.problems.length) {
					this.$message.error('有未选择的问题')
					return
				}
				var data = {
					ProblemId: this.cVote.ProblemId,
					problems: checkids
				}
				var res = await this.$request.post('/meeting/vserVote', data)
				if (res.code == 200) {
					this.$message.success('成功')
					this.answerShow = false //答题页面
					this.progressShowEdit = true //投票进度页面状态(未开始/进行中)
					this.isAnswer = true
					this.getdetail(this.cVote.ProblemId)
				}

			},
			//停止投票
			stopVote() {
				this.$confirm(`投票终止后参会者将无法继续投票。是否继续?`, {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					var data = {
						ProblemId: this.cVote.ProblemId,
						State: 2
					}
					var res = await this.$request.post('/meeting/overVote', data)
					if (res.code == 200) {
						this.$message.success('成功')
						// this.createShow = true
						// this.$forceUpdate()
						this.initParams()
					}

				}).catch(err => {
					// console.error(err)
				})
			},
			//删除投票
			delVote() {
				if (this.cVote.ProblemId > 0) {
					this.$confirm(`投票删除后参会者将无法查看投票。是否继续?`, {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(async () => {
						var data = {
							ProblemId: this.cVote.ProblemId,
							State: 99
						}
						var res = await this.$request.post('/meeting/overVote', data)
						if (res.code == 200) {
							this.$message.success('成功')
							this.initParams()
						}

					}).catch(err => {
						// console.error(err)
					})
				}
			},
			// 关闭页面
			closePop(){
				this.$emit('closePop')
			},
			// 初始化数据
			initParams(){
				this.input = '', //投票主题
				this.content = '', //描述
				this.checked = false, //是否匿名
				this.createShow = true, //创建页面
				this.progressShowEdit = false, //投票进度页面状态(未开始/进行中)
				// NoprogressShow:true,     //投票进度页面(未开始)
				this.progressShow = false, //投票进度页面
				this.answerShow = false, //答题页面
				this.isAnswer = false, //是否已答题
				this.voteInfo = {},
				// 问题列表
				this.list = []
				// 问题列表
				this.cVote = {
					ProblemId: 0,
					Name: '', //投票主题
					Content: '', //描述
					RoomId: 0,
					IsAnonymous: 0, //是否匿名 1-是
					State: 0, //状态 0-未开始 1-进行中 2- 已结束
					problems: [] //问题
				}
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	.frame {
		// margin-left: -5px;
		width: 436px;
		// height: 847px;
		// height: 747px;
		height: var(--box-color);
		background: #FFFFFF;
		border-radius: 0px 16px 16px 0px;
		box-shadow: 0px 3px 26px 1px rgba(0, 0, 0, 0.16);
		// display: flex;
		// flex-direction: column;

		.people-title {
			// margin-left: 12px;
			font-size: 18px;
			font-weight: 500;
			color: #333333;
			flex: 1;
		}

		.create-frame {
			padding: 0px 16px;
			// background: #000;
			// height: 620px;
			// height: 520px;
			// display: flex;
			// flex-direction: column;
			height: var(--box-color1);
			overflow: auto;
			// flex: 1;
		}

		.frame-buttom {
			height: 80px;
		}

		.fram-button {
			width: 107px;
			height: 41px;
			background: #0052D9;
			border-radius: 16px 16px 16px 16px;
			// border: 1px solid #858A99;
			font-size: 14px;
			color: #FFFFFF;
		}

		.create-text {
			font-weight: 400;
			font-size: 15px;
			color: #000000;
		}

		.create-add-text {
			margin-top: 10px;
			font-weight: 400;
			font-size: 12px;
			color: #366EF4;
			cursor: pointer;
		}

		.frame-button-add {
			width: 122px;
			height: 39px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			border: 1px solid #0052D9;
			font-size: 16px;
			color: #0052D9;
		}

		.progress-frame {
			padding: 0px 18px;
			// height: 715px;
			// height: 615px;
			// height: 64vh;
			// background: #000;
			overflow: auto;
		}

		.frame-desc {
			font-weight: 400;
			font-size: 14px;
			color: #6E6E6E;
		}
	}

	.imgIcon {
		width: 19px;
		height: 19px;
	}

	.frame-line {
		margin-top: 10px;
		// width: 402px;
		width: 100%;
		height: 0px;
		border: 1px solid #707070;
		opacity: 0.17;
	}


	/deep/.memberInput2 .el-input__inner {
		//   width: 50%;
		text-align: right;
		height: 20px;
		background: #FFFFFF;
		padding: 0px 0px;
		//   border-radius: 8px;
		border: 0px solid #B0B1B4;
	}

	/deep/.memberInput3 .el-input__inner {
		//   width: 50%;
		text-align: left;
		padding: 0px 0px;
		height: 20px;
		background: #FFFFFF;
		font-size: 13px;
		border: 0px solid #B0B1B4;
	}

	/deep/.memberInput2 .el-input__inner::placeholder {
		color: #6E6E6E;
		// text-align:center;
	}

	/deep/.el-textarea__inner {
		// background: #F1F1F1;
		// min-height: 214px;
		padding: 0px 0px;
		border: 0px solid #B0B1B4;
	}

	/deep/.memberInput4 .el-input__inner {
		//   width: 50%;
		text-align: left;
		padding: 0px 0px;
		height: 20px;
		background: #F5F6F8;
		font-size: 13px;
		border: 0px solid #B0B1B4;
		border-radius: 8px;
	}

	/deep/.el-checkbox__inner {
		width: 21px;
		height: 21px;
		border: 2px solid #9B9B9B;
		border-radius: 50%;
	}

	/deep/.el-checkbox__label {
		padding-left: 5px;
	}

	/* 对勾样式 */
	/deep/.el-checkbox__inner::after {
		border: 2px solid #fff;
		border-left: 0;
		border-top: 0;
		left: 6px;
		top: 3px;
	}
</style>