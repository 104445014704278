<template>
    <div class="hello">
        <el-dialog :visible.sync="videoDialog" width="70%"  center  class="videoPlayer" @close="closeDialog">
      <div>
        <video-player
          style="width: 100%; height: 500px"
          class="video-player vjs-custom-skin"
          ref="videoPlayer"
          :playsline="false"
          :options="playerOptions"
          @play="onPlayerPlay($event)"
          @pause="onPlayerPause($event)"
          @ended="onPlayerEnded($event)"
          @waiting="onPlayerWaiting($event)"
          @playing="onPlayerPlaying($event)"
          @loadeddata="onPlayerLoadeddata($event)"
          @timeupdate="onPlayerTimeupdate($event)"
          @canplay="onPlayerCanplay($event)"
          @canplaythrough="onPlayerCanplaythrough($event)"
          @statechanged="playerStateChanged($event)"
          @ready="playerReadied"
        ></video-player>
      </div>
    </el-dialog>

    </div>
  </template>
  
  <script>
    import { videoPlayer } from "vue-video-player";
    import "video.js/dist/video-js.css";

  export default {
    name: 'videoPlayerPop',
    props: {
      // 是否显示弹窗
     dialogVisibleShow: {
           type: Boolean,
           default: false
       },
       // 视频路径
     VideoUrl: {
           type: String,
           default: ''
       },
    },
    data(){
        return{
            videoDialog:false,
            playerOptions: {
            playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
            autoplay: false, // 如果true,浏览器准备好时开始回放。
            controls: true, //  控制条
            muted: false, // 默认情况下将会消除任何音频。
            loop: false, // 导致视频一结束就重新开始。
            preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
            language: "zh-CN",
            aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
            fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
            sources: [
            {
                type: "video/mp4", // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
                src: "", // url地址
            },
            ],
            // poster: "../../../../static/full_res.jpg", // 你的封面地址
            width: document.documentElement.clientWidth, // 播放器宽度
            notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
            controlBar: {
            timeDivider: true,
            durationDisplay: true,
            remainingTimeDisplay: false,
            fullscreenToggle: true, // 全屏按钮
            },
        },
        }
    },
    computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
  },

    watch: {
   // New: 新值 | Old: 老值
   dialogVisibleShow(New, Old) {
     this.videoDialog = New
    //  console.log(`新值:${New} ——— 老值:${Old}`)
   },
   VideoUrl(New, Old) {
    //  console.log('视频路径',New)
     this.playerOptions.sources[0].src = New;
     console.log(this.playerOptions)
   },
   },
   
    methods:{
    // 关闭弹窗回调
    closeDialog(){
        // console.log('我被取消了',this.dialogVisibleMember)
        this.$emit('updateDialog')
        this.player.pause();
    },
    openVideo(row) {
    //   this.videoDialog = true;
    //   this.row = row;
    //   this.playerOptions.sources[0].src = row.filesLoc;
    },
    // 播放回调
    onPlayerPlay(player) {
        console.log('播放回调',player)
    },

    // 暂停回调
    onPlayerPause(player) {
    },

    // 视频播完回调
    onPlayerEnded($event) {},

    // DOM元素上的readyState更改导致播放停止
    onPlayerWaiting($event) {},

    // 已开始播放回调
    onPlayerPlaying($event) {},

    // 当播放器在当前播放位置下载数据时触发
    onPlayerLoadeddata($event) {},

    // 当前播放位置发生变化时触发。
    onPlayerTimeupdate($event) {},

    //媒体的readyState为HAVE_FUTURE_DATA或更高
    onPlayerCanplay(player) {
    },

    //媒体的readyState为HAVE_ENOUGH_DATA或更高。这意味着可以在不缓冲的情况下播放整个媒体文件。
    onPlayerCanplaythrough(player) {
    },

    //播放状态改变回调
    playerStateChanged(playerCurrentState) {},

    //将侦听器绑定到组件的就绪状态。与事件监听器的不同之处在于，如果ready事件已经发生，它将立即触发该函数。。
    playerReadied(player) {},

    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    .video-js .vjs-icon-placeholder {
    width: 80%;
    height: 80%;
    display: block;
    }
    .videoPlayer /deep/ .el-dialog,
    .el-pager li {
    background: none !important;
    margin-top: 6vh !important;
    box-shadow:0 1px 3px rgba(0,0,0,0);
    }
    .my-img:hover{
    cursor:pointer;
    }

  </style>
  