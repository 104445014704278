<template>
	<div class="hello">
		<div class="frame" v-if="tab == 0">
			<div class="flexal" style="padding-top: 15px;">
				<div class="frame-title" style="flex: 1;">{{ title }}</div>
				<!-- <div class="block" style="margin-right: 55px;">
					<el-date-picker
					v-model="value1"
					type="daterange"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期">
					</el-date-picker>
				</div> -->
			</div>
			<!-- <div class="frame-title">{{ title1}}</div> -->
			<div class="flexcumal" style="height: 650px;overflow: auto;">
				<div v-if="type == 0" style="display: flex;flex-direction: column;">
					<div style="width: 794px;margin-top: 15px;">
						<!-- <div class="frame-text">任务名称</div>
						<el-input type="textarea" :autosize="{ minRows: 3, maxRows: 3}" placeholder="请输入"
							v-model="from.inputName"></el-input> -->
						<div class="frame-text">已完成：</div>
						<quill-editor ref="myQuillEditor" v-model="from.inputName" class="editor" :options="editorOption" @focus="quillEditorFocus($event,'myQuillEditor')"/>
					</div>
					<div style="width: 794px;margin-top: 15px;">
						<!-- <div class="frame-text">问题描述</div>
						<el-input type="textarea" :autosize="{ minRows: 3, maxRows: 3}" placeholder="请输入"
							v-model="from.inputDesc"></el-input> -->
						<div class="frame-text">未完成：</div>
						<quill-editor ref="myQuillEditor1" v-model="from.inputDesc" class="editor" :options="editorOption" @focus="quillEditorFocus($event,'myQuillEditor1')"/>
					</div>
					<div style="width: 794px;margin-top: 15px;">
						<!-- <div class="frame-text">解决方案</div>
						<el-input type="textarea" :autosize="{ minRows: 3, maxRows: 3}" placeholder="请输入"
							v-model="from.inputOpts"></el-input> -->
						<!-- <div class="frame-text">{{logtype ==4 ? title:'计划'}}：</div> -->
						<div class="frame-text">计划：</div>
						<quill-editor ref="myQuillEditor2" v-model="from.inputOpts" class="editor" :options="editorOption" @focus="quillEditorFocus($event,'myQuillEditor2')"/>
					</div>
				</div>
				<div v-if="type == 1" style="display: flex;flex-direction: column;">
					<div style="width: 794px;margin-top: 15px;">
						<div class="frame-text">计划名称：</div>
						<quill-editor ref="myQuillEditor" v-model="from.inputName" class="editor" :options="editorOption" @focus="quillEditorFocus($event,'myQuillEditor')"/>
					</div>
					<div style="width: 794px;margin-top: 15px;">
						<div class="frame-text">计划描述：</div>
						<quill-editor ref="myQuillEditor1" v-model="from.inputDesc" class="editor" :options="editorOption" @focus="quillEditorFocus($event,'myQuillEditor1')"/>
					</div>
					<!-- <div style="width: 794px;margin-top: 15px;">
						<div class="frame-text">计划：</div>
						<quill-editor ref="myQuillEditor2" v-model="from.inputOpts" class="editor" :options="editorOption" @focus="quillEditorFocus($event,'myQuillEditor2')"/>
					</div> -->
				</div>
				<div v-if="type == 2" style="display: flex;flex-direction: column;">
					<div style="width: 794px;margin-top: 15px;">
						<div class="frame-text">任务名称：</div>
						<quill-editor ref="myQuillEditor" v-model="from.inputName" class="editor" :options="editorOption" @focus="quillEditorFocus($event,'myQuillEditor')"/>
					</div>
					<div style="width: 794px;margin-top: 15px;">
						<div class="frame-text">任务描述：</div>
						<quill-editor ref="myQuillEditor1" v-model="from.inputDesc" class="editor" :options="editorOption" @focus="quillEditorFocus($event,'myQuillEditor1')"/>
					</div>
					<!-- <div style="width: 794px;margin-top: 15px;">
						<div class="frame-text">计划：</div>
						<quill-editor ref="myQuillEditor2" v-model="from.inputOpts" class="editor" :options="editorOption" @focus="quillEditorFocus($event,'myQuillEditor2')"/>
					</div> -->
				</div>
				<!-- <div style="width: 794px;margin-top: 15px;" v-if="type == 0">
					<div class="frame-text">解决方案</div>
					<el-input type="textarea" :autosize="{ minRows: 3, maxRows: 3}" placeholder="请输入"
						v-model="from.inputOpts"></el-input>
				</div> -->
				<!-- <div style="width: 794px;margin-top: 15px;" v-if="type == 0">
					<div class="frame-text">心得体会</div>
					<el-input type="textarea" :autosize="{ minRows: 3, maxRows: 3}" placeholder="请输入"
						v-model="from.inputExpe"></el-input>
				</div> -->
				<!-- <div style="width: 794px;margin-top: 15px;" v-if="type == 0">
					<div class="frame-text">其他</div>
					<el-input type="textarea" :autosize="{ minRows: 1, maxRows: 3}" placeholder="请输入"
						v-model="from.inputOthe"></el-input>
				</div> -->
				<div style="width: 794px;margin-top: 15px;">
						<div class="frame-text" style="margin-bottom: 5px">时间：</div>
						<el-date-picker v-model="value1" value-format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
				</div>
				<div style="width: 794px;margin-top: 20px;" class="flexal">
					<div class="frame-text" style="flex: 1;">发送到人</div>
					<img :src="require('@/assets/img/log/add.png')" style="width: 25px;height: 25px;"
						@click="handleusers()" />
				</div>
				
				<div class="flexal" style="width: 794px;margin-top: 10px;">
					<template v-for="(item,index) in list">
						<div class="flexcumal" style="margin-left: 14px;" :key="index" v-if="item.checked">
							<div style="display: flex;">
								<img :src="item.Avatar" style="width: 38px;height: 38px;border-radius: 50%;" />
								<img :src="require('@/assets/img/log/close.png')"
									style="width: 14px;height: 14px;position: relative;margin-left: -10px;"
									@click="item.checked = !item.checked" />
							</div>
							<div class="frame-text-name">{{item.UserName}}</div>
						</div>
					</template>
				</div>
				<div style="width: 10px;height: 10px;display: flex;margin-bottom: 60px;" class="flexal"></div>
			</div>
			<div style="width: 100%;display: flex;justify-content: flex-end;">
				<div @click="handleaddlog()" class="frame-button flexcen" style="margin-right: 53px;margin-top: 10px;">
					{{logtype == 4 || logtype == 3 ? '提交':'提交日志'}}</div>
			</div>
		</div>
		<div class="frame" v-if="tab == 1">
			<div class="frame-title" style="padding-top: 15px;">查看日志</div>
			<div style="height: 700px;overflow: auto;margin-top: 28px;padding: 0px 17px;">
				<div class="flexal">
					<img :src="logview.workuser.Avatar" style="width: 42px;height: 42px;border-radius: 50%;" />
					<div style="margin-left: 15px;">
						<div style="font-weight: 500;font-size: 16px;">{{logview.workuser.UserName}}的工作{{WorkTypeDesc(logview.sdata.WorkType)}}</div>
						<div style="font-weight: 400;font-size: 11px;color: #878787;">{{logview.sdata.CreateTime}}</div>
					</div>
				</div>
				<div v-if="logview.sdata.WorkType == 0 ||logview.sdata.WorkType == 1||logview.sdata.WorkType == 2">
				<div class="frame-content-text" style="margin-top: 13px;">
					 已完成：
					<!-- <span>{{logview.sdata.Content}}</span> -->
				</div>
				<div class="frame-content-text" v-html="logview.sdata.Content"></div>
				<div class="frame-content-text" style="margin-top: 10px;">
					未完成：
					<!-- <span>{{logview.sdata.Content1}}</span> -->
				</div>
				<div class="frame-content-text" v-html="logview.sdata.Content1"></div>

				<div class="frame-content-text" style="margin-top: 10px;"> 计划： </div>
				<div class="frame-content-text" v-html="logview.sdata.Content2"></div>
				</div>

				<div v-if="logview.sdata.WorkType == 3">
				<div class="frame-content-text" style="margin-top: 13px;">
					 计划名称：
					<!-- <span>{{logview.sdata.Content}}</span> -->
				</div>
				<div class="frame-content-text" v-html="logview.sdata.Content"></div>
				<div class="frame-content-text" style="margin-top: 10px;">
					计划描述：
					<!-- <span>{{logview.sdata.Content1}}</span> -->
				</div>
				<div class="frame-content-text" v-html="logview.sdata.Content1"></div>

				<!-- <div class="frame-content-text" style="margin-top: 10px;"> 计划： </div>
				<div class="frame-content-text" v-html="logview.sdata.Content2"></div> -->
				</div>

				<div v-if="logview.sdata.WorkType == 4">
				<div class="frame-content-text" style="margin-top: 13px;">
					 任务名称：
					<!-- <span>{{logview.sdata.Content}}</span> -->
				</div>
				<div class="frame-content-text" v-html="logview.sdata.Content"></div>
				<div class="frame-content-text" style="margin-top: 10px;">
					任务描述：
					<!-- <span>{{logview.sdata.Content1}}</span> -->
				</div>
				<div class="frame-content-text" v-html="logview.sdata.Content1"></div>

				<!-- <div class="frame-content-text" style="margin-top: 10px;"> 计划： </div>
				<div class="frame-content-text" v-html="logview.sdata.Content2"></div> -->
				</div>
				<!-- <div class="frame-content-text" style="margin-top: 10px" v-if="logview.sdata.Content2&&logview.sdata.Content2!=''">
					解决方案：<span>{{logview.sdata.Content2}}</span>
				</div> -->
				<!-- <div class="frame-content-text" v-if="logview.sdata.Content3&&logview.sdata.Content3!=''">
					心得体会：<span>{{logview.sdata.Content3}}</span>
				</div> -->
				<!-- <div class="frame-content-text" v-if="logview.sdata.Content4&&logview.sdata.Content4!=''">
					其它：<span>{{logview.sdata.Content4}}</span>
				</div> -->
				<div class="frame-content-text" style="margin-top: 10px;">
					发送人员：<span v-for="(item,index) in logview.ulist" :key="index">{{item.UserName}} {{logview.ulist.length == index+1? '':'，'}} </span>
				</div>
			</div>
			<!-- <div style="width: 100%;display: flex;justify-content: flex-end;">
				<div class="frame-button flexcen" style="margin-right: 53px;margin-top: 10px;">写日志</div>
			</div> -->
		</div>
		<el-upload
			class="avatar-uploader-img"
			action="#"
			:show-file-list="false"
			:http-request="sendmsgbyUpload"
			:before-upload="beforeUploadImg"
			:on-error="uploadImgError"
			:data="{'pathName':''}"
		/>
		<!-- 添加群成员弹窗 -->
		<memberPop :type="5" :key="refreshKey" :dialogVisibleShow="dialogVisibleMember" @updateDialog="updateDialogClick" @CloseUpdateDialog="CloseUpdateDialog" :list="list" :dialogTitle="'发送人'"></memberPop>
	</div>
</template>

<script>
	import memberPop from '../pop-up/memberPop.vue'
	import localdic from '@/util/dicHelper.js'
	// 工具栏配置
	const toolbarOptions = [
		['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
		['blockquote', 'code-block'], // 引用  代码块
		[{ header: 1 }, { header: 2 }], // 1、2 级标题
		[{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
		[{ script: 'sub' }, { script: 'super' }], // 上标/下标
		[{ indent: '-1' }, { indent: '+1' }], // 缩进
		// [{'direction': 'rtl'}],                         // 文本方向
		[{ size: ['small', false, 'large', 'huge'] }], // 字体大小
		[{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
		[{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
		[{ font: [] }], // 字体种类
		[{ align: [] }], // 对齐方式
		['clean'], // 清除文本格式
		// ['link', 'image', 'video'] // 链接、图片
		['link', 'image'] // 链接、图片
		]
	export default {
		name: 'workNotcie',
		props: {
			msg: {
				type: String,
				default: ''
			},
			// 日志类型 0-日报 1-周报 2-月报 3-自定义 4-工作分配
			logtype: {
				type: Number,
				default: 0
			},
			//   类型 0:日报（周报/月报） 1:工作计划  2：工作分配
			type: {
				type: Number,
				default: 0
			},
			//   导航栏下标 0:工作日志  1:查看日志
			tab: {
				type: Number,
				default: 0
			},
			logId: {
				type: Number,
				default: 0
			}
		},
		watch: {
			tab(newval) {
				if (newval == 1) {
					console.log('查看日志')
				}
			},
			logId(newval) {
				if (newval > 0) {
					this.getlogdetail()
				}
			},
			logtype(newValue){
				console.log('数据变化',newValue)
				if(newValue == 0){
					this.title = '日报'
				}else if(newValue == 1){
					this.title = '周报'
				}else if(newValue == 2){
					this.title = '月报'
				}else if(newValue == 3){
					this.title = '工作计划'
				}else if(newValue == 4){
					this.title = '工作分配'
				}
				this.from = {
					input: '',
					inputName: '',
					inputDesc: '',
					inputOpts: '',
					inputExpe: '',
					inputOthe: ''
				}
			}
		},
		data() {
			return {
				editorOption: { // 编辑框操作事件
				theme: 'snow', // or 'bubble'
				placeholder: '请输入内容...',
				modules: {
				toolbar: {
					container: toolbarOptions,
					handlers: {
					image: function(value) { // 上传图片
						if (value) {
						document.querySelector('.avatar-uploader-img input').click() // 触发input框选择文件
						} else {
						this.quill.format('image', false)
						}
					},
					link: function(value) { // 添加链接
						if (value) {
						var href = prompt('请输入链接地址：')
						this.quill.format('link', href)
						} else {
						this.quill.format('link', false)
						}
					}
					}
				}
				}
				},
				title:'日报',
				// uploadUrl:'https://dzapi.youyilianjk.com/upload/UploadFile',
				token:'',
				// input:'',
				from: {
					input: '',
					inputName: '',
					inputDesc: '',
					inputOpts: '',
					inputExpe: '',
					inputOthe: ''
				},
				avatar: 'https://cdn.uviewui.com/uview/album/1.jpg',
				// 组织成员
				list: [],
				// chooseLsit:[],
				tabIndex: 0,
				logview: {
					workuser: {
						UserName: '王小二',
						Avatar: 'https://cdn.uviewui.com/uview/album/1.jpg',
					},
					sdata: {
						WorkLogId: 0,
						Content: '',
						Content1: '',
						Content2: '',
						Content3: ''
					}

				},
				refEditor:'myQuillEditor',
				value1:'',
				refreshKey:0
			}
		},
		components: {
			memberPop
		},
		async mounted(){
			this.token = await localdic.get('usertoken')
		},
		methods: {
			beforeUploadImg(file) {//富文本图片上传前
				const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif'
				if (!isJPG) {
					this.$message.error('上传图片只能是 JPG，PNG, GIF 格式!')
				} else {
					// 显示loading动画
					this.quillUpdate = true
				}
				return isJPG
				},
				uploadImgSuccess(res, file) {//富文本图片上传成功
				// res为图片服务器返回的数据
				// 获取富文本组件实例
				// console.log('上传成功',res)
				// return
				const quill = this.$refs[this.refEditor].quill
				
				// 这里需要注意自己文件上传接口返回内容，我这里code=0表示上传成功，返回的文件地址：res.data.src
				if (res.code !== 200) {
					this.$message.error(res.msg)
					//this.$message.error('图片插入失败!')
				} else {
					console.info(res)
					// 获取光标所在位置
					const length = quill.getSelection().index
					// 插入图片  res.info为服务器返回的图片地址
					quill.insertEmbed(length, 'image', res.data)
					// 调整光标到最后
					quill.setSelection(length + 1)
				}
				// loading动画消失
				this.quillUpdate = false
				},
				uploadImgError() {//富文本图片上传失败
				// loading动画消失
				this.quillUpdate = false
				this.$message.error('图片插入失败!')
				},
				quillEditorFocus(event,field) {//富文本的获得焦点事件
					this.refEditor = field
					// 在这里可以设置富文本编辑器是否可编辑
					//event.enable(false);//不可输入和编辑
					//event.enable(true);//可以输入和编辑
					// console.log('token',this.token)
				},
			submit(index) {
				this.tabIndex = index
				this.$emit('workNoticeSubmit', index)
			},
			async handleusers() {
				//获取全部
				var data = {
					page: 1,
					limit: 999,
					queryId: 0
				}
				this.list = []
				var res = await this.$request.post('/Users/usersList', data)
				if (res.code == 200) {
					this.list = this.deepClone(res.data.items) 
					this.dialogVisibleMember = true
				}
			},

			updateDialogClick(plist) {
				console.log('发送人',plist)
				this.list = plist
				this.dialogVisibleMember = false
			},
			CloseUpdateDialog(){
				this.dialogVisibleMember = false
			},
			async getlogdetail() {
				var data = {
					WorkLogId: this.logId
				}
				var res = await this.$request.post('/work/detail', data)
				if (res.code == 200) {
					this.logview = res.data
					console.log('0', this.logview)
				}
			},
			async handleaddlog() {

				// if (!this.from.inputName || this.from.inputName == '') {
				// 	this.$message.error('任务名称不能为空')
				// 	return false
				// }
				// if (!this.from.inputDesc || this.from.inputDesc == '') {
				// 	this.$message.error('问题描述不能为空')
				// 	return false
				// }
				if(!this.value1 || this.value1 == ''){
					this.$message.error('时间不能为空')
					return false
				}
				console.log('时间',this.value1)
				var uchecks = []
				this.list.map(it => {
					if (it.checked) uchecks.push(it.ID)
				})
				if (uchecks.length < 1) {
					this.$message.error('请选择发送人')
					return false
				}
				const loading = this.$loading({
					lock: true,
					text: 'Loading'
				});

				//获取全部
				var data = {
					Content: this.from.inputName,
					Content1: this.from.inputDesc,
					Content2: this.from.inputOpts,
					Content3: this.from.inputExpe,
					Content4: this.from.inputOthe,
					WorkType: this.logtype,
					StartTime:this.value1[0],
					EndTime:this.value1[1],
					uids: uchecks
				}
				var res = await this.$request.post('/work/crate', data)
				loading.close();
				if (res.code == 200) {
					this.list = res.data.items
					this.value1 = ''
					this.from.inputName = ''
					this.from.inputDesc = ''
					this.from.inputOpts = ''
					this.from.inputExpe = ''
					this.from.inputOthe = ''
					this.$message.success('提交成功')
				}
			},

			//发送图片/视频/文件/语音信息
			async sendmsgbyUpload(item) {

				// var ftype = this.getFileTypeByMime(item.file.type)
				// console.log('文件类型', ftype)
				// if (ftype != 'image' && ftype != 'video' && ftype != 'audio' &&
				// 	ftype != 'document' && ftype != 'spreadsheet' && ftype != 'archive') {
				// 	this.$message(`不能上传${item.file.type}类型文件`)
				// 	return false
				// }
				const formData = new FormData()
				// var roomId = this.messageDetail.RoomId
				// if (roomId && roomId > 0) formData.append('roomId', roomId)
				// var fileno = new Date().getTime();
				// if (fileno && fileno != '') formData.append('uploadfileNo', fileno)
				// var groupno = this.messageDetail.roomcode
				// if (groupno && groupno != '') formData.append('groupNo', groupno)
				formData.append('uploadfile', item.file)
				var res = await this.$request.post('/upload/UploadFile', formData)
				console.log('上传成功',res)
				if (res.code == 200) {
					this.uploadImgSuccess(res)
				}
				},

				// 类型描述
			WorkTypeDesc(type){
				if(type == 0){
					return '日报'
				}else if(type == 1){
					return '周报'
				}else if(type == 2){
					return '月报'
				}else if(type == 3){
					return '计划'
				}else if(type == 4){
					return '分配'
				}else{
					return '报告'
				}
			},
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	.frame {
		// margin-top: 31px;
		width: 901px;
		// height: 851px;
		height: 766px;
		background: #FFFFFF;
		border-radius: 16px 16px 16px 16px;
		margin-left: 12px;

		.people-title {
			margin-left: 12px;
			font-size: 18px;
			font-weight: 500;
			color: #777777;
		}

		.frame-title {
			// padding-top: 15px;
			margin-left: 18px;
			font-weight: 500;
			font-size: 18px;
			color: #333333;
		}

		.left-item {
			margin-left: 12px;
			margin-top: 5px;
			display: flex;
			width: 185px;
			align-items: center;

			.prefix-icon3 {
				margin-left: 5px;
				width: 15px;
				height: 15px;
				background-image: url('../../assets/img/navigation/search.png');
				background-size: cover;
				/* 确保图像覆盖整个背景区域 */
				background-position: center;
				/* 图像居中对齐 */
				background-repeat: no-repeat;
				/* 防止图像重复 */
			}

			.el-input__inner {
				height: 36px;
				background: #D4DFEA;
				font-size: 15px;
				border-radius: 8px;
				border: 1px solid #C4C6CD;
				color: #272B34;
			}

			.item-text {
				margin-left: 12px;
				color: #858A99;
				font-size: 16px;
				font-weight: 400;
				flex: 1;
			}

			.item-bubble {
				width: 15px;
				height: 15px;
				background: #F65160;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 9px;
				color: #FFFFFF;
				font-weight: 400;
			}
		}

		.frame-item {
			margin-top: 12px;
			margin-left: 12px;
			width: 290px;
			height: 63px;
			background: #FFFFFF;
			color: #272B34;
			border-radius: 8px 8px 8px 8px;
			box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.16);
		}

		.frame-item-active {
			// background: #EFF2FF;
			// color: #0052D9;
		}

		.frame-item-text {
			font-size: 15px;

			font-weight: 400;
		}

		.frame-line {
			width: 26px;
			height: 3px;
			background: #0052D9;
			border-radius: 3px 3px 3px 3px;
		}

		.frame-tab-line {
			width: 5px;
			height: 63px;
			background: #4CAF50;
			border-radius: 8px 0px 0px 8px;
		}

		.frame-text {
			font-weight: 400;
			font-size: 17px;
			color: #000000;
		}

		.frame-text-name {
			margin-top: 3px;
			font-weight: 400;
			font-size: 11px;
			color: #333333;
		}

		.frame-button {
			width: 122px;
			height: 48px;
			background: #0052D9;
			border-radius: 16px 16px 16px 16px;
			font-size: 15px;
			color: #FFFFFF;
		}
	}

	.imgIcon {
		width: 19px;
		height: 19px;
	}

	.frame-content-text {
		// margin-top: 5px;
		font-weight: 400;
		font-size: 13px;
		color: #333333;
		line-height: 20px;
		//   display: -webkit-box;
		//   -webkit-box-orient: vertical;
		//   -webkit-line-clamp: 2; /* 显示的行数 */
		//   overflow: hidden;
		//   text-overflow: ellipsis;
	}

	/deep/.el-textarea__inner {
		margin-top: 10px;
		width: 794px;
		// background: #CBCBCB;
		// min-height: 114px;
		border-radius: 8px;
	}



	/************************************** 富文本编辑器 **************************************/
/** 设置富文本的高度 **/
.editor {
  line-height: normal !important;
  height: 130px;
  margin-bottom: 60px;
}
.ql-container {
  height: 700px !important;
}
/** 设置富文本的高度 **/

/** 设置工具栏中上传图片和上传视频对应的文件上传组件样式 **/
.avatar-uploader-img {
  height: 0;
}
.avatar-uploader-video {
  height: 0;
}
/** 设置工具栏中上传图片和上传视频对应的文件上传组件样式 **/


/deep/ .ql-snow .ql-tooltip[data-mode=link]::before {
  content: '请输入链接地址：';
}
/deep/ .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: '保存';
  padding-right: 0px;
}
/deep/ .ql-snow .ql-tooltip[data-mode=video]::before {
  content: '请输入视频地址：';
}
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: '14px';
}
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=small]::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=small]::before {
  content: '10px';
}
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=large]::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large]::before {
  content: '18px';
}
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=huge]::before,
/deep/ .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=huge]::before {
  content: '32px';
}
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-label::before,
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: '文本';
}
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='1']::before,
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='1']::before {
  content: '标题1';
}
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='2']::before,
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='2']::before {
  content: '标题2';
}
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='3']::before,
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='3']::before {
  content: '标题3';
}
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='4']::before,
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='4']::before {
  content: '标题4';
}
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='5']::before,
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='5']::before {
  content: '标题5';
}
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='6']::before,
/deep/ .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='6']::before {
  content: '标题6';
}
/deep/ .ql-snow .ql-picker.ql-font .ql-picker-label::before,
/deep/ .ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: '标准字体';
}
/deep/ .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=serif]::before,
/deep/ .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=serif]::before {
  content: '衬线字体';
}
/deep/ .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=monospace]::before,
/deep/ .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=monospace]::before {
  content: '等宽字体';
}
/************************************** 富文本编辑器 **************************************/


</style>