<template>
	<div class="hello">
		<div class="frame" :style="{ '--box-color': boxColor }">
			<!-- 标题 -->
			<div class="flexal" style="padding-top: 15px;margin-left: 14px;margin-bottom: 10px;">
				<div class="people-title">签到</div>
				<img :src="require('@/assets/img/conference/575d63bbff7b7e64db25062a2d18892.png')"  style="width: 14px;height: 14px;margin-right: 26px;"  @click="closePop" />
			</div>

			<div class="flexal" style="padding: 0px 112px;margin-top: 20px;">
				<template v-for="(item,index) in tab" >
					<div class="flexcumal tabFrame"
						:style="{'margin-left':index==1? '83px':'0px','color':tabIndex == index? '#0052D9':'#858A99'}"
						@click.stop="tabchangefun(index)" :key="index">
						<div>{{item}}</div>
						<div class="tabLine" v-if="tabIndex == index"></div>
						<div class="tabLine" style="background: #fff;" v-else></div>
					</div>
				</template>
			</div>

			<!-- 发起签到 -->
			<div style="padding: 0px 18px;" v-if="!signShow && tabIndex == 0 && initiator == 1">
				<div class="flexal" style="margin-top: 36px;">
					<div class="tab-text" style="flex: 1;">签到时长</div>
					<el-input-number :min="1" size="mini" v-model="num4"></el-input-number>
					<div class="tab-text-vice" style="margin-left: 10px;">分钟</div>
				</div>
				<div class="tab-text-line"></div>

				<div style="margin-top: 20px;">
					<div class="tab-text" style="flex: 1;">签到说明</div>
					<el-input type="textarea" style="margin-top: 10px;" :autosize="{ minRows: 7, maxRows: 7}"
						placeholder="请输入签到说明（选填）" v-model="input"></el-input>
				</div>

				<div style="display: flex;margin-top: 20px;margin-bottom: 80px;">
					<div class="tab-block flexcen">i</div>
					<div class="tab-block-text">发起签到后，其他成员可在签到窗口完成签到</div>
				</div>
			</div>

			<!-- 签到描述 -->
			<div style="padding: 0px 16px;" v-if="signShow && tabIndex == 0">
				<div class="record-text" style="font-size: 15px;">{{signdata.roomname}}</div>
				<div class="record-text-time" style="font-size: 11px;margin-top: 10px;"> <span
						style="color:#2953E7">{{signdata.sdata.Name}}</span> 发起了签到</div>
				<div class="record-text" style="font-size: 10px;margin-top: 5px;height: 100px;overflow: auto;">
					{{signdata.sdata.Content}}
				</div>
			</div>

			<!-- 签到界面 -->
			<div class="flexcumal" v-if="signShow && tabIndex == 0 && surplusSeconds > 0"
				:style="{'margin-bottom': initiator == 1? '10px':'130px'}">
				<div class="flexcumal" :style="{'margin-top': initiator == 1? '25px' :'25px'}">
					<el-progress type="circle" :percentage="percentage" :stroke-width="15" :color="'#0052D9'"
						:width="153" :format="format"></el-progress>
					<div class="surplus-text">剩余时间</div>
					<div class="surplus-text-tips" v-if="initiator == 1">已签到：<span
							style="color:#2953E7">{{signdata.signcount}}</span>人</div>
					<div class="surplus-text-tips" v-if="initiator == 1" style="margin-top: 10px;">您可以在【签到记录】中查看详细记录
					</div>
				</div>
			</div>
			<!-- 签到结束界面 -->
			<div class="flexcumal" v-if="signShow && tabIndex == 0 && surplusSeconds <= 0"
				:style="{'margin-bottom': initiator == 1? '130px':'130px'}">
				<div class="flexcumal" :style="{'margin-top': initiator == 1? '25px' :'25px'}">
					<el-progress type="circle" :percentage="percentage" :stroke-width="15" :color="'#0052D9'"
						:width="153" :format="format"></el-progress>
					<div class="surplus-text">剩余时间</div>
					<div class="surplus-text" v-if=" surplusSeconds == 0 "
						style="margin-top: 100px;font-size: 12px;position: absolute;">签到已结束</div>
					<!-- <div class="surplus-text-tips" v-if="initiator == 1">已签到：<span style="color:#2953E7">1</span>人</div>
                    <div class="surplus-text-tips" v-if="initiator == 1" style="margin-top: 10px;">您可以在【签到记录】中查看详细记录</div> -->
				</div>
			</div>

			<!-- 签到记录 -->
			<div class="frame-record" :style="{ '--box-color1': boxColor1 }" v-if="tabIndex == 1">
				<div class="frame-record-item" v-for="(item,index) in list" :key="index"
					@click="item.show = !item.show">
					<div style="width: 100%;" class="flexal">
						<div style="flex: 1;">
							<div class="record-text">{{item.total}}人签到</div>
							<div class="flexal" style="margin-top: 5px;">
								<div class="record-block" :style="{'background':item.State>0 ? '#858A99':'#366EF4'}">
								</div>
								<div class="record-text-time"
									:style="{'color':item.State>0 ? '#858A99': '#366EF4','margin-left': '5px'}">
									{{ item.State>0 ? '已结束':'进行中'}}
								</div>
								<div class="record-text-time" style="margin-left: 12px;">{{dateFtt(item.CreateTime,'yyyy/MM/dd hh:mm')}} 发起</div>
							</div>
						</div>
						<img :src="require('@/assets/img/right.png')" style="width: 6px;height: 10px;"
							:style="{'transform': item.show? 'rotate(-90deg)': 'rotate(90deg)'}" />
					</div>
					<div v-if="item.show">
						<div class="flexal" style="margin-top: 17px;">
							<div class="record-text" style="font-size: 10px;">签到成员</div>
							<div class="record-text" style="font-size: 10px;margin-left: 104px;">签到时间</div>
						</div>
						<div class="record-line"></div>
						<div class="frame-record-item-child flexal" v-for="(items,indexs) in item.list" :key="indexs">
							<img :src="items.Avatar" style="width: 32px;height: 32px;border-radius: 50%;" />
							<div style="margin-left: 9px;width: 100px;">
								<div class="record-text ellipsis" style="font-size: 10px;">{{items.UserName}}</div>
								<div class="record-text-time ellipsis" style="font-size: 10px;">{{items.posiName}}</div>
							</div>
							<div style="margin-left: 4px;width: 100px;">
								<div class="record-text ellipsis" style="font-size: 10px;">{{dateFtt(items.CreateTime,'hh:mm')}}</div>
								<div class="record-text-time ellipsis" style="font-size: 10px;">{{dateFtt(items.CreateTime,'yyyy/MM/dd')}}</div>
							</div>
						</div>

					</div>
				</div>
			</div>


			<div class="frame-buttom flexcen">
				<div class="fram-button flexcen" v-if="!signShow && tabIndex == 0 && initiator == 1"
					@click="subimtSign()">立即发起
				</div>
				<div class="fram-button flexcen" v-if="signShow && tabIndex == 0 && initiator == 1" @click="sotp"
					style="background: #FFFFFF;border: 1px solid #0052D9;color: #0052D9;margin-right: 20px;">终止签到</div>
				<div class="fram-button flexcen" v-if="signShow && tabIndex == 0 && !isSign && surplusSeconds > 0" @click="signClick"> 立即签到</div>
				<div class="fram-button flexcen" v-if="signShow && tabIndex == 0 && isSign" style="background: #FFFFFF;color: #0052D9;">签到成功</div>
				<!-- 签到已结束 -->
				<div class="fram-button flexcen"
					v-if="signShow && tabIndex == 0 && initiator == 0 && !isSign && surplusSeconds <= 0"
					style="background: #FFFFFF;border: 1px solid #858A99;color: #858A99;" @click="closeClick">关闭</div>
			</div>
		</div>

	</div>
</template>

<script>
	export default {
		name: 'signInConferencePop',
		props: {
			roomId: {
				type: Number,
				default: 0
			},
			isfullscreen:{
				type:Boolean,
				default:false
			}
		},
		watch: {
			roomId(newval) {
				console.log('roomId-newval', this.roomId)
				this.loadinit()
			},
			isfullscreen(newValue){
				if(newValue){
					this.boxColor = '95vh'
					this.boxColor1 = '80vh'
					// this.boxColor2 = '83vh'
				}else{
					this.boxColor = '82vh'
					this.boxColor1 = '67vh'
					// this.boxColor2 = '70vh'
				}
			}
		},
		data() {
			return {
				boxColor:'82vh',
				boxColor1:'67vh',
				input: '',
				// 组织成员
				tab: ['发起签到', '签到记录'],
				avatar: 'https://cdn.uviewui.com/uview/album/8.jpg', //头像
				tabIndex: 0, //0：发起签到 1：签到记录 
				num4: 1, //分钟
				signShow: false, //是否签到中
				percentage: 0, //签到剩余时间百分比
				seconds: 0, //秒数
				timer: null, //定时器
				sumSeconds: 60, //总秒数
				surplusSeconds: 10, //剩余秒数
				initiator: 1, //是否发起人
				isSign: false, //是否已签到
				signdata: {},
				list: [],
				timerList:null,	//定时器
				proceed:false,	//是否有签到
			}
		},
		components: {},
		mounted() {
			// this.sumSeconds = this.num4 * 60
			if (this.isSign) {
				this.signShow = false
			}

			if (this.signShow) {
				let data = this.getTimes(this.surplusSeconds)
				this.num4 = data.m
				this.seconds = data.s
				this.Time() //调用定时器
			}
			this.percentage = (this.surplusSeconds / this.sumSeconds) * 100

			// console.log(this.getTimes(this.surplusSeconds))

			console.log('mounted-newval', this.roomId)
			this.loadinit()
			this.loadinitTime()
		},
		beforeDestroy() {
			console.log('签到组件销毁了')
			this.clearTime()
			this.clearTimeLoadinit()
		},
		methods: {

			submit(index) {
				this.tabIndex = index
				this.$emit('workNoticeSubmit', index)
			},
			// 添加弹窗回调
			updateDialogClick() {
				this.dialogVisibleMember = false
			},
			format() {
				// console.log('分钟',this.num4)
				var stotal = this.surplusSeconds
				let second = stotal % 60
				//总分钟
				let mintotal = (stotal - second) / 60
				//分钟
				let min = mintotal % 60

				//小时
				let hour = (mintotal - min) / 60
				let hstr = `${hour.toFixed(0)}`
				let mstr = `${min.toFixed(0)}`
				let sstr = `${second.toFixed(0)}`
				if (hour < 10) {
					hstr = `0${hour.toFixed(0)}`
				}
				if (min < 10) {
					mstr = `0${min.toFixed(0)}`
				}
				if (second < 10) {
					sstr = `0${second.toFixed(0)}`
				}

				return `${hstr}:${mstr}:${sstr}`
			},
			clearTime() { //清除定时器
				clearInterval(this.timer);
				this.proceed = false
			},
			clearTimeLoadinit() { //清除定时器
				clearInterval(this.timerList);
			},
			//定时器没过1秒参数减1
			Time() {
				if (this.timer) clearInterval(this.timer)
				this.timer = setInterval(this.getRestTime, 1000)
				// this.clearTimeSet = setInterval(this.getRestTime, 1000)
			},
			//定时器没过1秒参数减1
			loadinitTime() {
				if (this.timerList) clearInterval(this.timerList)
				this.timerList = setInterval(this.loadinitNew, 1000)
				// this.clearTimeSet = setInterval(this.getRestTime, 1000)
			},
			getRestTime() {
				if (this.surplusSeconds <= 0) {
					console.log('我进来了',this.surplusSeconds)
					if(this.initiator == 1){
						this.signShow = false
					}
					this.tabIndex = 0
					this.clearTime()
					return
				}
				this.surplusSeconds -= 1
				this.percentage = (this.surplusSeconds / this.sumSeconds) * 100

				this.format()
			},
			tabchangefun(index){
				this.tabIndex = index
				if(index>0) this.loadlist()
				
			},
			// 终止签到
			sotp() {
				this.$confirm(`终止后参会者将无法继续签到。是否继续?`, {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					var data = {
						SignId: this.signdata.sdata.SignId,
					}
					var res = await this.$request.post('/meeting/overSign', data)
					if (res.code == 200) {
						this.$message.success('成功')
						this.percentage = 0
						this.surplusSeconds = 0
						this.tabIndex = 1
						this.signShow = false
						this.clearTime()
					}

				}).catch(err => {
					// console.error(err)
				})

			},
			//加载判断进行中的签到
			async loadinit() {
				// if(this.proceed){
				// 	return 
				// }
				this.loadlist()
				var data = {
					RoomId: this.roomId
				}
				var res = await this.$request.post('/meeting/getSign', data)
				if (res.code == 200) {
					//是否存在进行中的签到
					var vid = res.data
					// this.proceed = vid > 0 ? true:false
					if (vid > 0)
						this.getdetail(vid)
				}
			},
			async loadinitNew() {
				if(this.proceed){
					return 
				}
				// this.loadlist()
				var data = {
					RoomId: this.roomId
				}
				var res = await this.$request.post('/meeting/getSign', data)
				if (res.code == 200) {
					//是否存在进行中的签到
					var vid = res.data
					this.proceed = vid > 0 ? true:false
					if (vid > 0)
						this.getdetail(vid)
				}
			},
			//加载记录
			async loadlist(){
				var data = {
					RoomId: this.roomId
				}
				
				var rs = await this.$request.post('/meeting/signList', data)
				if (rs.code == 200) {
					this.list = rs.data
				}
				
			},
			//加载详情
			async getdetail(vid) {
				var data = {
					SignId: vid
				}
				var res = await this.$request.post('/meeting/signDetail', data)
				if (res.code == 200) {
					this.signdata = res.data
					this.isSign = res.data.issign
					this.initiator = (res.data.isuser ? 1 : 0)
					this.surplusSeconds = res.data.secondsTotal
					this.sumSeconds = res.data.sdata.MinNum * 60
					this.signShow = true
					this.Time()
				}
			},
			//创建提交// 发起签到
			async subimtSign() {
				var data = {
					RoomId: this.roomId,
					MinNum: this.num4,
					Content: this.input
				}
				var res = await this.$request.post('/meeting/crateSign', data)
				if (res.code == 200) {
					this.$message.success('成功')
					this.getdetail(res.data)
				}
			},
			// 立即签到
			async signClick() {
				this.loadinitNew()
				// return 
				if(this.proceed){
					this.$message.error('签到已终止')
					if(this.initiator == 1){
						this.signShow = false
					}
					this.surplusSeconds = 0
					this.tabIndex = 0
					this.clearTime()
					return
				}
				var data = {
					SignId: this.signdata.sdata.SignId
				}
				var res = await this.$request.post('/meeting/userSign', data)
				if (res.code == 200) {
					this.$message.success('签到成功')
					this.isSign = true
					this.signdata.signcount += 1
				}

			},
			// 关闭
			closeClick(){
				// this.percentage = 0
				// this.surplusSeconds = 0
				// this.tabIndex = 0
				// this.signShow = false
				this.$emit('closePop')
			},
			// 关闭页面
			closePop(){
				this.$emit('closePop')
				// this.clearTime()
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	.frame {
		// margin-left: -5px;
		width: 436px;
		// height: 610px;
		height: var(--box-color);
		background: #FFFFFF;
		border-radius: 0px 16px 16px 0px;
		box-shadow: 0px 3px 26px 1px rgba(0, 0, 0, 0.16);

		.people-title {
			// margin-left: 12px;
			flex: 1;
			font-size: 18px;
			font-weight: 500;
			color: #333333;
		}

		.frame-buttom {
			height: 80px;
		}

		.fram-button {
			width: 122px;
			height: 42px;
			background: #0052D9;
			border-radius: 16px 16px 16px 16px;
			// border: 1px solid #858A99;
			font-size: 14px;
			color: #FFFFFF;
		}

		.tabFrame {
			font-weight: 400;
			font-size: 16px;
			color: #858A99;
		}

		.tabLine {
			width: 46px;
			height: 3px;
			background: #0052D9;
			border-radius: 2px 2px 2px 2px;
		}

		.tab-text {
			font-weight: 400;
			font-size: 15px;
			color: #000000;
		}

		.tab-text-vice {
			font-weight: 400;
			font-size: 14px;
			color: #6E6E6E;
		}

		.tab-text-line {
			margin-top: 10px;
			width: 393px;
			height: 0px;
			border: 1px solid #707070;
			opacity: 0.17;
		}

		.tab-block {
			margin-top: 2px;
			margin-right: 10px;
			width: 14px;
			height: 14px;
			color: #858A99;
			border: 1px solid #858A99;
			font-size: 10px;
			border-radius: 50%;
		}

		.tab-block-text {
			width: 338px;
			font-weight: 400;
			font-size: 13px;
			color: #858A99;
		}

		.surplus-text {
			margin-top: -110px;
			font-size: 9px;
			color: #858A99;
		}

		.surplus-text-tips {
			margin-top: 110px;
			font-weight: normal;
			font-size: 12px;
			color: #15181D;
		}

		.frame-record {
			padding: 0px 16px;
			padding-top: 15px;
			// height: 500px;
			height: var(--box-color1);
			overflow: auto;
			background: #000;
		}

		.frame-record-item {
			margin-top: 14px;
			padding: 13px 13px;
			background: #F9F9F9;
			border-radius: 8px 8px 8px 8px;
		}

		.record-text {
			font-weight: 400;
			font-size: 12px;
			color: #000000;
		}

		.record-block {
			width: 9px;
			height: 9px;
			background: #366EF4;
			border-radius: 50%;
		}

		.record-text-time {
			font-weight: 400;
			font-size: 10px;
			color: #858A99;
		}

		.record-line {
			margin-top: 11px;
			width: 382px;
			height: 0px;
			border: 1px solid #707070;
			opacity: 0.17;
		}

		.frame-record-item-child {
			margin-top: 10px;
			display: flex;
		}
	}

	.imgIcon {
		width: 19px;
		height: 19px;
	}

	/deep/.el-textarea__inner {
		// background: #F1F1F1;
		// min-height: 214px;
		padding: 5px 0px;
		// border: 0px solid #DCDFE6;
	}

	/deep/.memberInput1 .el-input__inner {
		width: 312px;
		height: 35px;
		background: #FFFFFF;
		border-radius: 8px;
		//   border: 1px solid #B0B1B4;
	}
</style>