import computer from '../components/message/longFrame.vue';
 
const ComputerPlugin = {
  install(Vue) {
    const OverlayConstructor = Vue.extend(computer);
    const overlayInstance = new OverlayConstructor();
    overlayInstance.$mount(document.createElement('div'));
    document.body.appendChild(overlayInstance.$el);
 
    // 添加实例方法
    Vue.prototype.$overlayComputer = {
      show() {
        console.log('我进来了1111222')
        overlayInstance.show();
      },
      hide() {
        overlayInstance.hide();
      },
    };
  },
};
 
export default ComputerPlugin;