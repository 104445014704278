<template>
	<div class="overlayPhone flexcen" id="overlay" ref="tag" :style="{'z-index':visible?'100':'-100' }">
		<div class="frame" v-if="visible">
			<div class="frame-title flexal">
				<div style="flex: 1;">发起会议</div>
				<img :src="require('@/assets/img/cancel.png')" style="width: 15px;height: 15px;margin-right: 32px;"
					@click="joinSubimtClick" />
			</div>
			<el-input v-model="name" class="memberInput" style="margin-left: 173px;margin-top: 20px;"
				placeholder="请输入会议名称"></el-input>

			<el-date-picker v-model="value1" type="datetime" placeholder="开始时间" popper-class="popperClass1"
			value-format="yyyy/MM/dd HH:mm:00"	class="detaInput" :picker-options="pickerOptions" ></el-date-picker>
			<div class="flexal" style="margin-left: 12px;margin-top: 100px;">
				<div class="frame-item flexcumal" v-for="(item,index) in tab" @click="item.close = !item.close"
					:key="index">
					<img :src=" item.close? item.chooseIcon: item.icon" style="width: 31px;height: 27px;" />
					<div class="frame-item-text" style="margin-top: 5px;">{{item.text}}</div>
				</div>
				<div style="flex: 1;"></div>
				<div class="frame-button flexcen" @click="submit">开始会议</div>
			</div>
		</div>
	</div>
</template>

  
  
<script>
	export default {
		name: 'launchConferencePop',
		// 是否显示弹窗
		//  dialogVisibleShow: {
		//        type: Boolean,
		//        default: false
		//    },
		data() {
			return {
				visible: true,
				avatar: 'https://cdn.uviewui.com/uview/album/8.jpg',
				cancelTele: false,
				zoomValues: 0,
				value1: '', //选择日期
				name: '', //名称
				code: '', //会议号
				tab: [{
						icon: require('@/assets/img/conference/7c2a94b7c9f86439435d837fb75fe43.png'),
						chooseIcon: require('@/assets/img/conference/ef3f9e6f21b5019d9a442d9e37b6091.png'),
						text: '麦克风',
						close: false
					},
					{
						icon: require('@/assets/img/conference/ae1726f60fd72e5c2f851a407d85370.png'),
						chooseIcon: require('@/assets/img/conference/87e3853a868e13f14d188ecd0dbab81.png'),
						text: '摄像头',
						close: false
					},
					{
						icon: require('@/assets/img/conference/600470aec1d0b5087767f37527bf427.png'),
						chooseIcon: require('@/assets/img/conference/583ea9e54dd6c488483e6bde630c8f5.png'),
						text: '扬声器',
						close: false
					},
				],
				pickerOptions: {
						disabledDate: time => {
						return time.getTime() < Date.now() - 8.64e7
						},
						selectableRange: new Date().getHours() + ':' + (new Date().getMinutes() + 1) + ':00' + ' - 23:59:59'
				}
			};
		},
		watch: {
			// New: 新值 | Old: 老值
			visible(New, Old) {
				//  this.dialogVisible = New
				// console.log(`新值:${New} ——— 老值:${Old}`)
				if (New) {
					var browerWidth = window.innerWidth; //浏览器可视宽度
					var baseWidth = 1440; //设计稿宽度
					var zoomValue = browerWidth / baseWidth; //缩放比例计算
					// console.log('overlay', browerWidth)
					this.$refs.tag.style.transform = "scale(" + (zoomValue) + "," + (zoomValue) +
					")"; //mainContainer为主容器id



				}
			},
		},
		methods: {
			// show() {
			//   this.visible = true;
			//   this.cancelTele = false
			//   console.log('触发了')
			// },
			// hide() {
			//   this.visible = false;
			//   console.log('关闭了',this.zoomValue)
			// },
			joinSubimtClick() {
				this.$emit('closeSubimt');
			},
			cancelClick() {
				this.cancelTele = true
				setTimeout(() => {
					this.visible = false
				}, 1000)
			},
			async submit() {

				if (!this.name || this.name == '') {
					this.$message.error('会议名称不能为空')
					return false
				}if (!this.value1 || this.value1 == '') {
					this.$message.error('会议时间不能为空')
					return false
				}
				var data = {
					RoomType: 2,
					Name: this.name,
					MeetDate:this.value1,
					setIsSpeaker: (this.tab[2].close ? 0 : 1), //扬声器
					setIsCamera: (this.tab[1].close ? 0 : 1), //摄像头
					setIsMicrophone: (this.tab[0].close ? 0 : 1), //麦克风
				}
				var res = await this.$request.post('/meeting/meetingAdd', data)
				console.log(res)
				if (res.code == 200) {
					this.$message.success('创建成功')
					this.$emit('startSubimt',res.data);
				}
			}
		},
		mounted() {
			console.log('99578')
			// var browerWidth = window.innerWidth; //浏览器可视宽度
			// var baseWidth = 1440; //设计稿宽度
			// var zoomValue = browerWidth / baseWidth; //缩放比例计算
			// console.log('overlay',browerWidth)
			// this.$refs.tag.style.transform = "scale(" + (zoomValue) + "," + (zoomValue) +
			// ")"; //mainContainer为主容器id
			//   window.addEventListener('setItemEvent', function(e) {
			// var that = this
			// const newdata = JSON.parse(window.localStorage.getItem('zoomValue'))
			// // that.$refs.tag.style.transform  = "scale(" + newdata + "," + newdata + ")";
			//   document.getElementById("overlay").style.transform = "scale(" + newdata + "," + newdata + ")";
			// console.log('值改变了123456',newdata)
			// })

		}
	};
</script>

<style lang="less">
	.popperClass1 {
		margin-left: 173px !important;
	}
</style>

<style scoped lang="less">
	.overlayPhone {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background-color: rgba(0, 0, 0, 0);
		/* Adjust transparency as needed */
		z-index: 100;
		/* Ensure it sits on top of other content */

		.frame {
			width: 648px;
			height: 401px;
			background: #FFFFFF;
			box-shadow: 0px 3px 26px 1px rgba(0, 0, 0, 0.16);
			border-radius: 16px 16px 16px 16px;

			.frame-title {
				margin-top: 18px;
				margin-left: 26px;
				font-weight: 400;
				font-size: 20px;
				color: #333333;
			}

			.frame-title-two {
				font-weight: 400;
				font-size: 16px;
				color: #333333;
			}

			.frame-item {
				cursor: pointer;
				/* 鼠标悬停时光标变为手指 */
				margin-left: 14px;
				width: 121px;
				height: 83px;
				background: #F2F3F5;
				border-radius: 16px 16px 16px 16px;
				justify-content: center;
			}

			.rame-item-text {
				font-weight: 400;
				font-size: 14px;
				color: #6E6E6E;
			}

			.frame-button {
				cursor: pointer;
				/* 鼠标悬停时光标变为手指 */
				margin-right: 32px;
				width: 122px;
				height: 47px;
				background: #0052D9;
				border-radius: 16px 16px 16px 16px;
				font-size: 16px;
				color: #FFFFFF;
				z-index: 200;
			}

			.frame-text-name {
				margin-top: 10px;
				font-weight: 400;
				font-size: 17px;
				color: #000000;
			}
		}

		.cancel-frame {
			margin-top: 29px;
			margin-bottom: 31px;
			width: 214px;
			height: 52px;
			background: #FFFFFF;
			border-radius: 14px 14px 14px 14px;
			border: 2px solid #366EF4;
			font-size: 19px;
			font-weight: 400;
			color: #366EF4;
		}
	}

	/deep/.memberInput .el-input__inner {
		width: 301px;
		// height: 56px;
		background: #FFFFFF;
		// border-radius: 16px 16px 16px 16px;
		border: 0px solid #B0B1B4;
		border-bottom: 1px solid #B7B7B7;
		text-align: center;
	}

	/deep/ .detaInput .el-input__inner {
		margin-top: 20px;
		margin-left: 173px;
		width: 301px;
		text-align: center;
		border: 0px solid #DCDFE6;
		border-bottom: 1px solid #B7B7B7;
		/* 设置下边框的宽度为 2px，样式为实线，颜色为黑色 */
	}

	/deep/ .detaInput .el-input__prefix {
		display: none;
	}
</style>